import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Card,
  CardContent,
  CardSectionTitle,
  Row,
  Col,
} from 'former-kit'
import {
  pathOr,
} from 'ramda'
import RecipientHeader from '../RecipientHeader'
import env from '../../../environment'
import style from './style.css'
import getDefaultColumns from '../../../components/TransactionRecipientInstallments/TableColumns'
import TransactionRecipientInstallments from '../../../components/TransactionRecipientInstallments'
import installmentStatus from '../../../models/installmentStatus'

const getDefaultRecipientId = pathOr(null, ['default_recipient_id', env])
const prefix = 'pages.transaction.recipients'

function RecipientList ({
  onChangeCollapse,
  recipientsData,
  splitRules,
  t,
}) {
  const {
    company,
  } = useSelector(state => state.account)

  function mountDataIfSplitRulesIsInvalid (recipient) {
    const defaultRecipientId = getDefaultRecipientId(company)
    const isDefaultRecipient = recipient.id === defaultRecipientId

    return {
      amount: recipient.net_amount,
      chargeProcessingFee: true,
      id: defaultRecipientId,
      liable: true,
      name: isDefaultRecipient && recipient.name,
      percentage: 100,
    }
  }

  function mountData (recipient) {
    if (splitRules === null || splitRules.length === 0) {
      return mountDataIfSplitRulesIsInvalid(recipient)
    }

    const split = splitRules.filter(
      splitRule => splitRule.recipient_id === recipient.id
    )

    const splitsValues = split.reduce(
      (accumulator, currentValue) => ({
        chargeProcessingFee: currentValue.charge_processing_fee
        || accumulator.chargeProcessingFee,
        liable: currentValue.liable
        || accumulator.liable,
        netAmount: accumulator.netAmount + currentValue.amount,
        percentage: accumulator.percentage + currentValue.percentage,
      }),
      {
        chargeProcessingFee: false,
        liable: false,
        netAmount: 0,
        percentage: 0,
      }
    )

    return {
      amount: recipient.net_amount,
      chargeProcessingFee: splitsValues.chargeProcessingFee,
      id: recipient.id,
      liable: splitsValues.liable,
      name: recipient.name,
      netAmount: splitsValues.netAmount,
      percentage: splitsValues.percentage,
    }
  }

  const columns = getDefaultColumns({
    t,
  })
  return (
    <Row>
      {recipientsData.map(recipient => (
        <Col
          desk={12}
          palm={12}
          tablet={12}
          tv={12}
          key={recipient.id}
        >
          <Card>
            <CardContent>
              {
                splitRules && splitRules.length > 0 && (
                  <RecipientHeader t={t} {...mountData(recipient)} />
                )
              }
              {
                recipient.installments.length > 0 && (
                  <>
                    {!recipient.collapsed && (
                      <div className={style.collapseStyle}>
                        <TransactionRecipientInstallments
                          columns={columns}
                          rows={recipient.installments}
                        />
                      </div>
                    )}
                    <div className={style.itemCollapse}>
                      <CardSectionTitle
                        title={t(`${prefix}.${recipient.collapsed ? 'collapsedInstallments' : 'notCollapsedInstallments'}`)}
                        collapsed={recipient.collapsed}
                        onClick={(isCollapsed) => {
                          onChangeCollapse(isCollapsed, recipient.id)
                        }}
                      />
                    </div>
                  </>
                )
              }
            </CardContent>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

RecipientList.defaultProps = {
  recipientsData: [],
  splitRules: [],
}

RecipientList.propTypes = {
  onChangeCollapse: PropTypes.func.isRequired,
  recipientsData: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    collapsed: PropTypes.bool,
    id: PropTypes.string,
    installments: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        fees: PropTypes.number,
        net_amount: PropTypes.number,
        number: PropTypes.number,
        original_payment_date: PropTypes.string,
        outcoming_amount: PropTypes.number,
        outgoing_amount: PropTypes.number,
        payment_date: PropTypes.string,
        status: PropTypes.oneOf(Object.keys(installmentStatus)),
      })
    ),
    liable: PropTypes.bool,
    name: PropTypes.String,
    net_amount: PropTypes.number,
  })),
  splitRules: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    charge_processing_fee: PropTypes.bool,
    liable: PropTypes.bool,
    percentage: PropTypes.number,
    recipient_id: PropTypes.string,
  })),
  t: PropTypes.func.isRequired,
}

export default RecipientList
