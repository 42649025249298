import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'
import {
  Alert,
  Button,
  CardActions,
  CardSection,
  Col,
  Flexbox,
  Grid,
  Modal,
  ModalContent,
  ModalTitle,
  Row,
  Steps,
} from 'former-kit'
import IconError from 'emblematic-icons/svg/CloseCircle32.svg'
import { useSelector } from 'react-redux'
import {
  compose, isEmpty, isNil, pathOr,
} from 'ramda'
import { translate } from 'react-i18next'
import BenchGuySvg from '../../../../assets/bench_guy.svg'
import formatCurrency from '../../../../formatters/currency'
import { withError } from '../../../ErrorBoundary'
import Property from '../../../../components/Property'
import style from './style.css'

const enhanced = compose(
  translate(),
  withError
)

const tPrefix = 'pages.simulate_payment'

const SimulatePayment = ({
  isOpen,
  onClose,
  onSuccess,
  t,
  transaction,
}) => {
  const [loading, setLoading] = useState(false)
  const [updateError, setUpdateError] = useState(false)

  const { client } = useSelector(state => state.account)

  const [stepActive, setStepActive] = useState('identification')
  const steps = [
    { id: 'identification', title: t(`${tPrefix}.identification`) },
    { id: 'confirmation', title: t(`${tPrefix}.confirmation`) },
  ]
  const [stepsStatus, setStepsStatus] = useState([
    { id: 'identification', status: 'current' },
    { id: 'confirmation', status: 'pending' },
  ])

  const getPaymentMethod = pathOr('', ['payment', 'method'])
  const paymentMethod = getPaymentMethod(transaction)

  const goToConfirmationStep = useCallback(() => {
    const stepsUpdated = [
      { id: 'simulation', status: 'success' },
      { id: 'confirmation', status: 'current' },
    ]

    setStepActive('confirmation')
    setStepsStatus(stepsUpdated)
  }, [])

  const handleSubmit = async () => {
    try {
      setUpdateError(false)
      setLoading(true)

      await client.transactions.update({ id: transaction.id, status: 'paid' })

      onSuccess(transaction.id)
      goToConfirmationStep()
    } catch {
      setUpdateError(true)
    } finally {
      setLoading(false)
    }
  }

  const getTransactionInfo = () => {
    const {
      amount,
      customer,
      payment: {
        authorized_amount: authorizedAmount,
      },
    } = transaction

    let propertyFields = [
      { id: 2, title: t(`${tPrefix}.confirmed_value`), value: formatCurrency(authorizedAmount) },
      { id: 3, title: t(`${tPrefix}.amount`), value: formatCurrency(amount) },
    ]

    if (!isNil(customer)) {
      const { email, name } = customer
      propertyFields = [
        { id: 0, title: t(`${tPrefix}.name`), value: name },
        { id: 1, title: t(`${tPrefix}.email`), value: email },
        ...propertyFields,
      ]
    }

    return (
      <Grid>
        <Row>
          {propertyFields.map(({ id, title, value }) => (
            <Col
              className={style.propertyCol}
              key={id}
              palm={12}
              tablet={6}
              desk={6}
              tv={6}
            >
              <Property
                title={title}
                value={value}
              />
            </Col>
          ))}
        </Row>
      </Grid>
    )
  }

  const getIdentificationStep = () => (
    <>
      { getTransactionInfo() }
      <CardActions className={style.buttonContainer}>
        <Button
          loading={loading}
          disabled={loading}
          displayChildrenWhenLoading
          onClick={handleSubmit}
        >
          {t(`${tPrefix}.button_submit`)}
        </Button>
      </CardActions>
    </>
  )

  const getConfirmationStep = () => (
    <Flexbox alignItems="center" direction="column">
      <BenchGuySvg />
      <span className={style.successMessage}>
        { t(`${tPrefix}.${paymentMethod}_success`)}
      </span>
      { getTransactionInfo() }
      <CardActions className={style.buttonContainer}>
        <Button onClick={onClose} size="huge">
          {t(`${tPrefix}.button_success`)}
        </Button>
      </CardActions>
    </Flexbox>
  )

  if (isEmpty(transaction)) {
    return null
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle
        title={t(`${tPrefix}.title`)}
        closeIcon={<IconClose width={16} height={16} data-testid="close-icon" />}
        onClose={onClose}
      />
      <ModalContent>
        <div className={style.modalContainer}>
          {updateError && (
            <div className={style.alertContainer}>
              <Alert
                icon={<IconError height={16} width={16} />}
                type="error"
              >
                <span>{t(`${tPrefix}.generic_error`)}</span>
              </Alert>
            </div>
          )}
          <CardSection>
            <Steps
              status={stepsStatus}
              steps={steps}
            />
          </CardSection>
          <div className={style.modalContent}>
            { stepActive === 'identification' && getIdentificationStep() }
            { stepActive === 'confirmation' && getConfirmationStep() }
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}

SimulatePayment.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    amount: PropTypes.number,
    customer: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    payment: PropTypes.shape({
      authorized_amount: PropTypes.number,
    }),
  }).isRequired,
}

export default enhanced(SimulatePayment)
