import {
  find, is, isEmpty, juxt, omit, pipe,
} from 'ramda'
import moment from 'moment'
import isRequired from '../../validation/required'
import isNumber, { isFloat } from '../../validation/number'
import lessThan, { lessThanFloat } from '../../validation/lessThan'
import minLength from '../../validation/minLength'
import onlySpaces from '../../validation/onlySpaces'
import formatCurrency from '../../formatters/currency'
import createMoreThanValidation from '../../validation/greaterThan'

const isString = is(String)

const isRequiredGeneric = t => isRequired(
  t('pages.payment_links.add_link.payment_methods.required_error')
)

export const validateBoletoExpiresIn = (boletoEnabled, t) => (value) => {
  if (!boletoEnabled) {
    return false
  }

  const maxValue = 3650
  const greaterThan3650 = createMoreThanValidation(
    maxValue,
    t('pages.payment_links.add_link.payment_methods.max_value_error', {
      value: maxValue,
    })
  )

  const minValue = 1
  const lessThan1 = lessThan(
    minValue,
    t('pages.payment_links.add_link.payment_methods.min_value_error', {
      value: minValue,
    })
  )

  const isInteger = isNumber(t('pages.payment_links.add_link.payment_methods.is_integer_error'))

  const validationsOutput = juxt([
    isRequiredGeneric(t),
    lessThan1,
    isInteger,
    greaterThan3650,
  ])(value)

  return validationsOutput.find(isString)
}

export const validateRequiredField = (isEnabled, t) => (value) => {
  if (!isEnabled) {
    return false
  }

  return isRequiredGeneric(t)(value)
}

export const validateInterestRate = (
  creditCardEnabled,
  disabled,
  t
) => (value) => {
  if (!creditCardEnabled || disabled) {
    return false
  }

  const minValue = 0.01
  const lessThan0 = lessThanFloat(
    minValue,
    t('pages.payment_links.add_link.payment_methods.min_value_error', {
      value: minValue,
    })
  )

  const maxValue = 99
  const greaterThan99 = createMoreThanValidation(
    maxValue,
    t('pages.payment_links.add_link.payment_methods.max_value_error', {
      value: maxValue,
    })
  )

  const numberValue = value !== '' ? Number(value.replace(',', '.')) : value

  const validationsOutput = juxt([
    isRequiredGeneric(t),
    isFloat(t('pages.payment_links.add_link.payment_methods.is_number_error')),
    lessThan0,
    greaterThan99,
  ])(numberValue)

  return validationsOutput.find(isString)
}

const MAX_EXPIRATION_TIME_NUMBER = 999999
const MAX_EXPIRATION_TIME = {
  days: 180,
  hours: 4320,
  minutes: 259200,
  months: 180,
}

const MIN_EXPIRATION_TIME = {
  days: 1,
  hours: 1,
  minutes: 60,
  months: 1,
}

export const itemTypes = getTranslation => [
  {
    name: getTranslation('items.tangible_item'),
    value: 'true',
  },
  {
    name: getTranslation('items.intangible_item'),
    value: 'false',
  },
]

export const expirationTimeUnits = getTranslation => [
  {
    name: getTranslation('expiration_minutes'),
    value: 'minutes',
  },
  {
    name: getTranslation('expiration_hours'),
    value: 'hours',
  },
  {
    name: getTranslation('expiration_days'),
    value: 'days',
  },
  {
    name: getTranslation('expiration_months'),
    value: 'months',
  },
]

export const formatItemsValues = items => (
  items.map(item => ({
    ...item,
    quantity: Number(item.quantity),
    tangible: item.tangible,
    unit_price: Number(item.unit_price),
  }))
)
export const getGroupedItems = (items) => {
  const groupedItems = []
  const itsAnItemProp = ([key]) => key.split('').includes('-')

  Object.entries(items).filter(itsAnItemProp).forEach(([key, value]) => {
    const [itemPropName, itemIndex] = key.split('-')
    let valueItem = value

    if (itemPropName === 'quantity') {
      valueItem = `${value}`.replace(/[^0-9]+/g, '')
    }

    groupedItems[itemIndex] = {
      ...groupedItems[itemIndex],
      [itemPropName]: valueItem,
    }
  })

  return groupedItems
}

export const buildFormValueWithItems = (values, newItems) => {
  const parsedValue = { ...omit(['items'], values) }

  newItems.forEach((item, itemIndex) => {
    Object.keys(item).forEach((key) => {
      parsedValue[`${key}-${itemIndex}`] = item[key]
    })
  })

  return parsedValue
}

const applyValidations = (validations, value) => pipe(
  juxt(validations),
  find(is(String))
)(value)

const getDateDurationInMinutes = (timeAmount, timeUnit) => {
  const date = moment().add(timeAmount, timeUnit)
  const duration = moment.duration(date.diff(moment()))
  return duration.asMinutes()
}

const createMinAllowedExpirationTime = (timeUnit, getTranslation) => (
  timeAmount
) => {
  const durationInMinutes = Math.round(
    getDateDurationInMinutes(timeAmount, timeUnit)
  )

  return durationInMinutes < MIN_EXPIRATION_TIME.minutes
    && getTranslation(
      `validations.expiration_time.min_allowed_${timeUnit}`,
      { amount: MIN_EXPIRATION_TIME[timeUnit] }
    )
}

const createMaxAllowedExpirationTime = (
  timeUnit, getTranslation
) => (timeAmount) => {
  const durationInMinutes = getDateDurationInMinutes(timeAmount, timeUnit)

  return durationInMinutes > MAX_EXPIRATION_TIME.minutes
    && getTranslation(
      `validations.expiration_time.max_allowed_${timeUnit}`,
      { amount: MAX_EXPIRATION_TIME[timeUnit] }
    )
}

// eslint-disable-next-line max-len
const createMaxAllowedNumber = getTranslation => timeAmount => timeAmount > MAX_EXPIRATION_TIME_NUMBER
  && getTranslation('validations.expiration_time.max_number')

export const validateExpirationAmount = (
  expirationUnit, getTranslation
) => (value) => {
  if (!value) {
    return false
  }

  const minAllowedExpirationTime = createMinAllowedExpirationTime(
    expirationUnit,
    getTranslation
  )
  const maxAllowedExpirationTime = createMaxAllowedExpirationTime(
    expirationUnit,
    getTranslation
  )
  const maxNumber = createMaxAllowedNumber(getTranslation)

  return applyValidations([
    maxNumber,
    minAllowedExpirationTime,
    isNumber(getTranslation('validations.expiration_time.is_not_integer')),
    maxAllowedExpirationTime,
  ],
  value)
}

export const buildFormDefaultValues = (formValues) => {
  const newItems = formValues.items.reduce((acc, currentItem, key) => ({
    ...acc,
    [`id-${key}`]: formValues.items[key].id || '',
    [`title-${key}`]: formValues.items[key].title || '',
    [`tangible-${key}`]: formValues.items[key].tangible || 'true',
    [`unit_price-${key}`]: formValues.items[key].unit_price?.toString() || '0',
    [`quantity-${key}`]: formValues.items[key].quantity?.toString() || '',
  }), {})
  const formDefaultValues = {
    ...formValues,
    ...newItems,
  }
  delete formDefaultValues.items

  return formDefaultValues
}

export const buildValidations = (formValues, registerItems, getTranslation) => {
  const registeredItemsValidations = formValues.items.reduce(
    (acc, _, key) => ({
      ...acc,
      [`id-${key}`]: [
        onlySpaces(getTranslation('items.validations.id.only_spaces_error')),
      ],
      [`title-${key}`]: [
        isRequired(getTranslation('items.validations.name.required')),
        onlySpaces(getTranslation('items.validations.name.only_spaces_error')),
      ],
      [`unit_price-${key}`]: [
        isRequired(getTranslation('items.validations.unit_price.required')),
        isNumber(getTranslation('items.validations.unit_price.number')),
      ],
      [`quantity-${key}`]: [
        isRequired(getTranslation('items.validations.quantity.required')),
        isNumber(getTranslation('items.validations.quantity.number')),
        lessThan(1, getTranslation('items.validations.quantity.min', { min: 1 })),
      ],
    }), {}
  )

  const itemsValidations = registerItems
    ? { ...registeredItemsValidations }
    : {
      amount: [
        isRequired(getTranslation('validations.amount.required')),
        isNumber(getTranslation('validations.amount.number')),
        lessThan(1, getTranslation('validations.amount.min', { min: formatCurrency(100) })),
      ],
    }

  return {
    expiration_time: validateExpirationAmount(
      formValues.expiration_time_unit,
      getTranslation
    ),
    ...itemsValidations,
    name: [
      isRequired(getTranslation('validations.name.required')),
      minLength(2, getTranslation('validations.name.min_length', { min: 2 })),
      onlySpaces(getTranslation('validations.name.only_spaces_error')),
    ],
  }
}

export const calcTotal = item => (Number(item.unit_price) || 0)
    * Number(item.quantity)

export const calcItemUnitPrice = item => formatCurrency(calcTotal(item))

export const calcAmount = items => (
  items.reduce((acc, currentItem) => (
    acc + calcTotal(currentItem)), 0)
)

const possibleEmptyFieldsWithItems = [
  'expiration_time',
  'boleto_expires_in',
  'free_installments',
  'interest_rate',
  'expiration_unit',
  'max_installments',
]

const fieldsThatNeedToBeFilledWithoutItems = [
  'amount',
  'name',
]

export const isEmptyOrHasEmptyValues = (
  values,
  registerItems
) => (Object.entries(values)
  .filter(([key]) => {
    if (registerItems) {
      return !possibleEmptyFieldsWithItems.includes(key) && !key.includes('id')
    }

    return fieldsThatNeedToBeFilledWithoutItems.includes(key)
  })
  .flat()
  .includes('') || isEmpty(values)
)

export const makeExpirationDateStr = (
  expirationAmount,
  expirationUnit,
  getTranslation
) => {
  if (expirationAmount > MAX_EXPIRATION_TIME_NUMBER) return ''

  const date = moment().add(expirationAmount, expirationUnit)

  const formatedHours = date.format('HH:mm')
  const formatedDate = date.format('DD/MM/YYYY')

  return getTranslation('expiration_date_string', {
    formatedDate,
    formatedHours,
  })
}
