import React from 'react'
import {
  pipe,
  prop,
} from 'ramda'

import formatDate from '../../../../formatters/longDate'
import LinkCopyURL from '../../LinkCopyURL'
import StatusLegend from '../../StatusLegend'
import CustomLink from '../../../../components/Link'
import styles from './style.css'

const renderIdLink = id => (
  <span className={styles.linkId}>
    <CustomLink text={id} link={`/payment-links/${id}`} />
  </span>
)

const getDefaultColumns = ({ t }) => ([
  {
    accessor: ['status'],
    orderable: false,
    renderer: item => <StatusLegend t={t} status={item.status} />,
    title: t('pages.payment_links.list.status'),
    width: 100,
  },
  {
    accessor: ['id'],
    orderable: false,
    renderer: pipe(prop('id'), renderIdLink),
    title: t('pages.payment_links.list.id'),
  },
  {
    accessor: ['name'],
    orderable: false,
    title: t('pages.payment_links.list.link_name'),
  },
  {
    accessor: ['created_at'],
    orderable: false,
    renderer: pipe(prop('created_at'), formatDate),
    title: t('pages.payment_links.list.created_at'),
  },
  {
    accessor: ['url'],
    orderable: false,
    renderer: item => <LinkCopyURL t={t} status={item.status} url={item.url} />,
    title: t('pages.payment_links.list.link'),
    width: 300,
  },
  {
    accessor: ['amount'],
    orderable: false,
    title: t('pages.payment_links.list.total_paid'),
  },
])

export default getDefaultColumns
