/* eslint-disable no-unused-vars */
import React from 'react'
import {
  applySpec,
  either,
  path,
  pipe,
  prop,
} from 'ramda'

import { Truncate } from 'former-kit'

import formatCurrency from '../../formatters/currency'
import { formatDateAndHourUTC } from '../../formatters/longDate'

import CustomLink from '../../components/Link'
import renderStatusLegend from './StatusLegend/renderStatusLegend'

const convertPaymentValue = propPath => pipe(
  path(propPath),
  formatCurrency
)

const getCustomerName = either(
  path(['customer', 'name']),
  path(['card', 'holder_name'])
)

const applyTruncateCustomerName = (item) => {
  const value = getCustomerName(item)

  return value
    ? (
      <Truncate
        text={value}
      />
    )
    : null
}

const renderIdLink = id => <CustomLink text={id} link={`/transactions/${id}`} />

const renderPaymentMethod = paymentMethods => paymentMethods.map(paymentMethod => paymentMethod).join(', ')

const getDefaultColumns = ({ t }) => ([
  {
    accessor: ['status'],
    orderable: false,
    renderer: renderStatusLegend(t),
    title: t('models.transaction.status'),
  },
  {
    accessor: ['id'],
    orderable: false,
    renderer: pipe(prop('id'), renderIdLink),
    title: t('models.transaction.id'),
  },
  {
    accessor: ['created_at'],
    orderable: false,
    renderer: pipe(prop('created_at'), formatDateAndHourUTC),
    title: t('models.transaction.created_date'),
  },
  {
    accessor: ['updated_at'],
    orderable: false,
    renderer: pipe(prop('updated_at'), formatDateAndHourUTC),
    title: t('models.transaction.updated_date'),
  },
  {
    accessor: ['customer_name'],
    orderable: false,
    renderer: prop('customer_name'),
    title: t('models.transaction.customer_name'),
  },
  {
    accessor: ['payment_methods'],
    orderable: false,
    renderer: pipe(prop('payment_methods'), renderPaymentMethod),
    title: t('models.transaction.payment_method'),
  },
  {
    accessor: ['amount'],
    align: 'end',
    orderable: false,
    renderer: prop('amount'),
    title: t('models.transaction.transaction_amount'),
  },
])

export default getDefaultColumns
