import { juxt } from 'ramda'
import { changeTransactionsViewMode as gtmchangeTransactionsViewMode } from './googleTagManager'
import { changeTransactionsViewMode as fullStorychangeTransactionsViewMode } from './fullStory'

/**
 * Send changeTransactionsViewMode event to vendor sdks
 *
 * @param {string} viewMode view mode (table or chart)
  */
export default juxt([
  gtmchangeTransactionsViewMode,
  fullStorychangeTransactionsViewMode,
])
