import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Card, CheckboxGroup, Popover, PopoverContent,
} from 'former-kit'
import { Button } from '@stone-payments/jade'
import FilterSlash16 from 'emblematic-icons/svg/FilterSlash16.svg'
import { DateSelector, SearchInput } from 'shared-components'
import { addMonths } from 'date-fns'
import styles from './style.css'
import {
  dateSelectorButtonLabels,
  dateSelectorOptionsLabels,
  searchInputOptions,
  statusOptions,
} from './options'

const getInputSelected = filter => Object.keys(filter)
  .filter(
    key => ['ids', 'names', 'short_ids'].includes(key) && !!filter[key]
  )
  .map(key => ({ key, value: filter[key] }))

function FilterCard ({
  endDate,
  filter,
  loading,
  onChange,
  onChangeEndDate,
  onChangeStartDate,
  onReset,
  onSubmit,
  startDate,
  t,
}) {
  const [statusIsCollapsed, setStatusIsCollapsed] = useState(false)

  const inputSelecteds = getInputSelected(filter)

  const toggleStatus = useCallback(
    collapse => () => {
      if (collapse !== undefined) {
        if (collapse !== statusIsCollapsed) {
          setStatusIsCollapsed(collapse)
        }
      } else {
        setStatusIsCollapsed(currentValue => !currentValue)
      }
    },
    [statusIsCollapsed, setStatusIsCollapsed]
  )

  const handleChangeFilter = (newValue) => {
    const newFilter = {
      ...filter,
      ...newValue,
    }

    onChange(newFilter)
  }

  const handleSelectInputFilter = ({ key, value }) => {
    handleChangeFilter({ [key]: value })
  }

  const handleDeleteInputFilter = ({ key }) => {
    handleChangeFilter({ [key]: null })
  }

  return (
    <Card>
      <div className={styles.inputContainer}>
        <div className={styles.leftContainer} data-testid="input-container">
          <SearchInput
            options={searchInputOptions(t)}
            selecteds={inputSelecteds}
            tFunc={t}
            onSelect={handleSelectInputFilter}
            onDelete={handleDeleteInputFilter}
            placeholder="pages.payment_links.filter.filter_by_link_name"
          />
        </div>
        <div className={styles.rightContainer}>
          <Button
            onClick={onSubmit}
            variant="primary-solid"
            size="medium"
            data-testid="submit-filters"
            loading={loading}
          >
            {t('components.filter.apply')}
          </Button>
        </div>
      </div>
      <div className={styles.filtersContainer} data-testid="filters-container">
        <DateSelector
          start={startDate}
          end={endDate}
          setStart={onChangeStartDate}
          setEnd={onChangeEndDate}
          translate={t}
          buttonLabels={dateSelectorButtonLabels}
          optionsLabels={dateSelectorOptionsLabels}
        />
        <Popover
          content={(
            <div
              className={styles.popover}
              data-testid="status-popover-container"
            >
              <PopoverContent>
                <CheckboxGroup
                  name="status"
                  options={statusOptions(t)}
                  onChange={value => handleChangeFilter({ status: value })}
                  value={filter.status}
                />
              </PopoverContent>
            </div>
          )}
          placement="bottomStart"
          visible={statusIsCollapsed}
          onClickOutside={toggleStatus(false)}
          onClick={() => null}
        >
          <Button
            size="medium"
            icon={statusIsCollapsed ? 'chevron-up' : 'chevron-down'}
            onClick={toggleStatus()}
            data-testid="button-status-filter"
          >
            {t('pages.payment_links.filter.status')}
          </Button>
        </Popover>
        <Button
          size="medium"
          variant="neutral-ghost"
          data-testid="reset-filters"
          onClick={onReset}
          disabled={loading}
        >
          <div className={styles.resetFilterText}>
            <FilterSlash16 />
            {t('components.filter.reset')}
          </div>
        </Button>
      </div>
    </Card>
  )
}

FilterCard.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  filter: PropTypes.shape({
    ids: PropTypes.string,
    names: PropTypes.string,
    short_ids: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
  }),
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  onChangeStartDate: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  t: PropTypes.func.isRequired,
}

FilterCard.defaultProps = {
  endDate: new Date(),
  filter: {},
  loading: false,
  onChange: () => null,
  onChangeEndDate: () => null,
  onChangeStartDate: () => null,
  onReset: () => null,
  onSubmit: () => null,
  startDate: addMonths(new Date(), -1),
}

export default FilterCard
