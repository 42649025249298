import cardBrands from './cardBrands'
import installments from './installments'
import paymentMethods from './paymentMethods'
import refuseReason from './refuseReason'
import transactionStatus from './transactionStatus'
import lastUpdate from './lastUpdate'
import cardModality from './cardModality'

export default [
  cardBrands,
  installments,
  paymentMethods,
  lastUpdate,
  refuseReason,
  transactionStatus,
  cardModality,
]
