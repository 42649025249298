import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import {
  always,
  compose,
  ifElse,
  isEmpty,
  propEq,
} from 'ramda'
import moment from 'moment'
import { Alert, Steps } from 'former-kit'
import IconError from 'emblematic-icons/svg/CloseCircle32.svg'
import { withError } from '../../../ErrorBoundary'

import styles from './style.css'
import ItemsForm from '../../components/ItemsForm'
import PaymentMethodsForm from '../../components/PaymentMethodsForm'
import PaymentLinkCreateSummary from '../../components/PaymentLinkCreateSummary'
import CreatedPaymentLink from '../../components/CreatedPaymentLink'

import canChargeTransactionFee from '../../../../validation/canChargeTransactionFee'

const tPrefix = 'pages.payment_links.add_link'

const enhanced = compose(
  translate(),
  withError
)

const buildDate = (time, unit) => moment().add(time, unit)

const buildStepsStatus = (steps, currentStep) => steps.map((step) => {
  if (step.id === currentStep) {
    return { id: step.id, status: 'current' }
  }

  return { id: step.id, status: 'pending' }
})

const buildSummaryItemsCol = ({ amount, items }) => ({
  items,
  total: Number(amount),
})

const buildSummaryDetaisCol = ({
  expiration_time: expirationTime,
  expiration_time_unit: expirationTimeUnit,
  name,
}) => ({
  expiresDate: !isEmpty(expirationTime)
    ? buildDate(expirationTime, expirationTimeUnit).format('DD/MM/YYYY')
    : expirationTime,
  name,
})

const buildSummaryPaymentsCol = ({
  boleto,
  boleto_expires_in: boletoExpiresIn,
  credit_card: creditCard,
  fee_payer: feePayer,
  free_installments: freeInstallments,
  interest_rate: interestRate,
  max_installments: maxInstallments,
  pix,
}) => ({
  boleto: {
    active: boleto,
    boleto_expires_in: boletoExpiresIn,
  },
  credit_card: {
    active: creditCard,
    fee_payer: feePayer,
    free_installments: Number(freeInstallments),
    interest_rate: Number(interestRate),
    max_installments: Number(maxInstallments) || 12,
  },
  pix: {
    active: pix,
  },
})

const paymentPixEnabled = acquirers => !!acquirers?.some(
  ({ name, payment_methods: paymentMethod }) => paymentMethod?.includes('pix') && name === 'pagarme'
)

const paymentBoletoEnabled = acquirers => !!acquirers?.some(
  ({ name, payment_methods: paymentMethod }) => paymentMethod?.includes('boleto') && name === 'pagarme'
)

const paymentCreditCardEnabled = acquirers => !!acquirers?.some(
  ({ payment_methods: paymentMethod }) => paymentMethod?.includes('credit_card')
)

const buildPixExpirationDate = (expiresIn, expirationDate) => (
  expiresIn
    ? expirationDate.add(3, 'hours')
    : moment().add(1, 'year')
).toISOString()

const getDefaultPaymentMethod = ifElse(
  propEq('creditCard', true),
  always('credit_card'),
  ifElse(
    propEq('boleto', true),
    always('boleto'),
    ifElse(
      propEq('pix', true),
      always('pix'),
      always(null)
    )
  )
)

const buildPaymentLinkPayloadItems = (paymentLink) => {
  const { amount, items, name } = paymentLink

  if (!items?.length) {
    return [{
      id: '01',
      quantity: 1,
      tangible: true,
      title: name,
      unit_price: amount,
    }]
  }

  return items.map(({ id, tangible, ...itemData }, index) => ({
    ...itemData,
    id: isEmpty(id) ? (index + 1).toString().padStart(2, '0') : id,
    tangible: tangible === 'true',
  }))
}

export const buildPaymentLinkPayload = (
  formData,
  isCreditCardEnabled,
  isBoletoEnabled,
  isPixEnabled,
  chargeTransactionFee
) => {
  const expiresIn = formData.expiration_time
    ? moment.duration(
      formData.expiration_time,
      formData.expiration_time_unit
    ).asMinutes()
    : undefined

  const expirationDate = moment().add(
    expiresIn,
    'minutes'
  )

  const payload = {
    amount: formData.amount,
    items: buildPaymentLinkPayloadItems(formData),
    name: formData.name,
    payment_config: {
      default_payment_method: getDefaultPaymentMethod({
        boleto: formData.boleto,
        creditCard: formData.credit_card,
        pix: formData.pix,
      }),
    },
  }

  if (expiresIn) {
    payload.expires_in = expiresIn
  }

  if (isCreditCardEnabled && formData.credit_card) {
    const interestRate = Number(formData.interest_rate)
    const maxInstallments = Number(formData.max_installments)
    const freeInstallments = Number(formData.free_installments)

    payload.payment_config.credit_card = {
      charge_transaction_fee: chargeTransactionFee,
      enabled: formData.credit_card,
      free_installments: freeInstallments,
      max_installments: maxInstallments || 12,
    }

    if (interestRate !== 0 && freeInstallments !== maxInstallments) {
      payload.payment_config.credit_card.interest_rate = interestRate
    }
  }

  if (isBoletoEnabled && formData.boleto) {
    payload.payment_config.boleto = {
      enabled: formData.boleto,
      expires_in: Number(formData.boleto_expires_in),
    }
  }

  if (isPixEnabled && formData.pix) {
    payload.payment_config.pix = {
      enabled: formData.pix,
      expiration_date: buildPixExpirationDate(expiresIn, expirationDate),
    }
  }

  return payload
}

const defaultFormData = {
  amount: '0',
  boleto: false,
  boleto_expires_in: '7',
  credit_card: true,
  expiration_time: '',
  expiration_time_unit: 'days',
  expiration_unit: '',
  free_installments: '',
  interest_rate: '',
  items: [{
    id: '',
    quantity: '1',
    tangible: 'true',
    title: '',
    unit_price: '0',
  }],
  link_value: '0',
  max_installments: '',
  name: '',
  pix: false,
}

const CreatePaymentLink = ({ t }) => {
  const history = useHistory()
  const { client, company } = useSelector(state => state.account)

  const [currentStep, setCurrentStep] = useState('info')
  const [error, setError] = useState(false)
  const [paymentLink, setPaymentLink] = useState(defaultFormData)
  const [paymentLinkId, setPaymentLinkId] = useState(null)
  const [paymentLinkUrl, setPaymentLinkUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [acquirers, setAcquirers] = useState(null)
  const [isPixEnabled, setIsPixEnabled] = useState(false)
  const [isCreditCardEnabled, setIsCreditCardEnabled] = useState(false)
  const [isBoletoEnabled, setIsBoletoEnabled] = useState(false)

  const chargeTransactionFee = canChargeTransactionFee(company)

  const steps = [
    { id: 'info', title: t(`${tPrefix}.steps.info`) },
    { id: 'payment', title: t(`${tPrefix}.steps.payment`) },
    { id: 'summary', title: t(`${tPrefix}.steps.summary`) },
    { id: 'conclusion', title: t(`${tPrefix}.steps.conclusion`) },
  ]

  useEffect(() => {
    const fetchAcquirers = async () => {
      const response = await client.acquirers.all()
      setAcquirers(response)
    }

    fetchAcquirers()
  }, [client])

  useEffect(() => {
    setIsCreditCardEnabled(!!paymentCreditCardEnabled(acquirers))
    setIsBoletoEnabled(!!paymentBoletoEnabled(acquirers))
    setIsPixEnabled(!!paymentPixEnabled(acquirers))
  }, [acquirers])

  const createPaymentLink = async () => {
    setIsLoading(true)
    const payload = buildPaymentLinkPayload(
      paymentLink,
      isCreditCardEnabled,
      isBoletoEnabled,
      isPixEnabled,
      chargeTransactionFee
    )

    try {
      const result = await client.paymentLinks.create(payload)
      setPaymentLinkUrl(result.url)
      setPaymentLinkId(result.id)
      setIsLoading(false)
      return true
    } catch {
      setError(true)
      setIsLoading(false)
      return false
    }
  }

  const onNextStep = nextStep => async (formData) => {
    setError(false)

    if (currentStep === 'info') {
      setPaymentLink({
        ...paymentLink,
        ...formData,
        credit_card: isCreditCardEnabled,
      })
    }

    if (currentStep === 'payment') {
      setPaymentLink({ ...paymentLink, ...formData })
    }

    if (nextStep === 'conclusion') {
      const createWithSuccess = await createPaymentLink()
      if (!createWithSuccess) {
        return
      }
    }

    setCurrentStep(nextStep)
  }

  const onBackStep = step => () => {
    setCurrentStep(step)
    setError(false)
  }

  const handleReset = () => {
    setPaymentLink(defaultFormData)
    setPaymentLinkId(null)
    setCurrentStep('info')
  }

  const handleRedirectToLink = () => {
    history.push(`/payment-links/${paymentLinkId}`)
  }

  return (
    <>
      <header className={styles.header}>
        <Steps
          status={buildStepsStatus(steps, currentStep)}
          steps={steps}
        />
      </header>
      {error && (
        <Alert
          type="error"
          icon={<IconError height={16} width={16} />}
        >
          <p>{t(`${tPrefix}.error_message`)}</p>
        </Alert>
      )}
      <div className={styles.content}>
        {currentStep === 'info' && (
          <ItemsForm
            paymentLink={paymentLink}
            onSubmit={onNextStep('payment')}
            t={t}
          />
        )}
        {currentStep === 'payment' && (
          <PaymentMethodsForm
            formData={paymentLink}
            canChargeTransactionFee={chargeTransactionFee}
            isBoletoEnabled={isBoletoEnabled}
            isCreditCardEnabled={isCreditCardEnabled}
            isPixEnabled={isPixEnabled}
            onSubmit={onNextStep('summary')}
            onBack={onBackStep('info')}
            t={t}
          />
        )}
        {currentStep === 'summary' && (
          <PaymentLinkCreateSummary
            detailsCol={buildSummaryDetaisCol(paymentLink)}
            itemsCol={buildSummaryItemsCol(paymentLink)}
            loading={isLoading}
            paymentsCol={buildSummaryPaymentsCol(paymentLink)}
            onSubmit={onNextStep('conclusion')}
            onBack={onBackStep('payment')}
            t={t}
          />
        )}
        {currentStep === 'conclusion' && (
          <CreatedPaymentLink
            linkUrl={paymentLinkUrl}
            onCreateNewLink={handleReset}
            onViewLink={handleRedirectToLink}
            t={t}
          />
        )}
      </div>
    </>
  )
}

CreatePaymentLink.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(CreatePaymentLink)
