import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dropdown,
  Card,
  CardContent,
  FormInput,
  FormCheckbox,
  CardActions,
  Grid,
  Row,
  Col,
} from 'former-kit'
import Form from 'react-vanilla-form'
import { isEmpty, omit, range } from 'ramda'
import {
  validateBoletoExpiresIn,
  validateInterestRate,
  validateRequiredField,
} from '../../functions'
import styles from './style.css'

function returnTypeSingularOrPlural (value) {
  return value === 1 ? 'singular' : 'plural'
}

const buildInstallmentsOptions = (t) => {
  const installmentsRange = range(1, 13)

  return installmentsRange.map((value) => {
    const translationPath = `installment_value_${returnTypeSingularOrPlural(value)}`

    return {
      name: t(`pages.payment_links.add_link.payment_methods.${translationPath}`, { installment: value }),
      value: String(value),
    }
  })
}

const buildInterestFeesOptions = (
  installments, t
) => {
  const withInterestOptionsRange = range(1, Number(installments) + 1)
  const withInterestOptions = withInterestOptionsRange.map((value) => {
    const translationPath = `installment_options_${returnTypeSingularOrPlural(value)}`
    return {
      name: t(`pages.payment_links.add_link.payment_methods.${translationPath}`, { installment: value }),
      value: String(value),
    }
  })

  return withInterestOptions
}

const feePayerOptions = t => [
  {
    name: t('pages.payment_links.add_link.payment_methods.company_pays_interest_rate_value'),
    value: 'company',
  },
  {
    name: t('pages.payment_links.add_link.payment_methods.customer_pays_interest_rate_value'),
    value: 'customer',
  },
]

const paymentMethodEnabled = data => !!(data.boleto
   || data.credit_card
    || data.pix
)

const disableInterestRate = ({
  free_installments: freeInstallments,
  max_installments: maxInstallments,
}) => isEmpty(maxInstallments)
  || isEmpty(freeInstallments)
  || maxInstallments === freeInstallments

const disableMaxInstallments = ({
  credit_card: creditCard,
  max_installments: maxInstallments,
}) => !creditCard || isEmpty(maxInstallments)

const PaymentMethodsForm = ({
  canChargeTransactionFee,
  formData,
  isBoletoEnabled,
  isCreditCardEnabled,
  isPixEnabled,
  loading,
  onBack,
  onChange,
  onSubmit,
  t,
}) => {
  const [isPaymentMethodEnabled, setIsPaymentMethodEnabled] = useState(
    paymentMethodEnabled(formData)
  )

  const [formValue, setFormValue] = useState(formData)
  const [formErrors, setFormErros] = useState({})

  const internalOnChange = (newData) => {
    const newDataFormatted = {
      ...newData,
      interest_rate: newData.interest_rate.replace(/,/g, '.'),
    }

    if (newDataFormatted.fee_payer === 'customer') {
      newDataFormatted.free_installments = '0'
    }

    if (newDataFormatted.fee_payer === 'company' && newDataFormatted.free_installments === '0') {
      newDataFormatted.free_installments = ''
    }

    const resetInstallments = Number(newData.max_installments)
      < Number(newData.free_installments)

    if (!newDataFormatted.fee_payer && resetInstallments) {
      newDataFormatted.free_installments = ''
    }

    setIsPaymentMethodEnabled(paymentMethodEnabled(formValue))
    onChange(newDataFormatted)
    setFormValue(newDataFormatted)
  }

  useEffect(() => {
    setIsPaymentMethodEnabled(paymentMethodEnabled(formValue))
  }, [formValue])

  return (
    <Card>
      <Form
        data={formValue}
        onChange={internalOnChange}
        onSubmit={(values, errors) => {
          if (errors) {
            setFormErros(errors)
          } else {
            onSubmit(values)
          }
        }}
        validateOn="blur"
        validation={{
          boleto_expires_in: validateBoletoExpiresIn(formValue.boleto, t),
          fee_payer: validateRequiredField(formValue.credit_card, t),
          free_installments: validateRequiredField(formValue.credit_card, t),
          interest_rate: validateInterestRate(
            formValue.credit_card,
            disableInterestRate(formValue),
            t
          ),
          max_installments: validateRequiredField(formValue.credit_card, t),
        }}
      >
        <CardContent>
          <h2 className={styles.title}>{t('pages.payment_links.add_link.payment_methods.title')}</h2>
          <Grid>
            { isCreditCardEnabled && (
              <>
                <Row>
                  <Col palm={12} tablet={6} desk={4} tv={4}>
                    <FormCheckbox
                      value="credit_card"
                      label={t('pages.payment_links.add_link.payment_methods.credit_card')}
                      data-testid="credit_card"
                      checked={formValue.credit_card}
                      onChange={() => setFormValue({
                        ...formValue,
                        credit_card: !formValue.credit_card,
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col palm={12} tablet={6} desk={12} tv={12}>
                    <span className={styles.paymentMethodDescription}>
                      {t('pages.payment_links.add_link.payment_methods.credit_card_description')}
                    </span>
                  </Col>
                </Row>
                <Row className={styles.marginBottom}>
                  <>
                    {canChargeTransactionFee
                      ? (
                        <>
                          <Col>
                            <Dropdown
                              name="fee_payer"
                              placeholder={t('pages.payment_links.add_link.payment_methods.fee_payer_label')}
                              options={feePayerOptions(t)}
                              disabled={!formValue.credit_card}
                            />
                          </Col>
                          {formValue.fee_payer === 'company' && (
                            <Col>
                              <Dropdown
                                name="free_installments"
                                placeholder={t('pages.payment_links.add_link.payment_methods.max_free_installments_label')}
                                options={buildInterestFeesOptions(12, t)}
                                disabled={!formValue.credit_card}
                              />
                            </Col>
                          )}
                        </>
                      )
                      : (
                        <>
                          <Col>
                            <Dropdown
                              name="max_installments"
                              placeholder={t('pages.payment_links.add_link.payment_methods.max_installments_label')}
                              options={buildInstallmentsOptions(t)}
                              disabled={!formValue.credit_card}
                              data-testid="max_installments"
                            />
                          </Col>
                          <Col>
                            <Dropdown
                              name="free_installments"
                              placeholder={t('pages.payment_links.add_link.payment_methods.max_free_installments_label')}
                              options={buildInterestFeesOptions(
                                formValue.max_installments, t
                              )}
                              disabled={disableMaxInstallments(formValue)}
                              data-testid="free_installments"
                            />
                          </Col>
                          <Col>
                            <InterestFees
                              name="interest_rate"
                              t={t}
                              onClick={() => setFormErros(omit(['interest_rate'], formErrors))}
                              formValueCreditCard={formValue.credit_card}
                              disabled={disableInterestRate(formValue)}
                              errors={formErrors}
                            />
                          </Col>
                          <Col>
                            {!isEmpty(formValue.interest_rate) && (
                              <span className={styles.percentMask}>%</span>
                            )}
                          </Col>
                        </>
                      ) }
                  </>
                </Row>
              </>
            )}
            { isBoletoEnabled && (
              <>
                <Row>
                  <Col palm={12} tablet={6} desk={4} tv={4}>
                    <FormCheckbox
                      value="boleto"
                      label={t('pages.payment_links.add_link.payment_methods.boleto')}
                      data-testid="boleto"
                      checked={formValue.boleto}
                      onChange={() => setFormValue({
                        ...formValue,
                        boleto: !formValue.boleto,
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col palm={12} tablet={6} desk={12} tv={12}>
                    <span className={styles.paymentMethodDescription}>
                      {t('pages.payment_links.add_link.payment_methods.boleto_description')}
                    </span>
                  </Col>
                </Row>
                <Row className={styles.marginBottom} data-testid="index-error">
                  <Col palm={12} tablet={6} desk={4} tv={4}>
                    <FormInput
                      type="text"
                      name="boleto_expires_in"
                      data-testid="boleto_expires_in"
                      data-test="secondary-text"
                      label={t('pages.payment_links.add_link.payment_methods.boleto_input_label')}
                      disabled={!formValue.boleto}
                    />
                  </Col>
                </Row>
              </>
            )}
            { isPixEnabled && (
              <>
                <Row>
                  <Col palm={12} tablet={6} desk={4} tv={4}>
                    <FormCheckbox
                      value="pix"
                      label={t('pages.payment_links.add_link.payment_methods.pix')}
                      data-testid="pix"
                      checked={formValue.pix}
                      onChange={() => setFormValue({
                        ...formValue,
                        pix: !formValue.pix,
                      })}
                    />
                  </Col>
                </Row>
                <Row className={styles.marginBottom}>
                  <Col palm={12} tablet={6} desk={12} tv={12}>
                    <span className={styles.paymentMethodDescription}>
                      {t('pages.payment_links.add_link.payment_methods.pix_description')}
                    </span>
                  </Col>
                </Row>
              </>
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <Button onClick={onBack} fill="outline">
            {t('pages.payment_links.add_link.payment_methods.back')}
          </Button>
          <Button
            data-testid="button-advance"
            type="submit"
            disabled={!isPaymentMethodEnabled || loading}
          >
            {t('pages.payment_links.add_link.payment_methods.submit')}
          </Button>
        </CardActions>
      </Form>
    </Card>
  )
}

const InterestFees = ({
  disabled,
  errors,
  formValueCreditCard,
  name,
  onChange,
  onClick,
  t,
  value,
}) => (
  <FormInput
    type="text"
    data-testid="interest_rate"
    label={t('pages.payment_links.add_link.payment_methods.fees_percentage')}
    disabled={!formValueCreditCard || disabled}
    name={name}
    value={value.replace('.', ',')}
    onClick={onClick}
    onChange={event => onChange(event.target.value)}
    error={errors?.interest_rate}
  />
)

InterestFees.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.shape({
    interest_rate: PropTypes.string,
  }),
  formValueCreditCard: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
}

InterestFees.defaultProps = {
  disabled: false,
  errors: {},
  name: '',
  onChange: null,
  onClick: null,
  value: '',
}

PaymentMethodsForm.propTypes = {
  canChargeTransactionFee: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    boleto_expires_in: PropTypes.string,
    fee_payer: PropTypes.string,
    free_installments: PropTypes.string,
    interest_rate: PropTypes.string,
    max_installments: PropTypes.string,
  }).isRequired,
  isBoletoEnabled: PropTypes.bool.isRequired,
  isCreditCardEnabled: PropTypes.bool.isRequired,
  isPixEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  t: PropTypes.func.isRequired,
}

PaymentMethodsForm.defaultProps = {
  loading: false,
  onBack: () => {},
  onChange: () => {},
  onSubmit: () => {},
}

export default PaymentMethodsForm
