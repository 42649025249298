import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-vanilla-form'
import classNames from 'classnames'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Col,
  Dropdown,
  Flexbox,
  FormCheckbox,
  FormInput,
  Grid,
  Row,
} from 'former-kit'

import IconAdd from 'emblematic-icons/svg/Add24.svg'
import IconClose from 'emblematic-icons/svg/ClearClose24.svg'
import IconCloseCircle from 'emblematic-icons/svg/CloseCircle24.svg'
import formatCurrency from '../../../../formatters/currency'
import CurrencyInput from '../../../../components/CurrencyInput'

import styles from './style.css'

const InvalidAmountAlert = ({ getTranslation }) => (
  <div className={styles.alert}>
    <Alert
      icon={<IconCloseCircle height={16} width={16} />}
      type="error"
    >
      <p>{getTranslation('amount_min')}</p>
    </Alert>
  </div>
)

InvalidAmountAlert.propTypes = {
  getTranslation: PropTypes.func.isRequired,
}

const ItemsFormView = ({
  amount,
  buildFormDefaultValues,
  buildValidations,
  calcItemUnitPrice,
  expirationTimeUnits,
  formValues,
  getTranslation,
  hasInvalidAmount,
  isRegisterItems,
  itemTypes,
  makeExpirationDateStr,
  onAddItem,
  onChangeForm,
  onChangeIsRegisterItems,
  onRemoveItem,
  onSubmitForm,
  valuesAreInvalid,
}) => {
  const { items } = formValues
  const hasAtLeastTwoItems = items.length > 1
  return (
    <Card>
      {hasInvalidAmount
        && <InvalidAmountAlert getTranslation={getTranslation} />}
      <Form
        data={buildFormDefaultValues(formValues)}
        validateOn="blur"
        validation={buildValidations(
          formValues,
          isRegisterItems,
          getTranslation
        )}
        onSubmit={onSubmitForm}
        onChange={onChangeForm}
      >
        <CardContent className={styles.cardContent}>
          <Grid>
            <Row>
              <Col
                desk={12}
                palm={12}
                tablet={12}
                tv={12}
              >
                <h3 className={styles.title}>{getTranslation('title')}</h3>
              </Col>
            </Row>
            <Flexbox className={styles.inputsWrapper}>
              <div>
                <FormInput
                  type="text"
                  name="name"
                  label={getTranslation('name')}
                  data-testid="name"
                />
              </div>
              <div>
                <FormInput
                  type="number"
                  name="expiration_time"
                  label={getTranslation('expiration_time')}
                  data-testid="expiration-time"
                />
                {formValues.expiration_time && (
                  <span className={styles.expirationMessage}>
                    {makeExpirationDateStr(
                      formValues.expiration_time,
                      formValues.expiration_time_unit,
                      getTranslation
                    )}
                  </span>
                )}
              </div>
              <div>
                <Dropdown
                  name="expiration_time_unit"
                  options={expirationTimeUnits(getTranslation)}
                  data-testid="expiration-time-unit"
                />
              </div>
              {!isRegisterItems && (
                <div>
                  <FormInput
                    type="text"
                    name="amount"
                    label={getTranslation('amount_label')}
                    data-testid="amount"
                    renderer={props => (
                      <CurrencyInput
                        {...props}
                      />
                    )}
                  />
                </div>
              )}
            </Flexbox>
            <Row>
              <Col
                palm={12}
                tablet={12}
                desk={12}
                tv={12}
              >
                <FormCheckbox
                  value="register_items"
                  label={getTranslation('register_items')}
                  data-testid="register_items"
                  checked={isRegisterItems}
                  onChange={() => onChangeIsRegisterItems(!isRegisterItems)}
                />
              </Col>
            </Row>

            {isRegisterItems && (
              <>
                <Row>
                  <Col
                    palm={12}
                    tablet={6}
                    desk={3}
                    tv={3}
                  >
                    <h3>{getTranslation('items.title')}</h3>
                  </Col>
                  <Col
                    palm={12}
                    tablet={6}
                    desk={9}
                    tv={9}
                    align="end"
                  >
                    <Button
                      fill="outline"
                      icon={<IconAdd width={12} height={12} />}
                      onClick={onAddItem}
                    >
                      {getTranslation('items.add')}
                    </Button>
                  </Col>
                </Row>

                {items.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row key={i} className={styles.item}>
                    <Col
                      palm={12}
                      tablet={12}
                      desk={9}
                      tv={9}
                    >
                      <Row className={styles.fullWidth}>
                        <Col
                          palm={12}
                          tablet={6}
                          desk={4}
                          tv={4}
                        >
                          <FormInput
                            type="text"
                            name={`id-${i}`}
                            label={getTranslation('items.id')}
                            data-testid={`id-${i}`}
                          />
                        </Col>
                        <Col
                          palm={12}
                          tablet={6}
                          desk={4}
                          tv={4}
                        >
                          <FormInput
                            type="text"
                            name={`title-${i}`}
                            label={getTranslation('items.name')}
                            data-testid={`title-${i}`}
                          />
                        </Col>
                        <Col
                          palm={12}
                          tablet={6}
                          desk={4}
                          tv={4}
                        >
                          <Dropdown
                            options={itemTypes(getTranslation)}
                            name={`tangible-${i}`}
                            data-testid={`tangible-${i}`}
                          />
                        </Col>
                      </Row>
                      <Row className={styles.fullWidth}>
                        <Col
                          palm={12}
                          tablet={6}
                          desk={4}
                          tv={4}
                        >
                          <FormInput
                            type="number"
                            min="1"
                            name={`quantity-${i}`}
                            label={getTranslation('items.quantity')}
                            data-testid={`quantity-${i}`}
                          />
                        </Col>
                        <Col
                          palm={12}
                          tablet={6}
                          desk={4}
                          tv={4}
                        >
                          <FormInput
                            type="text"
                            name={`unit_price-${i}`}
                            label={getTranslation('items.unit_price')}
                            data-testid={`unit_price-${i}`}
                            renderer={props => (
                              <CurrencyInput
                                {...props}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      palm={12}
                      tablet={6}
                      desk={3}
                      tv={3}
                      align="end"
                      className={hasAtLeastTwoItems
                        ? classNames(styles.itemOptions, styles.spaceBetween)
                        : classNames(styles.itemOptions, styles.flexEnd)}
                    >
                      { hasAtLeastTwoItems && (
                        <Button
                          relevance="high"
                          fill="outline"
                          icon={<IconClose width={12} height={12} />}
                          onClick={() => onRemoveItem(i)}
                        >
                          {getTranslation('items.remove')}
                        </Button>
                      )}
                      <p>
                        {getTranslation('items.subtotal')} {calcItemUnitPrice(item)}
                      </p>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col palm={12} tablet={12} desk={12} tv={12} align="end">
                    {getTranslation('amount')}
                    {' '}
                    {formatCurrency(amount)}
                  </Col>
                </Row>
              </>
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <Button type="submit" data-testid="button-advance" disabled={valuesAreInvalid}>{getTranslation('submit')}</Button>
        </CardActions>
      </Form>
    </Card>
  )
}
const itemShape = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]).isRequired,
  tangible: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  unit_price: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]).isRequired,
}

const formValuesShape = {
  expiration_time: PropTypes.string.isRequired,
  expiration_time_unit: PropTypes.oneOf(['minutes', 'hours', 'days', 'months']).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  name: PropTypes.string.isRequired,
}

ItemsFormView.propTypes = {
  amount: PropTypes.string.isRequired,
  buildFormDefaultValues: PropTypes.func.isRequired,
  buildValidations: PropTypes.func.isRequired,
  calcItemUnitPrice: PropTypes.func.isRequired,
  expirationTimeUnits: PropTypes.func.isRequired,
  formValues: PropTypes.shape(formValuesShape).isRequired,
  getTranslation: PropTypes.func.isRequired,
  hasInvalidAmount: PropTypes.bool.isRequired,
  isRegisterItems: PropTypes.bool.isRequired,
  itemTypes: PropTypes.func.isRequired,
  makeExpirationDateStr: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  onChangeIsRegisterItems: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  valuesAreInvalid: PropTypes.bool.isRequired,
}

export default ItemsFormView
