import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  CardContent,
  Row,
  isMomentPropValidation,
} from 'former-kit'

import PopoverCalendar from '../../../../components/PopoverCalendar'
import PopoverCheckbox from '../../../../components/PopoverCheckbox'

const OptionsGroup = ({
  handleFiltersChange,
  lastItems,
  options,
  query,
  selectedFilters,
  subItems,
  t,
}) => (
  <CardContent>
    <fieldset name="filters">
      <Row>
        {subItems}
        {options.map(({
          items, key, name, type,
        }) => (
          <Fragment key={key}>
            {
              type === 'calendar' && (
                <PopoverCalendar
                  t={t}
                  onChange={(data) => {
                    handleFiltersChange({
                      ...query,
                      filters: {
                        ...query.filters,
                        updatedCreatedSince: data.start?.toISOString()
                        || null,
                        updatedCreatedUntil: data.end?.toISOString() || null,
                      },
                    })
                  }}
                  calendarDates={{
                    end: query.filters.updatedCreatedUntil
                      ? moment(query.filters.updatedCreatedUntil)
                      : null,
                    key,
                    name,
                    start: query.filters.updatedCreatedSince
                      ? moment(query.filters.updatedCreatedSince)
                      : null,
                  }}
                  minDate={query.createdDates?.createdSince}
                  maxDate={query.createdDates?.createdUntil}
                  labelPlaceholder="components.popoverCalendar.placeholder"
                />
              )}
            {
              type === 'checkbox' && (
                <PopoverCheckbox
                  name={t(name)}
                  checkboxKey={key}
                  items={items}
                  handleFiltersChange={handleFiltersChange}
                  options={options}
                  query={query}
                  selectedFilters={selectedFilters}
                />
              )
            }
          </Fragment>
        ))}
        {lastItems}
      </Row>
    </fieldset>
  </CardContent>
)

OptionsGroup.propTypes = {
  handleFiltersChange: PropTypes.func.isRequired,
  lastItems: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  query: PropTypes.shape({
    createdDates: PropTypes.shape({
      createdSince: isMomentPropValidation,
      createdUntil: isMomentPropValidation,

    }),
    dates: PropTypes.shape({
      end: isMomentPropValidation,
      start: isMomentPropValidation,
    }),
    filters: PropTypes.shape({
      createdSince: isMomentPropValidation,
      updatedCreatedSince: isMomentPropValidation,
      updatedCreatedUntil: isMomentPropValidation,
    }),
    lastUpdate: PropTypes.shape({
      end: isMomentPropValidation,
      start: isMomentPropValidation,
    }),
    search: PropTypes.string,
  }).isRequired,
  selectedFilters: PropTypes.shape({}).isRequired,
  subItems: PropTypes.node,
  t: PropTypes.func.isRequired,
}
OptionsGroup.defaultProps = {
  lastItems: null,
  subItems: null,
}
export default OptionsGroup
