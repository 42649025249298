import React, { useState, useEffect } from 'react'
import Proptypes from 'prop-types'
import {
  pluck, includes, reject, equals, filter, pipe, prop, __,
} from 'ramda'
import {
  Modal,
  ModalContent,
  ModalTitle,
  ModalActions,
  Spacing,
  Button,
  Grid,
  Row,
  Col,
  Checkbox,
} from 'former-kit'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'

import { getAllFields, getDefaultFields, getTranslation } from '../../Search/exportFields'

const ExportSettingsModal = ({
  isOpen, onCancel, onSubmit, showMetadataInColumns, t,
}) => {
  const [allFields] = useState(getAllFields(t, showMetadataInColumns))
  const [defaultFields, setDefaultFields] = useState([])

  const [selectedFields, setSelectedFields] = useState(pluck('key', defaultFields))

  const onClear = () => setSelectedFields([])

  useEffect(() => {
    const subscriptionTables = localStorage.getItem('transactionTables')
    if (subscriptionTables) {
      setDefaultFields(JSON.parse(subscriptionTables))
      return
    }

    localStorage.setItem('transactionTables', JSON.stringify(getDefaultFields(t)))
    setDefaultFields(getDefaultFields(t))
  }, [t])

  useEffect(() => {
    const newItems = selectedFields.map(field => ({
      key: field,
      value: getTranslation(field, t),
    }))
    localStorage.setItem('transactionTables', JSON.stringify(newItems))
  }, [selectedFields, t])

  useEffect(() => {
    setSelectedFields(pluck('key', defaultFields))
  }, [defaultFields])

  const onChangeValue = (key) => {
    if (includes(key, selectedFields)) {
      setSelectedFields(
        reject(equals(key), selectedFields)
      )
      return
    }
    setSelectedFields([
      ...selectedFields,
      key,
    ])
  }

  const onClickSubmit = () => {
    const fields = filter(
      pipe(prop('key'), includes(__, selectedFields)),
      allFields
    )
    onSubmit(fields)
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle
        title={t('pages.transactions.export.export_report')}
        titleAlign="start"
        closeIcon={<IconClose width={12} height={12} />}
        onClose={() => onCancel()}
      />
      <ModalContent>
        <Grid>
          <Row>
            {allFields.map(field => field.key && (
              <Col key={field.key} palm={12} tablet={6} desk={6} tv={6}>
                <Checkbox
                  name="field"
                  type="checkbox"
                  value={field.key}
                  checked={selectedFields.includes(field.key)}
                  onChange={onChangeValue}
                  label={field.value}
                />
              </Col>
            ))}
          </Row>
        </Grid>
        <Spacing />
      </ModalContent>
      <ModalActions>
        <Button fill="outline" onClick={onClear} data-testid="clear_button">
          {t('pages.transactions.export.clear_options')}
        </Button>
        <Button onClick={onClickSubmit} disabled={selectedFields.length === 0} data-testid="export_button">
          {t('pages.transactions.export.export_report')}
        </Button>
      </ModalActions>
    </Modal>
  )
}

ExportSettingsModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  onCancel: Proptypes.func.isRequired,
  onSubmit: Proptypes.func.isRequired,
  showMetadataInColumns: Proptypes.bool,
  t: Proptypes.func.isRequired,
}

ExportSettingsModal.defaultProps = {
  showMetadataInColumns: false,
}

export default ExportSettingsModal
