import {
  always,
  cond,
  either,
  equals,
} from 'ramda'

const pixTemplate = [
  'header-image',
  'text-introduction',
  'outer-separator',
  'refund-title',
  'field-transaction-id',
  'separator',
  'field-pix-end-to-end-id',
  'separator',
  'field-date-created',
  'separator',
  'field-amount',
  'separator',
  'field-refunded-amount',
  'separator',
  'field-date-refunded',
  'favored-title',
  'field-pix-payer-name',
  'separator',
  'field-pix-payer-document',
  'separator',
  'field-pix-payer-bank-name',
  'separator',
  'field-pix-payer-bank-agency',
  'separator',
  'field-pix-payer-bank-account-number',
  'farewell',
  'outer-separator',
  'footer',
]

const boletoTemplate = [
  'header-image',
  'text-introduction',
  'outer-separator',
  'refund-title',
  'field-transaction-id',
  'separator',
  'field-date-created',
  'separator',
  'field-amount',
  'separator',
  'field-refunded-amount',
  'separator',
  'field-date-refunded',
  'favored-title',
  'field-bank-account-legal-name',
  'separator',
  'field-bank-account-document',
  'separator',
  'field-bank-account-name',
  'separator',
  'field-bank-account-agency',
  'separator',
  'field-bank-account-number',
  'separator',
  'field-bank-account-type',
  'farewell',
  'outer-separator',
  'footer',
]

const cardTemplate = [
  'header-image',
  'text-introduction',
  'outer-separator',
  'refund-title',
  'field-transaction-id',
  'separator',
  'field-authorization-id',
  'separator',
  'field-date-created',
  'separator',
  'field-amount',
  'separator',
  'field-refunded-amount',
  'separator',
  'field-date-refunded',
  'favored-title',
  'field-card-holder-name',
  'separator',
  'field-card-number',
  'separator',
  'field-card-brand',
  'warning-credit-card',
  'farewell',
  'outer-separator',
  'footer',
  'field-bank-account-document',
  'separator',
]

const getReceiptTemplate = cond([
  [equals('boleto'), always(boletoTemplate)],
  [equals('pix'), always(pixTemplate)],
  [
    either([equals('credit_card'), equals('debit_card')]),
    always(cardTemplate),
  ],
])

export default getReceiptTemplate
