export default {
  items: [
    {
      label: 'Pix',
      value: 'pix',
    },
    {
      label: 'Boleto',
      value: 'boleto',
    },
    {
      label: 'Cartão de crédito',
      value: 'credit_card',
    },
    {
      label: 'Cartão de débito',
      value: 'debit_card',
    },
  ],
  key: 'payment_methods',
  name: 'pages.transactions.filters.payment_methods',
  type: 'checkbox',
}
