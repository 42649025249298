import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-vanilla-form'
import {
  Button,
  Col,
  FormDropdown,
  FormInput,
  Grid,
  ModalActions,
  ModalContent,
  Row,
} from 'former-kit'

import CurrencyInput from '../../../components/CurrencyInput'
import formatCurrency from '../../../formatters/currency'
import greaterThanValidation from '../../../validation/greaterThan'
import lessThanOrEqualValidation from '../../../validation/lessThanOrEqual'
import numberValidation from '../../../validation/number'
import Property from '../../../components/Property'
import requiredValidation from '../../../validation/required'

class PixForm extends Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (data, errors) {
    const { onSubmit } = this.props

    if (!errors) {
      onSubmit(data)
    }
  }

  render () {
    const {
      amount,
      recipient,
      recipients,
      refundAmount,
      showRecipientsSelector,
      t,
    } = this.props

    const isRequired = requiredValidation(t('pages.refund.required'))
    const isNumber = numberValidation(t('pages.refund.number'))

    return (
      <Form
        data={{
          amount: refundAmount || amount.toString(),
          recipient,
        }}
        validateOn="blur"
        validation={{
          amount: [
            isRequired,
            isNumber,
            greaterThanValidation(amount, t('pages.refund.invalid_amount')),
            lessThanOrEqualValidation(0, t('pages.refund.greater_than_zero')),
          ],
        }}
        onSubmit={this.handleSubmit}
      >
        <ModalContent>
          <Grid>
            <Row>
              <Col palm={12} tablet={4} desk={4} tv={4}>
                <Property
                  title={t('models.transaction.amount')}
                  value={formatCurrency(amount)}
                />
              </Col>

              {showRecipientsSelector && (
                <Col palm={12} tablet={4} desk={4} tv={4}>
                  <FormDropdown
                    label={t('pages.refund.selected_recipient')}
                    name="recipient"
                    options={recipients}
                  />
                </Col>
              )}

              <Col palm={12} tablet={4} desk={4} tv={4}>
                <FormInput
                  label={t('pages.refund.refund_amount_with_symbol')}
                  name="amount"
                  renderer={props => (
                    <CurrencyInput
                      {...props}
                    />
                  )}
                  type="text"
                />
              </Col>
            </Row>
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            type="submit"
          >
            {t('pages.refund.continue')}
          </Button>
        </ModalActions>
      </Form>
    )
  }
}

PixForm.propTypes = {
  amount: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  recipient: PropTypes.string,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  })),
  refundAmount: PropTypes.string,
  showRecipientsSelector: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

PixForm.defaultProps = {
  recipient: null,
  recipients: [],
  refundAmount: null,
  showRecipientsSelector: false,
}

export default PixForm
