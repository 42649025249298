import React from 'react'
import PropTypes from 'prop-types'
import { HashRouter, Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  Flexbox,
} from 'former-kit'
import styles from './style.css'

const InfoCard = ({
  buttonLabel,
  description,
  disabled,
  isInternalLink,
  link,
  title,
}) => {
  const dataTestId = link && `button${link.toLowerCase().replace(/\//g, '-')}`
  return (
    <Card>
      <CardContent>
        <Flexbox
          alignItems="center"
          className={styles.infoCard}
          justifyContent="space-between"
        >
          <div>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.description}>
              {description}
            </div>
          </div>
          {isInternalLink
            ? (
              <HashRouter>
                <Link to={!disabled && link} className={styles.link}>
                  <Button disabled={disabled} data-testid={dataTestId}>
                    {buttonLabel}
                  </Button>
                </Link>
              </HashRouter>
            )
            : (
              <a
                className={styles.link}
                href={!disabled && link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button disabled={disabled} data-testid={dataTestId}>
                  {buttonLabel}
                </Button>
              </a>
            )}
        </Flexbox>
      </CardContent>
    </Card>
  )
}

InfoCard.defaultProps = {
  disabled: false,
  isInternalLink: false,
}

InfoCard.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isInternalLink: PropTypes.bool,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default InfoCard
