import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { remove } from 'ramda'
import InfoFormView from './View'

import {
  isEmptyOrHasEmptyValues,
  buildFormDefaultValues,
  buildFormValueWithItems,
  buildValidations,
  calcItemUnitPrice,
  calcAmount,
  formatItemsValues,
  expirationTimeUnits,
  getGroupedItems,
  itemTypes,
  makeExpirationDateStr,
} from '../../functions'

const MIN_PAYMENT_LINK_VALUE_IN_CENTS = 100

const initItem = {
  id: '',
  quantity: '1',
  tangible: 'true',
  title: '',
  unit_price: '0',
}

const defaultPaymentLink = {
  amount: '0',
  expiration_time: '',
  expiration_time_unit: 'days',
  items: [{ ...initItem }],
  name: '',
}

const ItemsForm = ({
  onSubmit,
  paymentLink: propPaymentLink,
  t,
}) => {
  const [formValues, setFormValues] = useState(propPaymentLink)
  const { items } = formValues

  const [isRegisterItems, setIsRegisterItems] = useState(
    propPaymentLink?.items.length > 0
  )
  const [hasInvalidAmount, setHasInvalidAmount] = useState(false)
  const [valuesAreInvalid, setValuesAreInvalid] = useState(
    isEmptyOrHasEmptyValues(buildFormValueWithItems(
      propPaymentLink,
      propPaymentLink.items
    ), isRegisterItems)
  )

  const getTranslation = (subpath, args = {}) => t(`pages.payment_links.add_link.items_form.${subpath}`, args)

  const hasJustItemsErrors = (errors) => {
    let hasOnlyItemsErrors = true

    Object.keys(errors).forEach((key) => {
      if (!key.includes('-')) {
        hasOnlyItemsErrors = false
      }
    })

    return hasOnlyItemsErrors
  }

  const onChangeForm = (values, errors) => {
    const errorsLength = Object.getOwnPropertyNames(errors || {}).length
    let hasErrors = errorsLength > 0

    if (errorsLength === 1
      && errors.amount
      && isRegisterItems) {
      hasErrors = false
    }

    if (!isRegisterItems && hasJustItemsErrors(errors)) {
      hasErrors = false
    }

    const groupedItems = values.items ? values.items : getGroupedItems(values)

    setValuesAreInvalid(
      isEmptyOrHasEmptyValues(values, isRegisterItems) || hasErrors
    )
    setFormValues(() => ({
      amount: isRegisterItems
        ? calcAmount(groupedItems).toString()
        : values.amount,
      expiration_time: values.expiration_time,
      expiration_time_unit: values.expiration_time_unit,
      items: groupedItems,
      name: values.name,
    }))
  }

  const onSubmitForm = (formItems, errors) => {
    setHasInvalidAmount(false)

    const { amount } = formValues

    if (amount < MIN_PAYMENT_LINK_VALUE_IN_CENTS) {
      setHasInvalidAmount(true)
      return
    }

    const itemsWithoutSpace = formValues.items.map(item => ({
      ...item,
      id: item.id.trimStart().trimEnd(),
      title: item.title.trimStart().trimEnd(),
    }))

    if (!errors) {
      onSubmit({
        ...formValues,
        items: isRegisterItems ? formatItemsValues(itemsWithoutSpace) : [],
        name: formValues.name.trimStart().trimEnd(),
      })
    }
  }

  const onAddItem = () => {
    const newItems = [...items, { ...initItem }]
    onChangeForm(buildFormValueWithItems(formValues, newItems))
  }

  const onRemoveItem = (index) => {
    const newItems = remove(index, 1, items)
    onChangeForm(buildFormValueWithItems(formValues, newItems))
  }

  const handleSetIsRegisterItems = (value) => {
    setIsRegisterItems(value)

    const newFormValues = {
      ...formValues,
      amount: '0',
      items: value ? [initItem] : [],
    }

    setFormValues(newFormValues)
  }

  return (
    <InfoFormView
      amount={formValues.amount}
      buildFormDefaultValues={buildFormDefaultValues}
      buildValidations={buildValidations}
      calcItemUnitPrice={calcItemUnitPrice}
      expirationTimeUnits={expirationTimeUnits}
      formValues={formValues}
      getTranslation={getTranslation}
      hasInvalidAmount={hasInvalidAmount}
      valuesAreInvalid={valuesAreInvalid}
      itemTypes={itemTypes}
      makeExpirationDateStr={makeExpirationDateStr}
      onAddItem={onAddItem}
      onChangeForm={onChangeForm}
      onRemoveItem={onRemoveItem}
      onSubmitForm={onSubmitForm}
      isRegisterItems={isRegisterItems}
      onChangeIsRegisterItems={handleSetIsRegisterItems}
    />
  )
}

ItemsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  paymentLink: PropTypes.shape({
    expiration_time: PropTypes.string.isRequired,
    expiration_time_unit: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]).isRequired,
      tangible: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      unit_price: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]).isRequired,
    })),
    name: PropTypes.string.isRequired,
  }),
  t: PropTypes.func.isRequired,
}

ItemsForm.defaultProps = {
  paymentLink: defaultPaymentLink,
}

export default ItemsForm
