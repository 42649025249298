"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformMovementTypePropTo = exports.transformAndNegateMovementTypePropTo = exports.isNegative = exports.getType = exports.getTransferId = exports.getTransactionId = exports.getTargetId = exports.getSourceId = exports.getSettlementId = exports.getRecipientId = exports.getOperationDate = exports.formatRows = exports.buildNetAmount = void 0;
var _ascend2 = _interopRequireDefault(require("ramda/src/ascend"));
var _sortWith2 = _interopRequireDefault(require("ramda/src/sortWith"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _both2 = _interopRequireDefault(require("ramda/src/both"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _pathEq2 = _interopRequireDefault(require("ramda/src/pathEq"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _propSatisfies2 = _interopRequireDefault(require("ramda/src/propSatisfies"));
var _lt2 = _interopRequireDefault(require("ramda/src/lt"));
var _evolve2 = _interopRequireDefault(require("ramda/src/evolve"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _assoc2 = _interopRequireDefault(require("ramda/src/assoc"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _negate2 = _interopRequireDefault(require("ramda/src/negate"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var negateWhenNotZero = (0, _unless2["default"])((0, _equals2["default"])(0), _negate2["default"]);
var transformMovementTypePropTo = exports.transformMovementTypePropTo = function transformMovementTypePropTo(propPath, to) {
  return (0, _pipe2["default"])((0, _path2["default"])(propPath), (0, _when2["default"])((0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]), (0, _always2["default"])(0)), (0, _assoc2["default"])('amount', _2["default"], {
    type: to
  }));
};
var transformAndNegateMovementTypePropTo = exports.transformAndNegateMovementTypePropTo = function transformAndNegateMovementTypePropTo(propPath, to) {
  return (0, _pipe2["default"])(transformMovementTypePropTo(propPath, to), (0, _evolve2["default"])({
    amount: negateWhenNotZero
  }));
};
var lessThanZero = (0, _lt2["default"])(_2["default"], 0);
var isNegative = exports.isNegative = (0, _propSatisfies2["default"])(lessThanZero);
var buildNetAmount = exports.buildNetAmount = function buildNetAmount(buildOutcoming, buildOutgoing) {
  return (0, _pipe2["default"])((0, _juxt2["default"])([(0, _pipe2["default"])(buildOutcoming, (0, _pluck2["default"])('amount'), _sum2["default"]), (0, _pipe2["default"])(buildOutgoing, (0, _pluck2["default"])('amount'), _sum2["default"])]), _sum2["default"]);
};
var isNilProp = function isNilProp(type) {
  return (0, _propSatisfies2["default"])(_isNil2["default"], type);
};
var getOperationDate = exports.getOperationDate = function getOperationDate(dateType, fallbackDateType) {
  return (0, _pipe2["default"])((0, _when2["default"])((0, _has2["default"])('movement_object'), (0, _prop2["default"])('movement_object')), (0, _ifElse2["default"])(isNilProp(dateType), (0, _prop2["default"])(fallbackDateType), (0, _prop2["default"])(dateType)), (0, _when2["default"])((0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]), (0, _always2["default"])(null)));
};
var getSourceId = exports.getSourceId = (0, _ifElse2["default"])((0, _pathEq2["default"])(['movement_object', 'type'], 'inter_recipient'), (0, _path2["default"])(['movement_object', 'source_id']), (0, _always2["default"])(null));
var getTargetId = exports.getTargetId = (0, _ifElse2["default"])((0, _pathEq2["default"])(['movement_object', 'type'], 'inter_recipient'), (0, _path2["default"])(['movement_object', 'target_id']), (0, _always2["default"])(null));
var getTransactionId = exports.getTransactionId = (0, _ifElse2["default"])((0, _propEq2["default"])('object', 'balance_operation'), (0, _pathOr2["default"])(null, ['movement_object', 'transaction_id']), (0, _prop2["default"])('transaction_id'));
var getTransferId = exports.getTransferId = (0, _ifElse2["default"])((0, _pathEq2["default"])(['movement_object', 'object'], 'transfer'), (0, _path2["default"])(['movement_object', 'id']), (0, _always2["default"])(null));
var getSettlementId = exports.getSettlementId = (0, _ifElse2["default"])((0, _both2["default"])((0, _pathEq2["default"])(['movement_object', 'object'], 'fee_collection'), (0, _pathEq2["default"])(['movement_object', 'object_type'], 'settlement')), (0, _path2["default"])(['movement_object', 'object_id']), (0, _always2["default"])(null));
var getRecipientId = exports.getRecipientId = (0, _cond2["default"])([[(0, _pathEq2["default"])(['movement_object', 'object'], 'transfer'), (0, _pathOr2["default"])(null, ['movement_object', 'recipient', 'id'])], [(0, _propEq2["default"])('object', 'balance_operation'), (0, _pathOr2["default"])(null, ['movement_object', 'recipient_id'])], [_T2["default"], (0, _propOr2["default"])(null, 'recipient_id')]]);
var notSettlementOrLoan = (0, _complement2["default"])((0, _pathEq2["default"])((0, _either2["default"])((0, _pathEq2["default"])(['movement_object', 'object_type'], 'settlement'), (0, _pathEq2["default"])(['movement_object', 'object_type'], 'loan'))));
var getType = exports.getType = (0, _cond2["default"])([[(0, _propEq2["default"])('type', 'external_settlement'), (0, _always2["default"])('external_settlement')], [(0, _propEq2["default"])('object', 'payable'), (0, _prop2["default"])('payment_method')], [(0, _both2["default"])((0, _propEq2["default"])('type', 'refund'), (0, _pathEq2["default"])(['movement_object', 'type'], 'boleto')), (0, _always2["default"])('boletoRefundFee')], [(0, _pathEq2["default"])(['movement_object', 'type'], 'credit'), (0, _path2["default"])(['movement_object', 'payment_method'])], [(0, _both2["default"])((0, _propEq2["default"])('type', 'fee_collection'), (0, _pathEq2["default"])(['movement_object', 'object_type'], 'settlement')), (0, _path2["default"])(['movement_object', 'object_type'])], [(0, _both2["default"])((0, _propEq2["default"])('type', 'fee_collection'), (0, _pathEq2["default"])(['movement_object', 'object_type'], 'loan')), (0, _path2["default"])(['movement_object', 'type'])], [(0, _both2["default"])((0, _propEq2["default"])('type', 'fee_collection'), notSettlementOrLoan), (0, _prop2["default"])('type')], [_T2["default"], (0, _path2["default"])(['movement_object', 'type'])]]);
var getMovementObjectDescription = (0, _pathOr2["default"])('', ['movement_object', 'description']);
var getMovementObjectStatus = (0, _path2["default"])(['movement_object', 'status']);
var formatRows = exports.formatRows = function formatRows(_ref) {
  var buildOutcoming = _ref.buildOutcoming,
    buildOutgoing = _ref.buildOutgoing,
    getInstallment = _ref.getInstallment;
  return (0, _pipe2["default"])((0, _map2["default"])((0, _applySpec2["default"])({
    description: getMovementObjectDescription,
    id: (0, _prop2["default"])('id'),
    installment: getInstallment,
    settlementId: getSettlementId,
    movementObject: (0, _prop2["default"])('movement_object'),
    net: buildNetAmount(buildOutcoming, buildOutgoing),
    outcoming: buildOutcoming,
    outgoing: buildOutgoing,
    paymentDate: {
      actual: getOperationDate('payment_date', 'date_created'),
      original: getOperationDate('original_payment_date')
    },
    sourceId: getSourceId,
    status: getMovementObjectStatus,
    recipientId: getRecipientId,
    targetId: getTargetId,
    type: getType,
    transactionId: getTransactionId,
    transferId: getTransferId
  })), (0, _sortWith2["default"])([(0, _ascend2["default"])((0, _prop2["default"])('id')), (0, _ascend2["default"])((0, _path2["default"])(['paymentDate', 'actual']))]));
};