import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import styles from './style.css'

const Link = ({
  link,
  text,
}) => (
  <RouterLink className={styles.pointer} to={link}>{text}</RouterLink>
)

Link.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
}

export default Link
