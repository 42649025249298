import React, { useCallback, useEffect } from 'react'
import useCountDown from 'react-countdown-hook'
import PropTypes from 'prop-types'

import OtpConfirmation from '../../../components/OtpConfirmation'
import timerDelay from '../../../models/otp/timerDelay'

const RequestOtp = (props) => {
  const [timer, { start }] = useCountDown()
  const { handleOtpConfirmation } = props

  useEffect(() => {
    start(timerDelay)
  }, [start])

  const onResendCode = useCallback(() => {
    handleOtpConfirmation()
    start(timerDelay)
  }, [start, handleOtpConfirmation])

  return (
    <OtpConfirmation
      onConfirm={handleOtpConfirmation}
      onResendCode={onResendCode}
      time={timer}
      {...props}
    />
  )
}

RequestOtp.propTypes = {
  handleOtpConfirmation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  status: PropTypes.oneOf([
    'error',
    'success',
  ]),
  statusMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
}

RequestOtp.defaultProps = {
  status: 'error',
  statusMessage: '',
}

export default RequestOtp
