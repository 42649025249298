import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  CardTitle,
} from 'former-kit'
import styles from './styles.css'
import SummaryCredtCardInstallments from '../../../../pages/PaymentLinks/components/SummaryCreditCardInstallments'

const renderItem = (label, value) => (
  <div className={styles.item}>
    <span className={styles.label}>{label}</span>
    <span className={styles.value}>{value}</span>
  </div>
)

const PaymentMethods = ({
  boletoConfig,
  creditCardConfig,
  pixConfig,
  t,
}) => (
  <Card>
    <CardTitle title={t('pages.payment_link_detail.payment_methods.title')} />
    <CardContent>
      <div className={styles.row}>
        {creditCardConfig && (
        <div>
          <div className={styles.item}>
            <span className={styles.label}>{t('pages.payment_link_detail.payment_methods.credit_title')}</span>
            <span className={styles.value}>
              {creditCardConfig
                ? (
                  <SummaryCredtCardInstallments
                    creditCardConfig={creditCardConfig}
                    t={t}
                  />
                ) : '-'
              }
            </span>
          </div>
        </div>
        )}
        {boletoConfig && (
          <div>
            {
              renderItem(
                t('pages.payment_link_detail.payment_methods.boleto_title'),
                `${t('pages.payment_link_detail.payment_methods.boleto_subtitle')}: ${boletoConfig
                  ? boletoConfig.expires_in
                  : '-'
                }`
              )
            }
          </div>
        )}
        {pixConfig && (
          <div>
            {
              renderItem(
                t('pages.payment_link_detail.payment_methods.pix_title'),
                t('pages.payment_link_detail.payment_methods.pix_subtitle')
              )
            }
          </div>
        )}
      </div>
    </CardContent>
  </Card>
)

PaymentMethods.propTypes = {
  boletoConfig: PropTypes.shape({
    expires_in: PropTypes.number,
  }),
  creditCardConfig: PropTypes.shape({
    charge_transaction_fee: PropTypes.bool,
    free_installments: PropTypes.number,
    interest_rate: PropTypes.number,
    max_installments: PropTypes.number,
  }),
  pixConfig: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

PaymentMethods.defaultProps = {
  boletoConfig: null,
  creditCardConfig: null,
  pixConfig: null,
}

export default PaymentMethods
