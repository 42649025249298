import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  compose,
  flatten,
  ifElse,
  pathOr,
} from 'ramda'
import { useSelector } from 'react-redux'
import { translate } from 'react-i18next'
import {
  Col,
  Grid,
  Row,
} from 'former-kit'
import {
  addMonths, addWeeks, endOfDay, isAfter, isBefore, startOfDay,
} from 'date-fns'
import {
  NewLinksCard,
  PaymentLinksList,
} from '../../../../containers/PaymentLinks/List'
import { withError } from '../../../ErrorBoundary'
import environment from '../../../../environment'
import usePostPaymentLinksReports from '../../../../hooks/usePostPaymentLinksReports'
import useGetPaymentLinks from '../../../../hooks/useGetPaymentLinks'
import useFileExporterFromBFF from '../../../../hooks/useFileExporterFromBFF'
import useQueryParams from '../../../../hooks/useQueryParams'
import FilterCard from '../../components/FilterCard'

const defaultColumnSize = {
  desk: 12,
  palm: 12,
  tablet: 12,
  tv: 12,
}

const enhanced = compose(
  translate(),
  withError
)

const isMigratedPaymentLink = pathOr(false, ['features', 'migrated_payment_link'])
const getMerchId = pathOr(false, ['features', 'merch_id'])

const isLive = environment === 'live'

const getAccountId = ifElse(
  () => isLive,
  pathOr(false, ['features', 'acc_id']),
  pathOr(false, ['features', 'sdx_acc_id'])
)

const getRequestStatus = status => flatten(status?.map(st => (st === 'inactive' ? ['canceled', 'expired'] : st)) || [])

const List = ({
  t,
}) => {
  const history = useHistory()
  const { company, user } = useSelector(state => state.account)
  const { params, update: updateQueryParams } = useQueryParams()
  const { isLoading: isLoadingExport, request } = usePostPaymentLinksReports()

  const [startDate, setStartDate] = useState(
    startOfDay(new Date(params?.created_since ?? addMonths(new Date(), -1)))
  )

  const [endDate, setEndDate] = useState(
    endOfDay(new Date(params?.created_until ?? new Date()))
  )

  const [filters, setFilters] = useState({
    ids: params?.ids,
    names: params?.names,
    short_ids: params?.short_ids,
    size: params?.size || 15,
    status: params?.status?.split(',') ?? ['active', 'inactive'],
  })

  const { isLoading, request: getPaymentLinks, result } = useGetPaymentLinks({
    parameters: {
      ...filters,
      created_since: startDate,
      created_until: endDate,
      ids: filters.ids?.trim(),
      names: filters.names?.trim(),
      short_ids: filters.short_ids?.trim(),
      status: getRequestStatus(filters.status),
    },
    runOnMount: true,
  })

  const paymentLinks = result?.items || []
  const pagination = result?.pagination || {}

  const onAddPaymentLink = () => {
    const isMigrated = isMigratedPaymentLink(company)
    const merchId = getMerchId(company)
    const accountId = getAccountId(company)

    if (isMigrated && !!merchId && !!accountId) {
      window.open(`https://shop.pagar.me/${merchId}/${accountId}`, '_blank')
    } else if (isMigrated) {
      window.open('https://shop.pagar.me/', '_blank')
    } else {
      history.push('/payment-links/create')
    }
  }

  const exportFile = useFileExporterFromBFF({
    exportPrefix: 'payment_links',
  })

  const handleExport = async (type) => {
    const options = {
      fields: ['date_created', 'id', 'name', 'status', 'amount', 'url'],
      report_type: type === 'csv' ? 'CSV' : 'XLSX',
    }

    const { response } = await request({
      options,
      parameters: {
        created_since: startDate,
        created_until: endDate,
        ids: filters.ids?.trim(),
        names: filters.names?.trim(),
        short_ids: filters.short_ids?.trim(),
        status: getRequestStatus(filters.status),
      },
    })

    if (response) {
      exportFile(
        response.data,
        type
      )
    }
  }

  const handleChangeFilter = (newFilter, newStartDate, newEndDate) => {
    updateQueryParams({
      created_since: new Date(newStartDate).toISOString(),
      created_until: new Date(newEndDate).toISOString(),
      ...newFilter,
    })

    setFilters(newFilter)

    getPaymentLinks({
      parameters: {
        ...newFilter,
        created_since: startOfDay(new Date(newStartDate)),
        created_until: endOfDay(new Date(newEndDate)),
        ids: filters.ids?.trim(),
        names: filters.names?.trim(),
        short_ids: filters.short_ids?.trim(),
        status: getRequestStatus(newFilter.status),
      },
    })
  }

  const handleChangePage = (cursor) => {
    getPaymentLinks({
      parameters: {
        ...filters,
        created_since: startOfDay(new Date(startDate)),
        created_until: endOfDay(new Date(endDate)),
        cursor,
        ids: filters.ids?.trim(),
        names: filters.names?.trim(),
        short_ids: filters.short_ids?.trim(),
        status: getRequestStatus(filters.status),
      },
    })
  }

  const handleResetFilter = () => {
    const newStartDate = startOfDay(addMonths(new Date(), -1))
    const newEndDate = endOfDay(new Date())
    const defaultFilter = {
      status: ['active', 'inactive'],
    }

    setStartDate(newStartDate)
    setStartDate(newEndDate)
    setFilters({
      ids: undefined,
      names: undefined,
      short_ids: undefined,
      size: params?.size || 15,
      status: ['active', 'inactive'],
    })
    handleChangeFilter(defaultFilter, newStartDate, newEndDate)
  }

  const handleUpdateSizeItemsPerPage = (newSize) => {
    handleChangeFilter({
      ...filters,
      size: newSize,
    }, startDate, endDate)
  }

  const handleDateChange = (date, type) => {
    let newDate = date

    if (type === 'start') {
      if (isBefore(new Date(newDate), addWeeks(new Date(), -104))) {
        newDate = addWeeks(new Date(), -104)
      }
      setStartDate(startOfDay(newDate))
    } else if (type === 'end') {
      if (isAfter(new Date(newDate), new Date())) {
        newDate = new Date()
      }
      if (newDate) {
        newDate = endOfDay(newDate)
      }

      setEndDate(newDate)
    }
  }

  return (
    <>
      <Grid>
        {user.permission !== 'read_only' && (
          <Row>
            <Col {...defaultColumnSize}>
              <NewLinksCard onAddPaymentLink={onAddPaymentLink} t={t} />
            </Col>
          </Row>
        )}
        <Row>
          <Col {...defaultColumnSize}>
            <FilterCard
              filter={filters}
              startDate={startDate}
              endDate={endDate}
              loading={isLoading || isLoadingExport}
              onChange={setFilters}
              onChangeEndDate={date => handleDateChange(date, 'end')}
              onChangeStartDate={date => handleDateChange(date, 'start')}
              onReset={handleResetFilter}
              onSubmit={() => handleChangeFilter(filters, startDate, endDate)}
              t={t}
            />
          </Col>
        </Row>
        <Row>
          <Col {...defaultColumnSize}>
            <PaymentLinksList
              isLoadingExport={isLoadingExport}
              loading={isLoading}
              onExportRequest={handleExport}
              onPageChange={handleChangePage}
              onPageCountChange={handleUpdateSizeItemsPerPage}
              pageCount={Number(filters.size)}
              pagination={pagination}
              rows={paymentLinks}
              t={t}
            />
          </Col>
        </Row>
      </Grid>
    </>
  )
}

List.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(List)
