import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardActions,
  CardContent,
  CardTitle,
} from 'former-kit'
import { Pagination, Select } from '@stone-payments/jade'

import style from './style.css'

import ExportData from '../../../../components/JadeExportData'
import TableList from '../../../../components/TableList'

import tableColumns from './tableColumns'

import itemsPerPage from '../../../../models/itemsPerPage'

import EmptyStateIcon from './EmptyStateIcon.svg'

const PaymentLinksList = ({
  isLoadingExport,
  loading,
  onExportRequest,
  onPageChange,
  onPageCountChange,
  pageCount,
  pagination,
  rows,
  t,
}) => {
  const onExport = (exportTypeData) => {
    onExportRequest(exportTypeData)
  }

  const countOptions = itemsPerPage.map(i => ({
    name: t('items_per_page', { count: i }),
    value: `${i}`,
  }))

  const pageCountSelected = t('items_per_page', { count: pageCount })
  const columns = tableColumns({ t })

  const handleChangeCountOption = (option) => {
    const value = countOptions.find(item => item.name === option)?.value

    if (value) {
      onPageCountChange(Number(value))
    }
  }

  const paginationElem = (
    <Pagination
      onNext={() => onPageChange(pagination.after)}
      onPrevious={() => onPageChange(pagination.before)}
      disabledPrevious={!pagination.before}
      disabledNext={!pagination.after}
    />
  )

  return (
    <Card>
      {(loading || rows.length > 0)
        ? (
          <>
            <CardTitle
              title={(
                <h2 className={style.customTitle}>
                  {t('pages.payment_links.list.title')}
                </h2>
              )}
              subtitle={(
                <div className={style.toolBar}>
                  <>
                    <ExportData
                      disabled={isLoadingExport}
                      loading={isLoadingExport}
                      onExport={onExport}
                      t={t}
                    />
                    <Select
                      label=""
                      size="medium"
                      onChange={e => handleChangeCountOption(e.target.value)}
                      options={countOptions.map(option => option.name)}
                      value={pageCountSelected}
                    />
                    {paginationElem}
                  </>
                </div>
              )}
            />

            <CardContent>
              <TableList
                columns={columns}
                disabled={loading}
                loading={loading}
                maxColumns={6}
                rows={rows}
              />
            </CardContent>

            <CardActions>
              {paginationElem}
            </CardActions>
          </>
        )
        : (
          <CardContent>
            <div className={style.emptyState}>
              <div>
                <EmptyStateIcon />
              </div>
              <div>
                <h1>
                  {t('pages.payment_links.list.empty_state_title')}
                </h1>
                <p>
                  {t('pages.payment_links.list.empty_state_message_1')}
                  <span>{t('pages.payment_links.list.empty_state_message_2')}</span>
                  {t('pages.payment_links.list.empty_state_message_3')}
                </p>
              </div>
            </div>
          </CardContent>
        )
    }
    </Card>
  )
}

PaymentLinksList.propTypes = {
  isLoadingExport: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onExportRequest: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageCountChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pagination: PropTypes.shape({
    after: PropTypes.string,
    before: PropTypes.string,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
}

PaymentLinksList.defaultProps = {
  isLoadingExport: false,
}

export default PaymentLinksList
