import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { compose, omit } from 'ramda'
import { translate } from 'react-i18next'
import { Alert, Steps } from 'former-kit'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'

import { withError } from '../../ErrorBoundary'
import hasPix from '../../../validation/hasPix'

import TransactionItemForm from '../components/TransactionItemForm'
import CustomerForm from '../../../components/CustomerForm'
import PaymentForm from '../../../components/PaymentForm'
import ReviewTransactionOrSubscription from '../../../components/ReviewTransactionOrSubscription'
import ConclusionTransactionOrSubscription from '../../../components/ConclusionTransactionOrSubscription'

import styles from './style.css'

const enhanced = compose(
  translate(),
  withError
)

const buildStepsStatus = (steps, currentStep) => steps.map((step) => {
  if (step.id === currentStep) {
    return { id: step.id, status: 'current' }
  }

  return { id: step.id, status: 'pending' }
})

const TransactionCreate = ({ t }) => {
  const history = useHistory()
  const [currentStep, setCurrentStep] = useState('items')
  const [error, setError] = useState('')
  const [transaction, setTransaction] = useState({})
  const [transactionId, setTransactionId] = useState(null)
  const [acquirers, setAcquirers] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { client } = useSelector(state => state.account)

  const steps = [
    { id: 'items', title: t('pages.transactions.create.header.items') },
    { id: 'customer', title: t('pages.transactions.create.header.customer') },
    { id: 'payment', title: t('pages.transactions.create.header.payment') },
    { id: 'review', title: t('pages.transactions.create.header.review') },
    { id: 'conclusion', title: t('pages.transactions.create.header.conclusion') },
  ]

  useEffect(() => {
    const fetchAcquirers = async () => {
      setAcquirers(await client.acquirers.all())
    }

    fetchAcquirers()
  }, [client])

  const onNextStep = nextStep => async (formData) => {
    setError('')

    if (currentStep === 'items' || currentStep === 'payment' || nextStep === 'review') {
      setTransaction({ ...transaction, ...formData })
    }

    if (currentStep === 'customer') {
      setTransaction({ ...transaction, customer: { ...formData } })
    }

    if (nextStep === 'review' && !formData.shipping) {
      setTransaction({ ...omit(['shipping'], transaction), ...formData })
    }

    if (nextStep === 'conclusion') {
      setIsLoading(true)
      try {
        const result = await client.withVersion('2019-09-01').transactions.create(transaction)
        setTransactionId(result.id)
        setIsLoading(false)
      } catch ({ response }) {
        setError(response.errors.map(({ message }) => message).join('\n'))
        setIsLoading(false)
        return
      }
    }

    setCurrentStep(nextStep)
  }

  const onBackStep = step => () => {
    setCurrentStep(step)
    setError('')
  }

  return (
    <>
      <header className={styles.header}>
        <Steps
          status={buildStepsStatus(steps, currentStep)}
          steps={steps}
        />
      </header>
      {error && (
        <Alert
          type="error"
          icon={<IconClose height={16} width={16} />}
        >
          <p>{error}</p>
        </Alert>
      )}
      <div className={styles.content}>
        {currentStep === 'items' && (
          <TransactionItemForm
            items={transaction?.items}
            onSubmit={onNextStep('customer')}
            t={t}
          />
        )}
        {currentStep === 'customer' && (
          <CustomerForm
            {...transaction?.customer}
            onSubmit={onNextStep('payment')}
            onBack={onBackStep('items')}
            t={t}
            type="transaction"
          />
        )}
        {currentStep === 'payment' && (
          <PaymentForm
            amount={transaction.amount}
            hasPix={hasPix(acquirers)}
            billing={transaction?.billing}
            payment={{
              boleto_expiration_date: transaction?.boleto_expiration_date,
              card_cvv: transaction?.card_cvv,
              card_expiration_date: transaction?.card_expiration_date,
              card_holder_name: transaction?.card_holder_name,
              card_number: transaction?.card_number,
              installments: transaction?.installments,
              payment_method: transaction?.payment_method,
              pix_expiration_date: transaction?.pix_expiration_date,
            }}
            shipping={transaction?.shipping}
            onSubmit={onNextStep('review')}
            onBack={onBackStep('customer')}
            t={t}
          />
        )}
        {currentStep === 'review' && (
          <ReviewTransactionOrSubscription
            customer={{
              document: transaction.customer.documents[0].number,
              documentType: transaction.customer.documents[0].type,
              email: transaction.customer.email,
              name: transaction.customer.name,
              phone: transaction.customer.phone_numbers[0],
            }}
            isLoading={isLoading}
            items={transaction.items}
            payment={{
              amount: transaction.amount,
              boleto_expiration_date: transaction.boleto_expiration_date,
              card_number: transaction.card_number,
              installments: transaction.installments,
              method: transaction.payment_method,
              pix_expiration_date: transaction.pix_expiration_date,
            }}
            onSubmit={onNextStep('conclusion')}
            onBack={onBackStep('payment')}
            t={t}
          />
        )}
        {currentStep === 'conclusion' && (
          <ConclusionTransactionOrSubscription
            t={t}
            type="transaction"
            id={transactionId}
            onCreateNew={() => {
              onBackStep('items')()
              setTransaction({})
            }}
            onVisualize={() => {
              history.push(`/transactions/${transactionId}`)
            }}
          />
        )}
      </div>
    </>
  )
}

TransactionCreate.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(TransactionCreate)
