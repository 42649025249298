export default function dataWithCensure (data, length = 3) {
  if (typeof data !== 'string') {
    return data
  }

  if (length * 2 > data.length) {
    return data.replace(/./g, '*')
  }

  const firstDigits = data.slice(0, length)
  const lastDigits = data.slice(-length)

  return `${firstDigits}${lastDigits.padStart(data.length - length, '*')}`
}
