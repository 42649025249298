import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  ModalActions,
} from 'former-kit'

import PixRefundDetails from '../../../components/PixRefundDetails'

class PixConfirmation extends Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (e) {
    const { onConfirm } = this.props

    e.preventDefault()
    onConfirm()
  }

  render () {
    const {
      amount,
      disabled,
      onBack,
      recipient,
      refundAmount,
      t,
    } = this.props

    const labels = {
      amount: t('models.transaction.amount'),
      recipient: t('pages.refund.target_recipient'),
      refundAmount: t('pages.refund.refund_amount'),
    }

    const contents = {
      amount,
      recipient,
      refundAmount,
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <PixRefundDetails
          contents={contents}
          labels={labels}
          title={t('pages.refund.refund_data')}
        />
        <ModalActions>
          <Button
            disabled={disabled}
            fill="outline"
            onClick={onBack}
            type="button"
          >
            {t('pages.refund.go_back')}
          </Button>
          <Button
            disabled={disabled}
            type="submit"
          >
            {t('pages.refund.confirm')}
          </Button>
        </ModalActions>
      </form>
    )
  }
}

PixConfirmation.propTypes = {
  amount: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  recipient: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  refundAmount: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

PixConfirmation.defaultProps = {
  disabled: false,
  recipient: null,
}

export default PixConfirmation
