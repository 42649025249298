/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import {
  allPass,
  always,
  applySpec,
  anyPass,
  both,
  contains,
  either,
  equals,
  flatten,
  head,
  ifElse,
  isEmpty,
  isNil,
  juxt,
  map,
  negate,
  path,
  pipe,
  prop,
  propEq,
  reject,
  sum,
  unless,
  when,
  propOr,
  pathOr,
  toUpper,
} from 'ramda'
import {
  Card,
  CardContent,
  CardTitle,
  Col,
  Grid,
  Legend,
  Row,
  Truncate,
} from 'former-kit'
import IconCheck from 'emblematic-icons/svg/Check24.svg'
import IconClearClose from 'emblematic-icons/svg/ClearClose24.svg'
import DownloadIcon from 'emblematic-icons/svg/Download24.svg'
import IconReverse from 'emblematic-icons/svg/Reverse24.svg'
import CaptureIcon from 'emblematic-icons/svg/Wallet24.svg'
import ReprocessIcon from 'emblematic-icons/svg/Reprocess24.svg'
import AnticipationIcon from 'emblematic-icons/svg/Anticipation32.svg'
import formatDate from '../../formatters/longDate'
import currencyFormatter from '../../formatters/currency'
import CustomerCard from '../../components/CustomerCard'
import DetailsHead from '../../components/DetailsHead'
import RecipientList from './RecipientList'
import statusLegends from '../../models/statusLegends'
import TotalDisplay from '../../components/TotalDisplay'
import TransactionDetailsCard from '../../components/TransactionDetailsCard'
import ShippingDetailsCard from '../../components/ShippingDetailsCard'
import TransactionItemsCard from '../../components/TransactionItemsCard'
import TreeView from '../../components/TreeView'
import Events from './Events'
import AlertInfo from './AlertInfo'
import ReprocessAlerts from './ReprocessAlerts'
import RefuseAlert from './RefuseAlert'
import RenderPayment from './RenderPayment'
import RenderOutAmountSubTitle from './RenderOutAmountSubtitle'
import style from './style.css'
import formatCpfCnpj from '../../formatters/cpfCnpj'

import env from '../../environment'
import installmentStatus from '../../models/installmentStatus'
import TransactionPayerDetails from '../../components/TransactionPayerDetails'

const isZeroOrNegative = value => value <= 0

const isLive = env === 'live'
const isTest = env === 'test'

const formatValues = map(when(
  isNil,
  always(0)
))

const getOutAmount = pipe(
  formatValues,
  sum,
  unless(
    isZeroOrNegative,
    negate
  )
)

const isEmptyOrNull = anyPass([isEmpty, isNil])

const isChargebackStatus = either(
  contains('chargeback'),
  contains('chargedback')
)

const isChargebackedTransaction = pipe(
  prop('status'),
  isChargebackStatus
)

const isBoletoTransaction = pipe(
  path(['payment', 'method']),
  equals('boleto')
)

const isPixTransaction = pipe(
  path(['payment', 'method']),
  equals('pix')
)

const isWaitingPaymentTransaction = pipe(
  prop('status'),
  equals('waiting_payment')
)

const isBoletoWaitingPayment = both(
  isBoletoTransaction,
  isWaitingPaymentTransaction
)

const isPixWaitingPayment = both(
  isPixTransaction,
  isWaitingPaymentTransaction
)

const isPendingReviewTransaction = propEq('status', 'pending_review')

const showStatusAlert = either(
  isChargebackedTransaction,
  isBoletoWaitingPayment
)

const formatDocument = applySpec({
  number: pipe(
    prop('number'),
    formatCpfCnpj
  ),
  type: prop('type'),
})

const formatCustomerBirthDay = customer => ({
  ...customer,
  birthday: !isNil(customer.birthday)
    ? formatDate(customer.birthday)
    : null,
})

const getDefaultDocumentNumber = pipe(
  prop('documents'),
  ifElse(
    either(isNil, isEmpty),
    always(null),
    pipe(
      head,
      formatDocument,
      prop('number')
    )
  )
)

const formatCustomerAddress = (customer) => {
  if (!customer.address) {
    return customer
  }

  const { id, ...address } = customer.address

  return {
    ...address,
    ...customer,
  }
}

const formatCustomerDocuments = customer => ({
  ...customer,
  document_number: getDefaultDocumentNumber(customer),
})

const formatCustomerData = pipe(
  formatCustomerBirthDay,
  formatCustomerDocuments,
  formatCustomerAddress
)

const getHeaderAmountLabel = (transaction, headerLabels) => {
  if (isBoletoTransaction(transaction)) {
    return headerLabels.boletoAmountLabel
  }
  return headerLabels.cardAmountLabel
}

const renderLegend = status => (
  <Legend
    acronym={statusLegends[status].text}
    color={statusLegends[status].color}
    hideLabel
    textColor={statusLegends[status].textColor}
  >
    { statusLegends[status].text }
  </Legend>
)

const validatePreviousTransactionRedirect = (props, propName) => {
  if (propName === 'onPreviousTransactionRedirect') {
    const {
      onPreviousTransactionRedirect,
      transaction: {
        previousId,
      },
    } = props

    if (previousId && isNil(onPreviousTransactionRedirect)) {
      throw new Error('The prop onPreviousTransactionRedirect must be a function when transaction.previousId is not null')
    }
  }
}

const validateNextTransactionRedirect = (props, propName) => {
  if (propName === 'onNextTransactionRedirect') {
    const {
      nextTransactionId,
      onNextTransactionRedirect,
      transaction: {
        nextId,
      },
    } = props

    if ((nextId || nextTransactionId) && isNil(onNextTransactionRedirect)) {
      throw new Error('The prop onNextTransactionRedirect must be a function when transaction.nextId is not null')
    }
  }
}

const validateCaptureFunction = (props, propName) => {
  if (propName === 'onCapture') {
    const {
      onCapture,
      transaction: {
        capabilities,
      },
    } = props

    if (capabilities && capabilities.capturable && isNil(onCapture)) {
      throw new Error('The prop onCapture must be a function when transaction.capabilities.capturable is true')
    }
  }
}

const validateRefundFunction = (props, propName) => {
  if (propName === 'onRefund') {
    const {
      onRefund,
      transaction: {
        capabilities,
      },
    } = props

    if (capabilities && capabilities.refundable && isNil(onRefund)) {
      throw new Error('The prop onRefund must be a function when transaction.capabilities.refundable is true')
    }
  }
}

const validateReprocessFunction = (props, propName) => {
  if (propName === 'onReprocess') {
    const {
      onReprocess,
      transaction: {
        capabilities,
      },
    } = props

    if (capabilities && capabilities.reprocessable && isNil(onReprocess)) {
      throw new Error('The prop onReprocess must be a function when transaction.capabilities.reprocessable is true')
    }
  }
}

class TransactionDetails extends Component {
  constructor (props) {
    super(props)
    this.getActions = this.getActions.bind(this)
  }

  getActions () {
    const {
      actionLabels,
      canExportCancellationLetter,
      handleRefundReceiptDownload,
      headerLabels,
      loading: {
        manualReview: isLoadingManualReview,
        reprocess,
      },
      onCapture,
      onExport,
      onManualReviewApprove,
      onManualReviewRefuse,
      onRefund,
      onReprocess,
      onResendBoleto,
      onSimulatePayment,
      permissions,
      transaction,
      transaction: {
        capabilities,
        payment: {
          method,
        },
      },
    } = this.props

    const onCaptureAction = {
      icon: <CaptureIcon width={12} height={12} />,
      onClick: onCapture,
      title: method === 'boleto' && capabilities.capturable
        ? actionLabels.boleto
        : actionLabels.capture,
    }

    const onExportAction = {
      icon: <DownloadIcon width={12} height={12} />,
      onClick: onExport,
      title: actionLabels.export,
    }

    const onReprocessAction = {
      icon: <ReprocessIcon width={12} height={12} />,
      loading: reprocess,
      onClick: onReprocess,
      title: reprocess
        ? actionLabels.reprocessing
        : actionLabels.reprocess,
    }

    const onRefundAction = {
      icon: <IconReverse width={12} height={12} />,
      onClick: onRefund,
      title: actionLabels.refund,
    }

    const onRefundReceipt = {
      icon: <DownloadIcon width={12} height={12} />,
      onClick: handleRefundReceiptDownload,
      title: actionLabels.exportRefundPdf,
    }

    const getManualReviewTransactionActions = (trx) => {
      if (isPendingReviewTransaction(trx) && permissions.manualReview) {
        return [
          {
            disabled: isLoadingManualReview,
            icon: <IconClearClose width={12} height={12} />,
            loading: isLoadingManualReview,
            onClick: () => onManualReviewRefuse('refused'),
            title: headerLabels.refuseLabel,
          },
          {
            disabled: isLoadingManualReview,
            icon: <IconCheck width={12} height={12} />,
            loading: isLoadingManualReview,
            onClick: () => onManualReviewApprove('approved'),
            title: headerLabels.approveLabel,
          },
        ]
      }
      return []
    }

    const getResendBoletoAction = (trx) => {
      if (isBoletoWaitingPayment(trx) && isLive) {
        return [
          {
            icon: <IconReverse width={12} height={12} />,
            onClick: onResendBoleto,
            title: actionLabels.resendBoleto,
          },
        ]
      }

      return []
    }

    const transactionIsWaitingPayment = either(
      isBoletoWaitingPayment,
      isPixWaitingPayment
    )
    const userPermissionIsNotReadOnly = !permissions.readOnly

    const shouldShowSimulatePaymentAction = allPass([
      transactionIsWaitingPayment,
      always(userPermissionIsNotReadOnly),
      always(isTest),
    ])

    const getSimulatePaymentAction = (trx) => {
      if (shouldShowSimulatePaymentAction(trx)) {
        return [
          {
            icon: <AnticipationIcon width={12} height={12} />,
            onClick: onSimulatePayment,
            title: actionLabels.simulatePayment,
          },
        ]
      }

      return []
    }

    const detailsHeadActions = pipe(
      juxt([
        ifElse(
          both(
            propEq('capturable', true),
            always(permissions.capture)
          ),
          always(onCaptureAction),
          always(null)
        ),
        ifElse(
          both(
            propEq('reprocessable', true),
            always(permissions.reprocess)
          ),
          always(onReprocessAction),
          always(null)
        ),
        ifElse(
          () => canExportCancellationLetter,
          always(onRefundReceipt),
          always(null)
        ),
        ifElse(
          both(
            propEq('refundable', true),
            always(permissions.refund)
          ),
          always(onRefundAction),
          always(null)
        ),
        always(getManualReviewTransactionActions(transaction)),
        always(getResendBoletoAction(transaction)),
        always(getSimulatePaymentAction(transaction)),
        always(onExportAction),
      ]),
      flatten,
      reject(isNil)
    )

    return detailsHeadActions(capabilities)
  }

  mountPayerDetails = (transaction) => {
    if (
      !transaction
      || !transaction.pix
      || !transaction.pix.payer
      || !transaction.pix.payer.bank_account
    ) {
      return false
    }

    const { payer } = transaction.pix

    /* eslint-disable */
    const PAYER_DETAILS = {
      name: payer.name,
      document: payer.document,
      bank_name: payer.bank_account.bank_name,
    }
    /* eslint-enable */

    return Object.entries(PAYER_DETAILS).map(([key, value]) => ({ key, value }))
  }

  render () {
    const {
      alertLabels,
      boletoWarningMessage,
      customerLabels,
      eventPaymentLabel,
      headerLabels,
      metadataTitle,
      nextTransactionId,
      onChangeCollapse,
      onCopyBoletoUrl,
      onCopyQrCodeUrl,
      onNextTransactionRedirect,
      onPreviousTransactionRedirect,
      onShowBoleto,
      paymentBoletoLabels,
      paymentCardLabels,
      paymentPixLabels,
      recipientsData,
      reprocessLabels,
      shippingLabels,
      t,
      tooltipLabels,
      totalDisplayLabels,
      transaction,
      transactionDetailsLabels,
    } = this.props

    const {
      acquirer,
      amount,
      boleto,
      card,
      customer,
      id,
      items,
      metadata,
      operations,
      payment,
      pix,
      recipients,
      risk_level: riskLevel,
      shipping,
      status,
      status_reason: statusReason,
    } = transaction

    let transactionDetailsContent = applySpec({
      acquirer_name: pathOr(null, ['acquirer', 'name']),
      acquirer_response_code: pathOr(null, ['acquirer', 'responde_code']),
      authorization_code: propOr(null, 'authorization_code'),
      capture_method: pathOr(null, ['card', 'capture_method']),
      nsu: pathOr(null, ['acquirer', 'sequence_number']),
      soft_descriptor: propOr(null, 'soft_descriptor'),
      subscription_id: pathOr(null, ['subscription', 'id']),
      tid: pathOr(null, ['acquirer', 'transaction_id']),
    })(transaction)

    if (transactionDetailsContent.subscription_id) {
      const subscriptionId = transactionDetailsContent.subscription_id
      transactionDetailsContent.subscription_id = (
        <Link className={style.link} to={`/subscriptions/${subscriptionId}`}>
          {subscriptionId}
        </Link>
      )
    }

    const shippingDetailsContent = ifElse(
      isNil,
      always(null),
      applySpec({
        city: path(['address', 'city']),
        complementary: path(['address', 'complementary']),
        country: pipe(path(['address', 'country']), toUpper),
        delivery_date: pipe(prop('delivery_date'), formatDate),
        expedited: prop('expedited'),
        fee: pipe(prop('fee'), currencyFormatter),
        id: prop('id'),
        neighborhood: path(['address', 'neighborhood']),
        number: path(['address', 'street_number']),
        recipient: prop('name'),
        state: path(['address', 'state']),
        street: path(['address', 'street']),
        zipcode: path(['address', 'zipcode']),
      })
    )(transaction.shipping)

    if (pix && pix.end_to_end_id) {
      transactionDetailsLabels.pix_id = t('pages.transaction.pix.id')
      transactionDetailsContent = {
        pix_id: pix.end_to_end_id,
        ...transactionDetailsContent,
      }
    }
    const formattedCustomer = formatCustomerData(customer || {})
    const isRefundedBeforeCaptured = status === 'refunded' && payment.paid_amount === 0

    const applyTruncateCustomerEmail = (
      <span className={style.value}>
        <Truncate
          text={formattedCustomer.email}
        />
      </span>
    )

    const customerDetailsContent = {
      birthday: formattedCustomer && formattedCustomer.birthday,
      city: formattedCustomer && formattedCustomer.city,
      complementary: formattedCustomer && formattedCustomer.complementary,
      document_number: formattedCustomer && formattedCustomer.document_number,
      email: formattedCustomer.email && applyTruncateCustomerEmail,
      name: formattedCustomer && formattedCustomer.name,
      neighborhood: formattedCustomer && formattedCustomer.neighborhood,
      phone: formattedCustomer && formattedCustomer.phone,
      state: formattedCustomer && formattedCustomer.state,
      street: formattedCustomer && formattedCustomer.street,
      street_number: formattedCustomer && formattedCustomer.street_number,
      zipcode: formattedCustomer && formattedCustomer.zipcode,
    }

    if (isEmpty(transaction)) {
      return (<div />)
    }

    const detailsHeadProperties = [
      {
        children: renderLegend(status),
        title: headerLabels.statusLabel,
      },
      {
        children: headerLabels.installments,
        title: headerLabels.installmentsLabel,
      },
      {
        children: (
          <strong className={style.children}>
            {currencyFormatter(amount)}
          </strong>
        ),
        title: getHeaderAmountLabel(transaction, headerLabels),
      },
    ]

    const transactionPayerDetails = this.mountPayerDetails(transaction)

    return (
      <Grid className={style.grid}>
        <ReprocessAlerts
          nextTransactionId={nextTransactionId}
          onNextTransactionRedirect={onNextTransactionRedirect}
          onPreviousTransactionRedirect={onPreviousTransactionRedirect}
          reprocessLabels={reprocessLabels}
          transaction={transaction}
        />
        <Row stretch className={style.transactionInfo}>
          <Col
            desk={12}
            tv={12}
            tablet={12}
            palm={12}
          >
            <Card>
              <CardContent>
                <DetailsHead
                  actions={this.getActions()}
                  identifier={`#${id}`}
                  properties={detailsHeadProperties}
                  title={headerLabels.title}
                />
              </CardContent>
            </Card>
          </Col>
        </Row>
        <RefuseAlert
          acquirer={acquirer}
          status={status}
          statusReason={statusReason}
          t={t}
        />
        <Row stretch className={style.paymentInfo}>
          <Col
            desk={3}
            palm={12}
            tablet={6}
            tv={3}
          >
            <RenderPayment
              boleto={boleto}
              card={card}
              onCopyBoletoUrl={onCopyBoletoUrl}
              onCopyQrCodeUrl={onCopyQrCodeUrl}
              onShowBoleto={onShowBoleto}
              payment={payment}
              paymentBoletoLabels={paymentBoletoLabels}
              paymentCardLabels={paymentCardLabels}
              paymentPixLabels={paymentPixLabels}
              pixQrCode={pix?.qr_code}
              pixExpirationDate={pix?.expiration_date}
            />
          </Col>
          <Col
            desk={3}
            palm={12}
            tablet={6}
            tv={3}
          >
            <Card className={style.paidAmountValue}>
              <CardContent className={style.content}>
                <TotalDisplay
                  align="start"
                  amount={isRefundedBeforeCaptured
                    ? payment.authorized_amount
                    : payment.paid_amount
                  }
                  amountSize="large"
                  color="#4d4f62"
                  subtitle={(
                    <div className={style.subtitle}>
                      {totalDisplayLabels.captured_at}
                    </div>
                  )}
                  title={isRefundedBeforeCaptured
                    ? totalDisplayLabels.authorized_amount
                    : totalDisplayLabels.paid_amount
                  }
                  titleSize="medium"
                />
              </CardContent>
            </Card>
          </Col>
          <Col
            desk={3}
            palm={12}
            tablet={6}
            tv={3}
          >
            <Card className={style.outAmountValue}>
              <CardContent className={style.content}>
                <TotalDisplay
                  align="start"
                  amount={
                    getOutAmount([
                      payment.refund_amount,
                      payment.mdr_amount,
                      payment.fraud_coverage_amount,
                      payment.anticipation_fee,
                    ])
                  }
                  amountSize="large"
                  color="#4d4f62"
                  subtitle={(
                    <RenderOutAmountSubTitle
                      payment={payment}
                      tooltipLabels={tooltipLabels}
                      totalDisplayLabels={totalDisplayLabels}
                    />
                  )}
                  title={totalDisplayLabels.out_amount}
                  titleSize="medium"
                />
              </CardContent>
            </Card>
          </Col>
          <Col
            desk={3}
            palm={12}
            tablet={6}
            tv={3}
          >
            <Card className={style.netAmountValue}>
              <CardContent className={style.content}>
                <TotalDisplay
                  align="start"
                  amount={(
                    payment.net_amount
                    + payment.cost_amount
                    - payment.fraud_coverage_amount
                    - payment.anticipation_fee
                  )}
                  amountSize="large"
                  color="#4d4f62"
                  subtitle={(
                    <div className={style.subtitle}>
                      {totalDisplayLabels.receive_date}
                    </div>
                  )}
                  title={totalDisplayLabels.net_amount}
                  titleSize="medium"
                />
              </CardContent>
            </Card>
          </Col>
        </Row>
        {showStatusAlert(transaction)
          && (
            <Row className={style.alertCustom}>
              <Col
                desk={12}
                palm={12}
                tablet={12}
                tv={12}
              >
                <AlertInfo
                  action={alertLabels.resubmit}
                  alertLabels={alertLabels}
                  boletoWarningMessage={boletoWarningMessage}
                  isBoletoWaitingPayment={isBoletoWaitingPayment(transaction)}
                />
              </Col>
            </Row>
          )
        }
        {
          (!isEmptyOrNull(recipients)
          && transaction?.split_rules
          && transaction?.split_rules.length !== 0)
          || transaction?.payment.method === 'credit_card'
            ? (
              <Row>
                <RecipientList
                  t={t}
                  splitRules={transaction?.split_rules}
                  recipientsData={recipientsData}
                  onChangeCollapse={onChangeCollapse}
                  methodPayment={transaction?.payment.method}
                />
              </Row>
            )
            : null
        }

        <Row>
          <Col
            desk={9}
            palm={12}
            tablet={12}
            tv={9}
          >
            <Grid className={style.detailsInfo}>
              {!isEmptyOrNull(customer)
                && (
                  <Row className={style.customerInfo}>
                    <Col
                      desk={12}
                      palm={12}
                      tablet={12}
                      tv={12}
                    >
                      <CustomerCard
                        contents={customerDetailsContent}
                        labels={customerLabels}
                        title={customerLabels.title}
                      />
                    </Col>
                  </Row>
                )
              }
              <Row className={style.transactionCardInfo}>
                <Col
                  desk={12}
                  palm={12}
                  tablet={12}
                  tv={12}
                >
                  <TransactionDetailsCard
                    contents={transactionDetailsContent}
                    labels={transactionDetailsLabels}
                    title={transactionDetailsLabels.title}
                  />
                </Col>
              </Row>

              {transactionPayerDetails
                && (
                <Row className={style.transactionCardInfo}>
                  <Col
                    desk={12}
                    palm={12}
                    tablet={12}
                    tv={12}
                  >
                    <TransactionPayerDetails
                      t={t}
                      payerDetails={transactionPayerDetails}
                    />
                  </Col>
                </Row>
                )
              }
              {!isEmptyOrNull(shipping) && (
                <Row>
                  <Col
                    desk={12}
                    palm={12}
                    tablet={12}
                    tv={12}
                  >
                    <ShippingDetailsCard
                      data={shippingDetailsContent}
                      labels={shippingLabels}
                      title={shippingLabels.title}
                    />
                  </Col>
                </Row>
              )}
              {!isEmptyOrNull(items) && (
              <Row className={style.transactionCardInfo}>
                <Col
                  desk={12}
                  palm={12}
                  tablet={12}
                  tv={12}
                >
                  <TransactionItemsCard
                    contents={items}
                    t={t}
                  />
                </Col>
              </Row>
              )}
              {!isEmptyOrNull(metadata)
                && (
                  <Row className={style.metadataInfo}>
                    <Col
                      desk={12}
                      palm={12}
                      tablet={12}
                      tv={12}
                    >
                      <TreeView
                        data={metadata}
                        title={metadataTitle}
                      />
                    </Col>
                  </Row>
                )
              }
            </Grid>
          </Col>
          <Col
            desk={3}
            palm={12}
            tablet={12}
            tv={3}
            className={style.eventsList}
          >
            <Card>
              <CardTitle
                className={style.eventCardTitle}
                title={t('pages.transaction.events.title')}
              />
              <div>
                <Events
                  boleto={boleto}
                  color={statusLegends[status].color}
                  eventPaymentLabel={eventPaymentLabel}
                  fraudReimbursed={status === 'fraud_reimbursed'}
                  t={t}
                  id={id}
                  operations={operations}
                  payment={payment}
                  riskLevel={riskLevel}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Grid>
    )
  }
}

TransactionDetails.propTypes = {
  actionLabels: PropTypes.shape({
    boleto: PropTypes.string,
    capture: PropTypes.string,
    export: PropTypes.string,
    exportRefundPdf: PropTypes.string,
    refund: PropTypes.string,
    reprocess: PropTypes.string,
    reprocessing: PropTypes.string,
    resendBoleto: PropTypes.string,
    simulatePayment: PropTypes.string,
  }).isRequired,
  alertLabels: PropTypes.shape({
    chargeback_reason: PropTypes.string,
    chargeback_reason_label: PropTypes.string,
    reason_code: PropTypes.string,
    resubmit: PropTypes.string,
  }).isRequired,
  boletoWarningMessage: PropTypes.string.isRequired,
  canExportCancellationLetter: PropTypes.bool.isRequired,
  customerLabels: PropTypes.shape({
    birthday: PropTypes.string,
    city: PropTypes.string,
    complement: PropTypes.string,
    document_number: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    neighborhood: PropTypes.string,
    number: PropTypes.string,
    phone: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    title: PropTypes.string,
    zip_code: PropTypes.string,
  }).isRequired,
  eventPaymentLabel: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  handleRefundReceiptDownload: PropTypes.func,
  headerLabels: PropTypes.shape({
    approveLabel: PropTypes.string,
    installments: PropTypes.string,
    installmentsLabel: PropTypes.string,
    refuseLabel: PropTypes.string,
    status: PropTypes.string,
    statusLabel: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  loading: PropTypes.shape({
    manualReview: PropTypes.bool,
    reprocess: PropTypes.bool,
  }),
  metadataTitle: PropTypes.string.isRequired,
  nextTransactionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onCapture: validateCaptureFunction,
  onChangeCollapse: PropTypes.func.isRequired,
  onCopyBoletoUrl: PropTypes.func,
  onCopyQrCodeUrl: PropTypes.func,
  onDismissAlert: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  onExport: PropTypes.func,
  onManualReviewApprove: PropTypes.func,
  onManualReviewRefuse: PropTypes.func,
  onNextTransactionRedirect: validateNextTransactionRedirect,
  onPreviousTransactionRedirect: validatePreviousTransactionRedirect,
  onRefund: validateRefundFunction,
  onReprocess: validateReprocessFunction,
  onResendBoleto: PropTypes.func,
  onShowBoleto: PropTypes.func,
  onSimulatePayment: PropTypes.func,
  paymentBoletoLabels: PropTypes.shape({
    copy: PropTypes.string,
    due_date: PropTypes.string,
    feedback: PropTypes.string,
    show: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  paymentCardLabels: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  paymentPixLabels: PropTypes.shape({
    dueDate: PropTypes.string,
    showQrcode: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  permissions: PropTypes.shape({
    capture: PropTypes.bool.isRequired,
    manualReview: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    refund: PropTypes.bool.isRequired,
    reprocess: PropTypes.bool.isRequired,
  }).isRequired,
  recipientsData: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    collapsed: PropTypes.bool,
    id: PropTypes.string,
    installments: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number,
        fees: PropTypes.number,
        net_amount: PropTypes.number,
        number: PropTypes.number,
        original_payment_date: PropTypes.string,
        outcoming_amount: PropTypes.number,
        outgoing_amount: PropTypes.number,
        payment_date: PropTypes.string,
        status: PropTypes.oneOf(Object.keys(installmentStatus)),
      })
    ),
    liable: PropTypes.bool,
    name: PropTypes.String,
    net_amount: PropTypes.number,
  })).isRequired,
  reprocessLabels: PropTypes.shape({
    nextAlert: PropTypes.string,
    previousAlert: PropTypes.string,
    showNext: PropTypes.string,
    showPrevious: PropTypes.string,
  }).isRequired,
  riskLevelsLabels: PropTypes.shape({
    high: PropTypes.string,
    low: PropTypes.string,
    moderated: PropTypes.string,
    very_high: PropTypes.string,
    very_low: PropTypes.string,
  }).isRequired,
  shippingLabels: PropTypes.shape({
    city: PropTypes.string,
    complementary: PropTypes.string,
    country: PropTypes.string,
    delivery_date: PropTypes.string,
    expedited: PropTypes.string,
    fee: PropTypes.string,
    neighborhood: PropTypes.string,
    number: PropTypes.string,
    recipient: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    title: PropTypes.string,
    zipcode: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  tooltipLabels: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  totalDisplayLabels: PropTypes.shape({
    authorized_amount: PropTypes.string,
    captured_at: PropTypes.string,
    cost: PropTypes.string,
    fee: PropTypes.shape({
      boleto: PropTypes.string,
      credit_card: PropTypes.string,
      pix: PropTypes.string,
    }),
    net_amount: PropTypes.string,
    out_amount: PropTypes.string,
    paid_amount: PropTypes.string,
    receive_date: PropTypes.string,
    refund: PropTypes.string,
  }).isRequired,
  transaction: PropTypes.shape({
    acquirer: PropTypes.shape({
      name: PropTypes.string,
      response_code: PropTypes.string,
      response_message: PropTypes.string,
      sequence_number: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      transaction_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
    amount: PropTypes.number,
    antifraud: PropTypes.object,
    boleto: PropTypes.shape({
      barcode: PropTypes.string,
      due_date: PropTypes.instanceOf(moment),
      url: PropTypes.string,
    }),
    capabilities: PropTypes.shape({
      capturable: PropTypes.bool,
      refundable: PropTypes.bool,
      reprocessable: PropTypes.bool,
    }),
    card: PropTypes.shape({
      brand_name: PropTypes.string,
      first_digits: PropTypes.string,
      funding_source: PropTypes.string,
      holder_name: PropTypes.string,
      international: PropTypes.bool,
      last_digits: PropTypes.string,
      pin_mode: PropTypes.string,
    }),
    created_at: PropTypes.instanceOf(moment),
    customer: PropTypes.shape({
      birth_date: PropTypes.string,
      country: PropTypes.string,
      document_number: PropTypes.string,
      document_type: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      phones: PropTypes.arrayOf(PropTypes.string),
    }),
    external_id: PropTypes.string,
    id: PropTypes.number,
    items: PropTypes.array,
    metadata: PropTypes.object, // eslint-disable-line
    operations: PropTypes.arrayOf(PropTypes.shape({
      created_at: PropTypes.instanceOf(moment),
      cycle: PropTypes.number,
      status: PropTypes.string,
      type: PropTypes.string,
    })),
    payment: PropTypes.shape({
      anticipation_fee: PropTypes.number,
      authorized_amount: PropTypes.number,
      cost_amount: PropTypes.number,
      fraud_coverage_amount: PropTypes.number,
      installments: PropTypes.number,
      mdr_amount: PropTypes.string,
      method: PropTypes.string,
      net_amount: PropTypes.number,
      paid_amount: PropTypes.number,
      refund_amount: PropTypes.number,
    }),
    pix: PropTypes.shape({
      end_to_end_id: PropTypes.string,
      expiration_date: PropTypes.string,
      payer: PropTypes.shape({
        bank_account: PropTypes.shape({
          account_number: PropTypes.string,
          bank_name: PropTypes.string,
          branch_name: PropTypes.string,
          isbp: PropTypes.string,
        }),
        document: PropTypes.string,
        document_type: PropTypes.string,
        name: PropTypes.string,
      }),
      qr_code: PropTypes.string,
    }),
    recipients: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      installments: PropTypes.arrayOf(PropTypes.shape({
        amount: PropTypes.number,
        costs: PropTypes.shape({
          anticipation: PropTypes.number,
          mdr: PropTypes.number,
        }),
        created_at: PropTypes.instanceOf(moment),
        net_amount: PropTypes.number,
        number: PropTypes.number,
        original_payment_date: PropTypes.string,
        payment_date: PropTypes.string,
      })),
      liabilities: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      net_amount: PropTypes.number,
    })),
    risk_level: PropTypes.string,
    shipping: PropTypes.object,
    soft_descriptor: PropTypes.string,
    split_rules: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string,
    status_reason: PropTypes.string,
    updated_at: PropTypes.instanceOf(moment),
  }).isRequired,
  transactionDetailsLabels: PropTypes.shape({
    acquirer_name: PropTypes.string,
    acquirer_response_code: PropTypes.string,
    authorization_code: PropTypes.string,
    capture_method: PropTypes.string,
    nsu: PropTypes.string,
    pix_id: PropTypes.string,
    soft_descriptor: PropTypes.string,
    subscription_id: PropTypes.string,
    tid: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

TransactionDetails.defaultProps = {
  handleRefundReceiptDownload: () => {},
  loading: {
    manualReview: false,
    reprocess: false,
  },
  nextTransactionId: null,
  onCapture: null,
  onCopyBoletoUrl: null,
  onCopyQrCodeUrl: null,
  onDismissAlert: null,
  onExport: null,
  onManualReviewApprove: null,
  onManualReviewRefuse: null,
  onNextTransactionRedirect: null,
  onPreviousTransactionRedirect: null,
  onRefund: null,
  onReprocess: null,
  onResendBoleto: null,
  onShowBoleto: null,
  onSimulatePayment: null,
}

export default TransactionDetails
