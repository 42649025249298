import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'
import {
  Alert,
  Modal,
  ModalTitle,
  ModalContent,
  FormInput,
  CardActions,
  Button,
  Flexbox,
  Spacing,
  ModalActions,
} from 'former-kit'
import IconError from 'emblematic-icons/svg/CloseCircle32.svg'
import Form from 'react-vanilla-form'
import { useSelector } from 'react-redux'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import requiredValidation from '../../../../validation/required'
import emailValidation from '../../../../validation/email'
import BenchGuySvg from '../../../../assets/bench_guy.svg'
import style from './style.css'
import { withError } from '../../../ErrorBoundary'

const enhanced = compose(
  translate(),
  withError
)

const tPrefix = 'pages.resend_boleto'

const ResendBoletoModal = ({
  isOpen,
  onClose,
  t,
  transaction,
}) => {
  const [dataForm, setDataForm] = useState({ email: '' })
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(false)
  const [showSuccessContent, setShowSuccessContent] = useState(false)
  const [updateError, setUpdateError] = useState(false)

  const { client } = useSelector(state => state.account)

  const onChange = (data, errors) => {
    setDataForm(data)
    setFormError(Object.keys(errors).length > 0)
  }

  const handleSubmit = async (_, errors) => {
    if (!errors) {
      try {
        setUpdateError(false)
        setLoading(true)

        const { id } = transaction

        await client.transactions.collectPayment({
          email: dataForm.email,
          id,
        })

        setShowSuccessContent(true)
      } catch {
        setUpdateError(true)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleClose = () => {
    onClose()
    setShowSuccessContent(false)
    setDataForm({ email: '' })
    setFormError(false)
  }

  const isRequired = requiredValidation(t(`${tPrefix}.errors.required`))
  const isEmail = emailValidation(t(`${tPrefix}.errors.email`))

  const formValidation = {
    email: [isRequired, isEmail],
  }

  const getFormContent = () => (
    <>
      {updateError && (
        <div className={style.alert}>
          <Alert
            icon={<IconError height={16} width={16} />}
            type="error"
          >
            <span>{t(`${tPrefix}.errors.generic_error`)}</span>
          </Alert>
        </div>
      )
      }
      <Form
        data={dataForm}
        errors={formError}
        onChange={onChange}
        onSubmit={handleSubmit}
        validateOn="change"
        validation={formValidation}
      >
        <div className={style.inputContainer}>
          <FormInput
            label={t(`${tPrefix}.email_label`)}
            name="email"
            type="email"
          />
        </div>
        <CardActions>
          <div className={style.buttonsContainer}>
            <Button
              fill="outline"
              disabled={loading}
              onClick={handleClose}
              type="button"
            >
              {t(`${tPrefix}.button_back`)}
            </Button>
            <Spacing size="small" />
            <Button
              loading={loading}
              disabled={loading}
              displayChildrenWhenLoading
              type="submit"
            >
              {t(`${tPrefix}.button_submit`)}
            </Button>
          </div>
        </CardActions>
      </Form>
    </>
  )

  const getSuccessContent = () => (
    <Flexbox alignItems="center" direction="column">
      <BenchGuySvg />
      <span className={style.successMessage}>
        {t(`${tPrefix}.success_message`)}
      </span>
      <ModalActions>
        <Flexbox className={style.paymentLinkActions} justifyContent="center">
          <Button onClick={handleClose} size="huge">
            {t(`${tPrefix}.button_success`)}
          </Button>
        </Flexbox>
      </ModalActions>
    </Flexbox>
  )

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle
        title={t(`${tPrefix}.title`)}
        closeIcon={<IconClose width={16} height={16} data-testid="close-icon" />}
        onClose={handleClose}
      />
      <ModalContent>
        {showSuccessContent ? getSuccessContent() : getFormContent()}
      </ModalContent>
    </Modal>
  )
}

ResendBoletoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
}

export default enhanced(ResendBoletoModal)
