import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'former-kit'
import IconInfo from 'emblematic-icons/svg/InfoCircle32.svg'
import IconCloseCircle from 'emblematic-icons/svg/CloseCircle32.svg'

const renderIcon = info => (info
  ? (<IconInfo height={16} width={16} />)
  : (<IconCloseCircle height={16} width={16} />))

const AlertInfo = ({
  action,
  alertLabels,
  boletoWarningMessage,
  isBoletoWaitingPayment,
}) => (
  <Alert
    action={action}
    icon={renderIcon(isBoletoWaitingPayment)}
    type={isBoletoWaitingPayment ? 'info' : 'error'}
  >
    {isBoletoWaitingPayment ? (
      <span>{ boletoWarningMessage }</span>
    ) : (
      <span>
        <strong> {alertLabels.chargeback_reason_label} </strong>
        {alertLabels.chargeback_reason}
      </span>
    )}
  </Alert>
)

AlertInfo.propTypes = {
  action: PropTypes.string.isRequired,
  alertLabels: PropTypes.shape({
    chargeback_reason: PropTypes.string,
    chargeback_reason_label: PropTypes.string,
    reason_code: PropTypes.string,
  }).isRequired,
  boletoWarningMessage: PropTypes.string.isRequired,
  isBoletoWaitingPayment: PropTypes.bool.isRequired,
}

export default AlertInfo
