import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import OnboardingPage from 'pilot/pages/Onboarding/Onboarding'
import getResponseError from 'pilot/formatters/apiError'

import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

import { connect } from 'react-redux'
import {
  compose, isEmpty, isNil, not,
} from 'ramda'

import { requestLogin as requestLoginAction } from 'pilot/pages/Account/actions/actions'
import environment from 'pilot/environment'

import Loader from 'pilot/components/Loader'

const selectApiErrors = (errors) => {
  const isCreditApiError = ({ status, url }) => /^\/credit/.test(url) && status === 403
  return errors.filter(error => not(isCreditApiError(error)))
}

const mapStateToProps = ({
  account: {
    client,
    company,
    error,
    user,
  },
  errors,
  welcome,
}) => ({
  client,
  company,
  errors: selectApiErrors(errors),
  hasError: !isNil(error) || !isEmpty(selectApiErrors(errors)),
  loadingOnboardingAnswers: welcome.loading,
  loginError: error,
  user,
})

const mapDispatchToProps = {
  requestLogin: requestLoginAction,
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
)

const Root = ({
  accountId,
  client,
  company,
  errors,
  hasError,
  jwt,
  loadingOnboardingAnswers,
  loginError,
  merchantId,
  onError,
  onSubmit,
  requestLogin,
  user,
}) => {
  useEffect(() => {
    const isClientAuthenticated = accountId && jwt && merchantId
    && (client?.authentication?.account_id === accountId
      || client?.authentication?.merchant_id === merchantId
      || client?.authentication?.jwt === jwt
    )

    if (!isClientAuthenticated) {
      requestLogin({
        account_id: accountId,
        environment,
        jwt,
        merchant_id: merchantId,
      })
    }
  }, [accountId, client, jwt, merchantId, requestLogin])

  useEffect(() => {
    if (loginError) {
      const errorPayload = [getResponseError(loginError)]
      onError(errorPayload)
    }

    if (!isEmpty(errors)) {
      onError(errors)
    }
  }, [loginError, errors, onError])

  if (hasError) {
    return null
  }

  if (!client || !user || !company || loadingOnboardingAnswers) {
    return (
      <Loader
        opaqueBackground
        position="absolute"
        visible
      />
    )
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/"
          render={routeProps => (
            <OnboardingPage
              {...routeProps}
              onSubmitOnboarding={onSubmit}
            />
          )
          }
        />
      </Switch>
    </Router>
  )
}

Root.propTypes = {
  accountId: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  client: PropTypes.object, // eslint-disable-line
  company: PropTypes.object, // eslint-disable-line
  errors: PropTypes.arrayOf(Error),
  jwt: PropTypes.string.isRequired,
  loadingOnboardingAnswers: PropTypes.bool.isRequired,
  loginError: PropTypes.instanceOf(Error),
  merchantId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requestLogin: PropTypes.func.isRequired,
  user: PropTypes.object, // eslint-disable-line
}

Root.defaultProps = {
  client: null,
  company: null,
  errors: [],
  loginError: null,
  user: null,
}

export default enhance(Root)
