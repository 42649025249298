import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Col,
  Grid,
  Row,
} from 'former-kit'
import SummaryCard from '../../../../components/SummaryCard'
import formatCurrency from '../../../../formatters/currency'
import SummaryCredtCardInstallments from '../SummaryCreditCardInstallments'

const tPrefix = 'pages.payment_links.add_link.summary_step'

const buildCreditCardRow = (t, {
  active,
  ...creditCardConfig
}) => (active
  ? {
    label: t(`${tPrefix}.credit_card_label`),
    value: <SummaryCredtCardInstallments
      creditCardConfig={creditCardConfig}
      t={t}
    />,
  }
  : null
)

const buildBoletoRow = (t, {
  active,
  boleto_expires_in: boletoExpiresIn,
}) => (active
  ? {
    label: t(`${tPrefix}.boleto_label`),
    value: t(`${tPrefix}.boleto_value`, { days: boletoExpiresIn }),
  }
  : null
)

const buildPixRow = (t, { active }) => (active
  ? {
    label: t(`${tPrefix}.pix_label`),
    value: t(`${tPrefix}.pix_value`),
  }
  : null
)

const buildItemsRow = items => items.map(({
  quantity,
  title,
  unit_price: unitPrice,
}) => ({
  label: `${title} (x${quantity})`,
  type: 'item',
  value: formatCurrency(quantity * unitPrice),
}))

const buildPaymentsCol = (t, boleto, creditCard, pix) => [
  buildCreditCardRow(t, creditCard),
  buildBoletoRow(t, boleto),
  buildPixRow(t, pix),
]

const buildItemsCol = (t, total, items) => [
  ...buildItemsRow(items),
  {
    label: t(`${tPrefix}.total`),
    type: 'total',
    value: formatCurrency(total),
  },
]

const buildDetailsCol = (
  t,
  name,
  expiresDate,
  showTotal = false,
  total = null
) => {
  const col = [
    { label: t(`${tPrefix}.link_name`), value: name },
  ]

  if (showTotal) {
    col.push({
      label: t(`${tPrefix}.link_value`),
      value: formatCurrency(total),
    })
  }

  return [
    ...col,
    {
      label: t(`${tPrefix}.time_expiration_label`),
      value: !isEmpty(expiresDate)
        ? t(`${tPrefix}.time_expiration_value`, { date: expiresDate })
        : '-',
    },
  ]
}

const PaymentLinkCreateSummary = ({
  detailsCol,
  itemsCol,
  loading,
  onBack,
  onSubmit,
  paymentsCol,
  t,
}) => {
  const { expiresDate, name } = detailsCol
  const { items, total } = itemsCol
  const { boleto, credit_card: creditCard, pix } = paymentsCol

  const columnSize = items.length > 0 ? 4 : 6

  return (
    <Card>
      <CardContent>
        <h2>{t(`${tPrefix}.title`)}</h2>
        <Grid>
          <Row>
            <Col palm={12} tablet={6} desk={columnSize} tv={columnSize}>
              <SummaryCard
                title={t(`${tPrefix}.title_details`)}
                rows={
                  buildDetailsCol(t, name, expiresDate, !items.length, total)
                }
              />
            </Col>
            { items.length > 0 && (
              <Col palm={12} tablet={6} desk={4} tv={4}>
                <SummaryCard
                  title={t(`${tPrefix}.title_items`)}
                  rows={buildItemsCol(t, total, items)}
                />
              </Col>
            )}
            <Col palm={12} tablet={6} desk={columnSize} tv={columnSize}>
              <SummaryCard
                title={t(`${tPrefix}.title_payments`)}
                rows={buildPaymentsCol(t, boleto, creditCard, pix)}
              />
            </Col>
          </Row>
        </Grid>
      </CardContent>
      <CardActions>
        <Button onClick={onBack} disabled={loading} fill="outline">
          {t(`${tPrefix}.button_back`)}
        </Button>
        <Button onClick={onSubmit} data-testid="conclusion" loading={loading} disabled={loading}>
          {t(`${tPrefix}.button_submit`)}
        </Button>
      </CardActions>
    </Card>
  )
}

PaymentLinkCreateSummary.propTypes = {
  detailsCol: PropTypes.shape({
    expiresDate: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  itemsCol: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.oneOfType([
          PropTypes.string, PropTypes.number,
        ]).isRequired,
        title: PropTypes.string.isRequired,
        unit_price: PropTypes.number.isRequired,
      })
    ).isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  paymentsCol: PropTypes.shape({
    boleto: PropTypes.shape({
      active: PropTypes.bool,
      boleto_expires_in: PropTypes.string,
    }).isRequired,
    credit_card: PropTypes.shape({
      active: PropTypes.bool,
      fee_payer: PropTypes.string,
      free_installments: PropTypes.number,
      max_installments: PropTypes.number,
    }).isRequired,
    pix: PropTypes.shape({
      active: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

PaymentLinkCreateSummary.defaultProps = {
  loading: false,
}

export default PaymentLinkCreateSummary
