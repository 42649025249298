import React from 'react'
import {
  cond,
  gt,
  identity,
  ifElse,
  juxt,
  lt,
  nth,
  pipe,
  prop,
  T,
  __,
} from 'ramda'
import styles from './styles.css'
import currencyFormatter from '../../formatters/currency'
import dateFormatter from '../../formatters/longDate'
import InstallmentStatusTypes from '../../models/installmentStatus'
import InstallmentStatus from './InstallmentStatus'

const colorNumber = className => formattedNumber => (
  <span className={styles[className]}>
    {formattedNumber}
  </span>
)

const addSign = sign => formattedNumber => `${sign}${formattedNumber}`

const formatNumber = (accessor, color) => pipe(
  prop(accessor),
  juxt([currencyFormatter, identity]),
  ifElse(
    pipe(nth(1), gt(__, 0)),
    pipe(nth(0), addSign('+'), colorNumber(color)),
    pipe(nth(0), colorNumber(color))
  )
)

const formatFees = pipe(
  prop('fees'),
  juxt([currencyFormatter, identity]),
  cond([
    [
      pipe(nth(1), gt(__, 0)),
      pipe(nth(0), addSign('+'), colorNumber('green')),
    ],
    [
      pipe(nth(1), lt(__, 0)),
      pipe(nth(0), colorNumber('red')),
    ],
    [
      T,
      pipe(
        nth(0),
        colorNumber('red')
      ),
    ],
  ])
)

const formatOutgoing = pipe(
  prop('outgoing_amount'),
  currencyFormatter,
  colorNumber('red')
)

const renderStatus = t => item => (InstallmentStatusTypes[item.status]
  ? <InstallmentStatus status={item.status} t={t} />
  : item.status)

const renderOriginalPaymentDate = originalPaymentDate => (
  <div>
    <span className={styles.scratched}>
      {originalPaymentDate && dateFormatter(originalPaymentDate)}
    </span>
  </div>
)

const renderPaymentDate = paymentDate => (
  <div>{dateFormatter(paymentDate)}</div>
)

const renderPaymentDates = item => (
  <>
    {item.original_payment_date
      && (renderOriginalPaymentDate(item.original_payment_date))
    }
    {item.payment_date && (renderPaymentDate(item.payment_date))}
  </>
)
const getDefaultColumns = ({ t }) => ([
  {
    accessor: ['number'],
    align: 'center',
    orderable: false,
    title: t('models.installment.number'),
  },
  {
    accessor: ['status'],
    align: 'center',
    orderable: false,
    renderer: renderStatus(t),
    title: t('models.installment.status'),
  },
  {
    accessor: ['payment_date'],
    align: 'center',
    orderable: false,
    renderer: renderPaymentDates,
    title: t('models.installment.date'),
  },
  {
    accessor: ['amount'],
    align: 'center',
    orderable: false,
    renderer: formatNumber('amount', 'black'),
    title: t('models.installment.amount'),
  },
  {
    accessor: ['outcoming_amount'],
    align: 'center',
    orderable: false,
    renderer: formatNumber('outcoming_amount', 'green', true),
    title: t('models.installment.outcoming_amount'),
  },
  {
    accessor: ['outgoing_amount'],
    align: 'center',
    orderable: false,
    renderer: formatOutgoing,
    title: t('models.installment.outgoing_amount'),
  },
  {
    accessor: ['fees'],
    align: 'center',
    orderable: false,
    renderer: formatFees,
    title: t('models.installment.fees'),
  },
  {
    accessor: ['net_amount'],
    align: 'center',
    orderable: false,
    renderer: formatNumber('net_amount', 'blue'),
    title: t('models.installment.net_amount'),
  },
])

export default getDefaultColumns
