import React, { useCallback, useState } from 'react';
import { Popover, PopoverContent, Button } from 'former-kit';
import { SidebarOptions } from '../SidebarOptions/SidebarOptions';
import { dateSelectorInputStyle, dateSelectorSidebarStyle, dateSelectorStyle } from './DateSelector.style';
import { Calendar } from '../Calendar/Calendar';
import { InputDate } from '../InputDate/InputDate';
import { formatDateLabel, formatDateToString, isDateRangeError, onChangeFullDate } from './helpers/dateFormaters';
import { mountDateCloseOptions } from './helpers/mountDateCloseOptions';
export const DateSelector = _ref => {
  let {
    translate,
    buttonLabels,
    optionsLabels,
    start,
    setStart,
    end,
    setEnd,
    maxDate,
    minDate
  } = _ref;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [inputStartValue, setInputStartValue] = useState(formatDateToString(start));
  const [inputEndValue, setInputEndValue] = useState(formatDateToString(end));
  const handleChangeDate = useCallback((dateStart, dateEnd) => {
    setStart(dateStart);
    setEnd(dateEnd);
    setInputStartValue(formatDateToString(dateStart));
    setInputEndValue(formatDateToString(dateEnd));
  }, [setStart, setEnd]);
  const handleSetDateStartInput = useCallback(e => {
    setInputStartValue(e.target.value);
    onChangeFullDate(e.target.value, setStart);
  }, [onChangeFullDate]);
  const handleSetDateEndInput = useCallback(e => {
    setInputEndValue(e.target.value);
    onChangeFullDate(e.target.value, setEnd);
  }, [onChangeFullDate]);
  const options = mountDateCloseOptions(handleChangeDate, setSelectedIndex, translate, optionsLabels);
  const handleClickCalendar = useCallback(_ref2 => {
    let [dateStart, dateEnd] = _ref2;
    handleChangeDate(dateStart, dateEnd);
    setSelectedIndex(options.length);
  }, [handleChangeDate, setSelectedIndex, options]);
  const buttonLabel = start && end ? translate(buttonLabels === null || buttonLabels === void 0 ? void 0 : buttonLabels.full, {
    start: formatDateLabel(start),
    end: formatDateLabel(end)
  }) : translate(buttonLabels === null || buttonLabels === void 0 ? void 0 : buttonLabels.empty);
  return React.createElement("div", {
    className: dateSelectorStyle(),
    "data-testid": "shared-components-date-selector"
  }, React.createElement(Popover, {
    arrow: false,
    onClick: () => {},
    onClickOutside: () => setVisible(false),
    visible: visible,
    content: React.createElement(PopoverContent, null, React.createElement("div", {
      className: 'date-selector-content'
    }, React.createElement("div", {
      className: 'date-selector-sidebar'
    }, React.createElement(SidebarOptions, {
      classes: {
        list: dateSelectorSidebarStyle()
      },
      options: options,
      translate: translate,
      selected: selectedIndex
    })), React.createElement("div", {
      className: 'date-selector-dates'
    }, React.createElement("div", {
      className: 'date-selector-calendar'
    }, React.createElement(Calendar, {
      startDate: start,
      endDate: end,
      onChangeDate: handleClickCalendar,
      maxDate: maxDate,
      minDate: minDate
    })), React.createElement("div", {
      className: 'date-selector-forms'
    }, React.createElement("div", {
      className: 'date-selector-input'
    }, React.createElement(InputDate, {
      classes: {
        formBox: dateSelectorInputStyle()
      },
      value: inputStartValue,
      onChange: handleSetDateStartInput,
      status: isDateRangeError(start, [null, end])
    })), React.createElement("span", {
      className: 'date-selector-dash'
    }), React.createElement("div", {
      className: 'date-selector-input'
    }, React.createElement(InputDate, {
      classes: {
        formBox: dateSelectorInputStyle()
      },
      value: inputEndValue,
      onChange: handleSetDateEndInput,
      status: isDateRangeError(end, [start])
    }))))))
  }, React.createElement(Button, {
    onClick: () => setVisible(!visible)
  }, buttonLabel)));
};