export const getTranslation = (key, t) => t(`pages.transactions.export.${key}`)

export const getDefaultFields = t => [
  { key: 'status', value: getTranslation('status', t) },
  { key: 'id', value: getTranslation('id', t) },
  { key: 'created_at', value: getTranslation('created_at', t) },
  { key: 'name', value: getTranslation('name', t) },
  { key: 'payment_method', value: getTranslation('payment_method', t) },
  { key: 'card_number', value: getTranslation('card_number', t) },
  { key: 'documents', value: getTranslation('documents', t) },
  { key: 'email', value: getTranslation('email', t) },
  { key: 'subscription', value: getTranslation('subscription', t) },
  { key: 'phones', value: getTranslation('phones', t) },
  { key: 'acquirer_name', value: getTranslation('acquirer_name', t) },
  { key: 'acquirer_response_code', value: getTranslation('acquirer_response_code', t) },
  { key: 'ip', value: getTranslation('ip', t) },
  { key: 'brand_name', value: getTranslation('brand_name', t) },
  { key: 'amount', value: getTranslation('amount', t) },
  { key: 'paid_amount', value: getTranslation('paid_amount', t) },
  { key: 'refunded_amount', value: getTranslation('refunded_amount', t) },
  { key: 'split_rules', value: getTranslation('split_rules', t) },
  { key: 'street', value: getTranslation('street', t) },
  { key: 'streetNumber', value: getTranslation('streetNumber', t) },
  { key: 'complementary', value: getTranslation('complementary', t) },
  { key: 'neighborhood', value: getTranslation('neighborhood', t) },
  { key: 'zipcode', value: getTranslation('zipcode', t) },
  { key: 'city', value: getTranslation('city', t) },
  { key: 'state', value: getTranslation('state', t) },
  { key: 'antifraud', value: getTranslation('antifraud', t) },
]

export const getMetadataInColumns = (t, showColumn) => (showColumn
  ? { key: 'metadata_in_columns', value: getTranslation('metadata_in_columns', t) }
  : { key: undefined, value: null }
)

export const getAllFields = (t, showMetadataInColumns) => [
  ...getDefaultFields(t),
  { key: 'metadata', value: getTranslation('metadata', t) },
  getMetadataInColumns(t, showMetadataInColumns),
  { key: 'pix', value: getTranslation('pix_id', t) },
  { key: 'updated_at', value: getTranslation('updated_at', t) },
  { key: 'cost', value: getTranslation('cost', t) },
  { key: 'installments', value: getTranslation('installments', t) },
  { key: 'card_first_digits', value: getTranslation('card_first_digits', t) },
  { key: 'card_last_digits', value: getTranslation('card_last_digits', t) },
  { key: 'boleto_expiration_date', value: getTranslation('boleto_expiration_date', t) },
  { key: 'boleto_url', value: getTranslation('boleto_url', t) },
  { key: 'tid', value: getTranslation('tid', t) },
  { key: 'authorization_code', value: getTranslation('authorization_code', t) },
  { key: 'nsu', value: getTranslation('nsu', t) },
  { key: 'items', value: getTranslation('items', t) },
  { key: 'card_holder_name', value: getTranslation('card_holder_name', t) },
  { key: 'card_funding_source', value: getTranslation('card_funding_source', t) },
]

