import XLSX from 'xlsx'

const handleCSVExportDownloadingClick = (data, filename) => {
  /* eslint-disable no-undef */
  const downloadLink = document.createElement('a')
  downloadLink.target = '_blank'
  downloadLink.download = filename.concat('csv')

  const blob = new Blob([data], { type: 'application/vnd.ms-excel' })

  const URL = window.URL || window.webkitURL
  const downloadUrl = URL.createObjectURL(blob)

  downloadLink.href = downloadUrl

  document.body.append(downloadLink)

  downloadLink.click()

  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(downloadUrl)
  /* eslint-enable no-undef */
}

const parseNumber = (value) => {
  const cleanPattern = new RegExp('[^-+0-9,]', 'g')
  const cleaned = value.replace(cleanPattern, '')
  const normalized = cleaned.replace(',', '.')

  return parseFloat(normalized)
}

const handleXLSExportDownloadingClick = (data,
  filename,
  columnsTypeNumberPosition) => {
  const workSheetName = 'sheetJS'
  const newWorkSheet = XLSX.utils.book_new()
  const dataWorkSheet = XLSX.utils.aoa_to_sheet(data)

  if (columnsTypeNumberPosition) {
    const range = XLSX.utils.decode_range(dataWorkSheet['!ref'])

    // eslint-disable-next-line no-plusplus
    for (let R = range.s.r; R <= range.e.r; ++R) {
    // eslint-disable-next-line no-plusplus
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R }
        const cellRef = XLSX.utils.encode_cell(cellAddress)

        if (columnsTypeNumberPosition.includes(cellAddress.c)
       && cellAddress.r > 0) {
          const newValue = parseNumber(dataWorkSheet[cellRef].v)
          dataWorkSheet[cellRef].v = newValue
          dataWorkSheet[cellRef].z = '#,##0.00'
          dataWorkSheet[cellRef].t = 'n'
        }
      }
    }
  }

  XLSX.utils.book_append_sheet(newWorkSheet, dataWorkSheet, workSheetName)

  XLSX.writeFile(newWorkSheet, filename.concat('xlsx'))
}

const exportCSVorXLS = (exportType, res, filename, columnsTypeNumber) => {
  if (exportType === 'csv') {
    handleCSVExportDownloadingClick(res, filename)
    return
  }

  handleXLSExportDownloadingClick(res, filename, columnsTypeNumber)
}

export default exportCSVorXLS
