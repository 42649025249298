import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Form from 'react-vanilla-form'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  FormDropdown,
  FormInput,
} from 'former-kit'
import IconAdd from 'emblematic-icons/svg/Add24.svg'
import IconClose from 'emblematic-icons/svg/ClearClose24.svg'
import IconCloseCircle from 'emblematic-icons/svg/CloseCircle24.svg'
import CurrencyInput from '../../../../components/CurrencyInput'

import isRequired from '../../../../validation/required'
import isNumber from '../../../../validation/number'
import lessThan from '../../../../validation/lessThan'
import formatCurrency from '../../../../formatters/currency'

import styles from './style.css'

const initItem = {
  quantity: 1,
  unit_price: 0,
}

const tPrefix = 'pages.transactions.create.items'

const tangiblesOptions = t => [
  {
    name: t(`${tPrefix}.tangible_options.true`),
    value: 'true',
  },
  {
    name: t(`${tPrefix}.tangible_options.false`),
    value: 'false',
  },
]

const convertItemsValuesToPrimitive = items => (
  items.map(item => ({
    ...item,
    quantity: Number(item.quantity),
    tangible: JSON.parse(item.tangible),
    unit_price: Number(item.unit_price),
  }))
)

const buildFormItemsToArray = (formItems) => {
  const result = []
  Object.entries(formItems).forEach(([formItemProp]) => {
    const [key, i] = formItemProp.split('-')
    result[i] = { ...result[i], [key]: formItems[formItemProp] }
  })

  return result
}

const buildFormDefaultValues = items => (
  items.reduce((prevItem, currentItem, i) => ({
    ...prevItem,
    [`id-${i}`]: items[i].id || '',
    [`title-${i}`]: items[i].title || '',
    [`tangible-${i}`]: items[i].tangible?.toString() || 'true',
    [`unit_price-${i}`]: items[i].unit_price?.toString() || '0',
    [`quantity-${i}`]: items[i].quantity?.toString() || '1',
  }), {})
)

const buildValidations = (items, t) => (
  items.reduce((prevItem, currentItem, i) => ({
    ...prevItem,
    [`id-${i}`]: isRequired(t(`${tPrefix}.validations.id.required`)),
    [`title-${i}`]: isRequired(t(`${tPrefix}.validations.name.required`)),
    [`unit_price-${i}`]: [
      isRequired(t(`${tPrefix}.validations.unit_price.required`)),
      isNumber(t(`${tPrefix}.validations.unit_price.number`)),
    ],
    [`quantity-${i}`]: [
      isRequired(t(`${tPrefix}.validations.quantity.required`)),
      isNumber(t(`${tPrefix}.validations.quantity.number`)),
      lessThan(1, t(`${tPrefix}.validations.quantity.min`, { min: 1 })),
    ],
  }), {})
)

const calcTotal = item => (Number(item.unit_price) || 0) * Number(item.quantity)

const calcItemUnitPrice = item => formatCurrency(calcTotal(item))

const calcAmount = items => (
  items.reduce((prevItem, currentItem) => (
    prevItem + calcTotal(currentItem)), 0)
)

const hasEmptyValues = (items) => {
  const findValues = Object.entries(items).flat()
  return findValues.includes('') || !findValues.length
}

const TransactionItemForm = ({
  items: propItems,
  onSubmit,
  t,
}) => {
  const [items, setItems] = useState(
    propItems.length > 0
      ? propItems
      : [initItem]
  )
  const [hasInvalidAmount, setHasInvalidAmount] = useState(false)
  const [isInvalidForm, setInvalidForm] = useState(hasEmptyValues(propItems))
  const amount = calcAmount(items)
  const hasMoreThanTwoItems = items.length > 1

  const onChangeForm = (formItems) => {
    setInvalidForm(hasEmptyValues(formItems))
    setItems(buildFormItemsToArray(formItems))
  }

  const onSubmitForm = (formItems, errors) => {
    setHasInvalidAmount(false)

    if (amount < 100) {
      setHasInvalidAmount(true)
      return
    }

    if (!errors) {
      onSubmit({
        amount,
        items: convertItemsValuesToPrimitive(items),
      })
    }
  }

  const onAddItem = () => {
    setItems([...items, initItem])
  }

  const onRemoveItem = (i) => {
    setItems(items.filter((item, index) => i !== index))
  }

  return (
    <Card>
      <header className={styles.header}>
        <h2>{t(`${tPrefix}.title`)}</h2>
        <Button
          fill="outline"
          icon={<IconAdd width={12} height={12} />}
          disabled={items.length >= 10}
          onClick={onAddItem}
        >
          {t(`${tPrefix}.add`)}
        </Button>
      </header>
      {hasInvalidAmount && (
        <div className={styles.alert}>
          <Alert
            icon={<IconCloseCircle height={16} width={16} />}
            type="error"
          >
            <p>{t(`${tPrefix}.amount_min`)}</p>
          </Alert>
        </div>
      )}
      <Form
        data={buildFormDefaultValues(items)}
        validateOn="blur"
        validation={buildValidations(items, t)}
        onSubmit={onSubmitForm}
        onChange={onChangeForm}
      >
        <CardContent>
          <div className={styles.items}>
            {items.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={styles.item}>
                <div className={styles.form}>
                  <FormInput
                    type="text"
                    name={`id-${i}`}
                    label={t(`${tPrefix}.id`)}
                    data-testid={`id-${i}`}
                  />
                  <FormInput
                    type="text"
                    name={`title-${i}`}
                    label={t(`${tPrefix}.name`)}
                    data-testid={`title-${i}`}
                  />
                  <FormDropdown
                    options={tangiblesOptions(t)}
                    label={t(`${tPrefix}.tangible`)}
                    name={`tangible-${i}`}
                  />
                  <FormInput
                    type="number"
                    min="1"
                    name={`quantity-${i}`}
                    label={t(`${tPrefix}.quantity`)}
                    data-testid={`quantity-${i}`}
                  />
                  <FormInput
                    type="text"
                    name={`unit_price-${i}`}
                    label={t(`${tPrefix}.unit_price`)}
                    data-testid={`unit_price-${i}`}
                    renderer={props => (
                      <CurrencyInput
                        {...props}
                      />
                    )}
                  />
                </div>
                <div className={hasMoreThanTwoItems
                  ? styles.subactions
                  : classNames(styles.subactions, styles.withoutRemoveItem)}
                >
                  { hasMoreThanTwoItems && (
                    <Button
                      relevance="high"
                      fill="outline"
                      icon={<IconClose width={12} height={12} />}
                      onClick={() => onRemoveItem(i)}
                    >
                      {t(`${tPrefix}.remove`)}
                    </Button>
                  )}
                  <p className={styles.subprice}>
                    {t(`${tPrefix}.subtotal`)} {calcItemUnitPrice(item)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.amount}>
            {t(`${tPrefix}.amount`)}
            {' '}
            {formatCurrency(amount)}
          </div>
        </CardContent>
        <CardActions>
          <Button type="submit" data-testid="button-advance" disabled={isInvalidForm}>{t(`${tPrefix}.submit`)}</Button>
        </CardActions>
      </Form>
    </Card>
  )
}

TransactionItemForm.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    tangible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    unit_price: PropTypes.number.isRequired,
  })),
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

TransactionItemForm.defaultProps = {
  items: [],
}

export default TransactionItemForm
