import React from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  Row,
  Tag,
  Grid,
} from 'former-kit'
import { Link } from 'react-router-dom'
import style from './style.css'
import formatCurrency from '../../../formatters/currency'

function RecipientHeader ({
  amount,
  chargeProcessingFee,
  id,
  liable,
  name,
  netAmount,
  percentage,
  t,
}) {
  const formartPercent = (value) => {
    const formatter = new Intl.NumberFormat(
      'pt-BR',
      {
        maximumFractionDigits: 2,
        style: 'percent',
      }
    )

    return formatter.format(Number(value) / 100)
  }

  return (
    <Grid>
      <Col
        palm={12}
        tablet={12}
        desk={12}
        tv={12}
        className={style.itemContainer}
      >
        <Row className={style.content}>
          <Col
            palm={6}
            tablet={6}
            desk={6}
            tv={6}
          >
            <div className={style.marginBottom}>
              {t('pages.transaction.recipients.recipient')}
              <Link to={`/recipients/detail/${id}`}>
                {name}
              </Link>
            </div>
            <Row>
              {liable
                && (
                  <div className={style.contentTag}>
                    <Tag>
                      {t('pages.transaction.recipients.responsability_chargeback')}
                    </Tag>
                  </div>
                )
              }
              {chargeProcessingFee
                && (
                  <div className={style.contentTag}>
                    <Tag>
                      {t('pages.transaction.recipients.responsability_MDR')}
                    </Tag>
                  </div>
                )
              }
            </Row>
          </Col>
          <Col
            palm={6}
            tablet={6}
            desk={6}
            tv={6}
            className={style.headerAlign}
          >
            <div>
              <div className={style.totalLiquid}>
                {t('pages.transaction.recipients.nets_amount')}{formatCurrency(amount)}
              </div>
              {percentage !== null
                ? (
                  <div>
                    {t('pages.transaction.recipients.rule_split')} <span className={style.fontBold}>{formartPercent(percentage)}</span>
                  </div>
                )
                : (
                  <div>
                    {t('pages.transaction.recipients.rule_split')} <span className={style.fontBold}>{formatCurrency(netAmount)}</span>
                  </div>
                )
              }
            </div>
          </Col>
        </Row>
      </Col>
    </Grid>
  )
}

RecipientHeader.propTypes = {
  amount: PropTypes.number,
  chargeProcessingFee: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  liable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  netAmount: PropTypes.number,
  percentage: PropTypes.number,
  t: PropTypes.func.isRequired,
}

RecipientHeader.defaultProps = {
  amount: 0,
  netAmount: null,
  percentage: 0,
}

export default RecipientHeader
