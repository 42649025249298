import React from 'react'
import PropTypes from 'prop-types'
import DetailsCard from '../DetailsCard'

const ShippingDetailsCard = ({ data, labels, title }) => {
  const order = [
    'recipient',
    'fee',
    'delivery_date',
    'expedited',
    'street',
    'number',
    'complementary',
    'neighborhood',
    'city',
    'state',
    'country',
    'zipcode',
  ]
  return (
    <DetailsCard data={data} labels={labels} title={title} order={order} />
  )
}

const shape = {
  city: PropTypes.string,
  complementary: PropTypes.string,
  country: PropTypes.string,
  delivery_date: PropTypes.string,
  expedited: PropTypes.string,
  fee: PropTypes.string,
  neighborhood: PropTypes.string,
  number: PropTypes.string,
  recipient: PropTypes.string,
  state: PropTypes.string,
  street: PropTypes.string,
  zipcode: PropTypes.string,
}

ShippingDetailsCard.propTypes = {
  data: PropTypes.shape(shape).isRequired,
  labels: PropTypes.shape({ ...shape, title: PropTypes.string }).isRequired,
  title: PropTypes.string.isRequired,
}

export default ShippingDetailsCard
