import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import IconChecked from 'emblematic-icons/svg/CheckCircle32.svg'
import IconError from 'emblematic-icons/svg/CloseCircle32.svg'

import {
  always,
  cond,
  equals,
} from 'ramda'
import {
  Alert,
  Button,
  ModalActions,
  ModalContent,
} from 'former-kit'

import PixRefundDetails from '../../../components/PixRefundDetails'

const getIcon = cond([
  [equals('success'), always(<IconChecked height={16} width={16} />)],
  [equals('error'), always(<IconError height={16} width={16} />)],
])

const PixResult = ({
  amount,
  onTryAgain,
  onViewTransaction,
  recipient,
  refundAmount,
  status,
  statusMessage,
  t,
}) => {
  const labels = {
    amount: t('models.transaction.amount'),
    recipient: t('pages.refund.chosen_recipient'),
    refundAmount: t('pages.refund.refund_amount'),
  }

  const contents = {
    amount,
    recipient,
    refundAmount,
  }
  return (
    <Fragment>
      <ModalContent>
        <Alert
          icon={getIcon(status)}
          type={status}
        >
          <span>{statusMessage}</span>
        </Alert>
      </ModalContent>
      {status === 'success'
        && (
          <PixRefundDetails
            contents={contents}
            labels={labels}
            title={t('pages.refund.refund_data')}
            recipient={recipient}
          />
        )
      }
      <ModalActions>
        {status === 'success'
          ? (
            <Button
              fill="outline"
              onClick={onViewTransaction}
            >
              {t('pages.refund.view_transaction')}
            </Button>
          )
          : (
            <Button
              fill="outline"
              onClick={onTryAgain}
            >
              {t('pages.refund.try_again')}
            </Button>
          )
        }
      </ModalActions>
    </Fragment>
  )
}

PixResult.propTypes = {
  amount: PropTypes.number.isRequired,
  onTryAgain: PropTypes.func.isRequired,
  onViewTransaction: PropTypes.func.isRequired,
  recipient: PropTypes.string,
  refundAmount: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    'error',
    'success',
  ]).isRequired,
  statusMessage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

PixResult.defaultProps = {
  recipient: null,
}

export default PixResult
