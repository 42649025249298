import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardContent,
  CardTitle,
  Grid,
  Row,
  Col,
} from 'former-kit'

import { splitEvery } from 'ramda'

import Property from '../Property'
import style from './style.css'

const TransactionPayerDetails = ({ payerDetails, t }) => {
  const detailMatrix = splitEvery(3, payerDetails)

  return (
    <Card>
      <CardTitle title={t('pages.transaction.payer.title')} />
      <CardContent>
        <Grid>
          {detailMatrix.map(payerDetailsRow => (
            <Row
              className={style.propertyCustom}
              key={`transaction-payer-row-${payerDetailsRow[0].key}`}
            >
              {payerDetailsRow.map(payerDetailCol => (
                <Col
                  key={payerDetailCol.key}
                  palm={12}
                  tablet={6}
                  desk={4}
                  tv={4}
                  className={style.propertyCustom}
                >
                  <Property
                    title={t(`pages.transaction.payer.${payerDetailCol.key}`)}
                    value={payerDetailCol.value}
                  />
                </Col>
              ))}
            </Row>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}

const shape = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

TransactionPayerDetails.propTypes = {
  payerDetails: PropTypes.arrayOf(
    PropTypes.shape(shape).isRequired
  ).isRequired,
  t: PropTypes.func.isRequired,
}

export default TransactionPayerDetails
