import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
} from 'former-kit'

import installmentStatus from '../../models/installmentStatus'

const TransactionRecipientInstallments = ({
  columns,
  rows,
}) => (
  <Table
    columns={columns}
    rows={rows}
    maxColumns={8}
  />
)

TransactionRecipientInstallments.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.arrayOf(PropTypes.string),
    orderable: PropTypes.bool,
    title: PropTypes.string,
  })).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      fees: PropTypes.number,
      net_amount: PropTypes.number,
      number: PropTypes.number,
      original_payment_date: PropTypes.string,
      outcoming_amount: PropTypes.number,
      outgoing_amount: PropTypes.number,
      payment_date: PropTypes.string,
      status: PropTypes.oneOf(Object.keys(installmentStatus)),
    })
  ).isRequired,
}

export default TransactionRecipientInstallments
