const handleExportDataSuccess = (url) => {
  const downloadLink = document.createElement('a')
  downloadLink.target = '_blank'
  downloadLink.download = url
  downloadLink.href = url
  document.body.append(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export default handleExportDataSuccess
