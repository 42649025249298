export default {
  items: [
    {
      label: 'Pré-pago',
      value: 'Prepaid',
    },
    {
      label: 'Convencional',
      value: ['Credit', 'Debit'],
    },
  ],
  key: 'card_funding_sources',
  name: 'pages.transactions.filters.card_funding_sources',
  type: 'checkbox',
}
