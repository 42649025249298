import plans from './plans';
import transactions from './transactions';
import keys from './keys';
import paymentLinks from './paymentLinks';
import kyc from './kyc';
import subscriptions from './subscriptions';
export const TWO_YEARS_IN_WEEKS = 104;
export function removeUTCMinusThree(iso8601DateString) {
  let isEndOfDay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (isEndOfDay) {
    return iso8601DateString.replace("-03:00", ".999Z");
  }
  return iso8601DateString.replace("-03:00", ".000Z");
}
export default ((axiosConfig, options) => ({
  plans: plans(axiosConfig, options),
  transactions: transactions(axiosConfig, options),
  keys: keys(axiosConfig, options),
  paymentLinks: paymentLinks(axiosConfig, options),
  kyc: kyc(axiosConfig, options),
  subscriptions: subscriptions(axiosConfig, options)
}));