import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Col,
  Grid,
  Row,
} from 'former-kit'
import styles from './styles.css'
import BenchGuy from '../../../../assets/bench_guy.svg'
import LinkCopy from '../../../../components/LinkCopy'

const CreatedPaymentLink = ({
  linkUrl, onCreateNewLink, onViewLink, t,
}) => {
  const getTranslation = subpath => t(`pages.payment_links.create.created_payment_link.${subpath}`)
  return (
    <Card>
      <CardContent>
        <Grid>
          <Row>
            <Col palm={12} tablet={12} desk={12} tv={12} align="center">
              <BenchGuy />
              <h3>{getTranslation('success_message')}</h3>
              <LinkCopy
                placement="rightEnd"
                status="active"
                url={linkUrl}
                t={t}
              />
            </Col>
          </Row>
        </Grid>
      </CardContent>
      <CardActions className={styles.actions}>
        <Button fill="outline" data-testid="button-visualize" onClick={onViewLink}>
          {getTranslation('view')}
        </Button>
        <Button onClick={onCreateNewLink} data-testid="button-new-payment-link">
          {getTranslation('new')}
        </Button>
      </CardActions>
    </Card>

  )
}

CreatedPaymentLink.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  onCreateNewLink: PropTypes.func.isRequired,
  onViewLink: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CreatedPaymentLink

