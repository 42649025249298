import React from 'react'
import PropTypes from 'prop-types'
import InfoCard from '../../../../components/InfoCard'
import style from './style.css'

const FeeCalculatorInfo = ({ t }) => (
  <div className={style.cardContainer}>
    <InfoCard
      title={t('pages.transactions.transaction_calculate_fees_info.title')}
      description={(
        <p className={style.description}>{t('pages.transactions.transaction_calculate_fees_info.text')}</p>
      )}
      buttonLabel={t('pages.transactions.transaction_calculate_fees_info.button')}
      link="/fees-calculator"
      isInternalLink
    />
  </div>
)

FeeCalculatorInfo.propTypes = {
  t: PropTypes.func.isRequired,
}

export default FeeCalculatorInfo
