export default {
  chargeback: {
    color: '#000000',
    key: 'models.installment_status.chargeback',
    textColor: '#ffffff',
  },
  chargeback_refund: {
    color: '#000000',
    key: 'models.installment_status.chargeback_refund',
  },
  paid: {
    color: '#65a300',
    key: 'models.installment_status.paid',
  },
  partial_chargeback: {
    color: '#000000',
    key: 'models.installment_status.partial_chargeback',
  },
  partial_refund: {
    color: '#6045af',
    key: 'models.installment_status.partial_refund',
  },
  refund: {
    color: '#6045af',
    key: 'models.installment_status.refund',
  },
  refund_reversal: {
    color: '#6045af',
    key: 'models.installment_status.refund_reversal',
  },
  waiting_funds: {
    color: '#f3b700',
    key: 'models.installment_status.waiting_funds',
  },
}
