import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-vanilla-form'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormDropdown,
  FormInput,
} from 'former-kit'

import getCountries from '../../utils/helpers/getCountries'
import isRequired from '../../validation/required'
import isEmail from '../../validation/email'
import isCpfCnpj from '../../validation/cpfCnpj'
import isPhone from '../../validation/e164phone'
import isNilOrEmpty from '../../validation/isNilOrEmpty'

import * as styles from './style.css'

const tPrefix = 'components.customer_form'

// eslint-disable-next-line no-useless-escape
const removeMask = value => value.replace(/\s|\-|\.|\//g, '')

const hasEmptyValues = formValue => Object.entries(formValue).flat().includes('')

const CustomerForm = ({
  country,
  documents,
  onBack,
  onSubmit,
  phone_numbers: phoneNumbers,
  t,
  type,
  ...customerProps
}) => {
  const [typeDocument, setTypeDocument] = useState(documents[0]?.type)
  const formData = {
    country: country.toUpperCase(),
    document: documents[0]?.number ?? '',
    phone: phoneNumbers[0] || '+55',
    ...customerProps,
  }
  if (type === 'subscription') {
    delete formData.external_id
  }
  const [invalidForm, setInvalidForm] = useState(hasEmptyValues(formData))

  const validations = {
    country: isRequired(t(`${tPrefix}.validations.country.required`)),
    document: [
      isRequired(t(`${tPrefix}.validations.document.required`)),
      isCpfCnpj(t(`${tPrefix}.validations.document.valid`)),
    ],
    email: [
      isRequired(t(`${tPrefix}.validations.email.required`)),
      isEmail(t(`${tPrefix}.validations.email.valid`)),
    ],
    external_id: isRequired(t(`${tPrefix}.validations.external_id.required`)),
    name: isRequired(t(`${tPrefix}.validations.name.required`)),
    phone: [
      isRequired(t(`${tPrefix}.validations.phone.required`)),
      isPhone(t(`${tPrefix}.validations.phone.valid`)),
    ],
  }

  const onSubmitForm = (form, errors) => {
    const { document, phone, ...formValue } = form
    if (isNilOrEmpty(errors)) {
      onSubmit({
        ...formValue,
        country: form.country.toLowerCase(),
        documents: [{
          number: removeMask(document),
          type: typeDocument,
        }],
        phone_numbers: [removeMask(phone)],
        type: typeDocument === 'cnpj' ? 'corporation' : 'individual',
      })
    }
  }

  const onChangeForm = (formValue, errors) => {
    const document = removeMask(formValue.document)

    if (document?.length <= 11) {
      setTypeDocument('cpf')
    }

    if (document?.length > 11) {
      setTypeDocument('cnpj')
    }

    setInvalidForm(
      !isNilOrEmpty(errors)
      || hasEmptyValues({ document, ...formValue })
    )
  }

  return (
    <Card>
      <Form
        data={formData}
        validation={validations}
        validateOn="blur"
        onSubmit={onSubmitForm}
        onChange={onChangeForm}
      >
        <CardContent>
          <h2 className={styles.title}>{t(`${tPrefix}.title`)}</h2>
          <div className={styles.form}>
            {type === 'transaction' && (
              <FormInput
                type="text"
                name="external_id"
                data-testid="external_id"
                label={t(`${tPrefix}.external_id`)}
              />
            )}
            <FormInput
              type="text"
              name="name"
              data-testid="name"
              label={t(`${tPrefix}.name`)}
            />
            <FormInput
              id="document"
              type="text"
              name="document"
              data-testid="document"
              label={t(`${tPrefix}.document`)}
            />
            <FormInput
              type="text"
              name="email"
              data-testid="email"
              label={t(`${tPrefix}.email`)}
            />
            <FormInput
              type="text"
              name="phone"
              data-testid="phone"
              label={t(`${tPrefix}.phone`)}
            />
            <FormDropdown
              options={getCountries(t)}
              name="country"
              data-testid="country"
              label={t(`${tPrefix}.country`)}
            />
          </div>
        </CardContent>
        <CardActions>
          {onBack && (
            <Button onClick={onBack} fill="outline">{t(`${tPrefix}.back`)}</Button>
          )}
          <Button type="submit" data-testid="button-advance" disabled={invalidForm}>{t(`${tPrefix}.submit`)}</Button>
        </CardActions>
      </Form>
    </Card>
  )
}

CustomerForm.propTypes = {
  country: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })),
  email: PropTypes.string,
  external_id: PropTypes.string,
  name: PropTypes.string,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  phone_numbers: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
}

CustomerForm.defaultProps = {
  country: 'br',
  documents: [],
  email: '',
  external_id: '',
  name: '',
  onBack: null,
  phone_numbers: [],
  type: 'transaction',
}

export default CustomerForm
