import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-vanilla-form'
import {
  Button,
  ModalActions,
  ModalContent,
  Col,
  FormDropdown,
  FormInput,
  Grid,
  Row,
  Truncate,
} from 'former-kit'

import CurrencyInput from '../../../components/CurrencyInput'
import formatterCardNumber from '../../../formatters/cardNumber'
import formatterCurrency from '../../../formatters/currency'
import greaterThanValidation from '../../../validation/greaterThan'
import lessThanOrEqualValidation from '../../../validation/lessThanOrEqual'
import numberValidation from '../../../validation/number'
import Property from '../../../components/Property'
import requiredValidation from '../../../validation/required'

import style from './style.css'

class CardForm extends Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (data, errors) {
    const { onConfirm } = this.props

    if (!errors) {
      onConfirm(data)
    }
  }

  render () {
    const {
      amount,
      brand,
      cardFirstDigits,
      cardLastDigits,
      email,
      holderName,
      installments,
      isAuthorized,
      recipient,
      recipients,
      showRecipientsSelector,
      t,
    } = this.props

    const isRequired = requiredValidation(t('pages.refund.required'))
    const isNumber = numberValidation(t('pages.refund.number'))

    const applyTruncate = (
      email
        ? (
          <span className={style.value}>
            <Truncate
              text={email}
            />
          </span>
        )
        : null
    )

    return (
      <Form
        data={{
          amount: amount.toString(),
          recipient,
        }}
        validateOn="blur"
        validation={{
          amount: [
            isRequired,
            isNumber,
            greaterThanValidation(amount, t('pages.refund.invalid_amount')),
            lessThanOrEqualValidation(0, t('pages.refund.greater_than_zero')),
          ],
        }}
        onSubmit={this.handleSubmit}
      >
        <ModalContent>
          <Grid>
            <Row>
              <Col palm={12} tablet={4} desk={4} tv={4}>
                <Property
                  title={t('models.card.holder_name')}
                  value={holderName}
                />
              </Col>

              <Col palm={12} tablet={4} desk={4} tv={4}>
                <Property
                  title={t('models.customer.email')}
                  value={
                    applyTruncate
                  }
                />
              </Col>

              <Col palm={12} tablet={4} desk={4} tv={4}>
                <Property
                  title={t('models.card.number')}
                  value={
                    `${formatterCardNumber(cardFirstDigits)} ${cardLastDigits}`
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col palm={12} tablet={4} desk={4} tv={4}>
                <Property
                  title={t('models.card.brand')}
                  value={brand}
                />
              </Col>

              <Col palm={12} tablet={4} desk={4} tv={4}>
                <Property
                  title={t('models.transaction.installments')}
                  value={t('models.transaction.installments_count', { count: installments })}
                />
              </Col>

              <Col palm={12} tablet={4} desk={4} tv={4}>
                <Property
                  title={t('models.transaction.amount')}
                  value={formatterCurrency(amount)}
                />
              </Col>
            </Row>
            <Row>
              { showRecipientsSelector && (
                <Col palm={12} tablet={8} desk={8} tv={8}>
                  <FormDropdown
                    label={t('pages.refund.selected_recipient')}
                    name="recipient"
                    options={recipients}
                  />
                </Col>
              )}

              <Col palm={12} tablet={4} desk={4} tv={4}>
                { isAuthorized
                  ? (
                    <Property
                      title={t('pages.refund.refund_amount_with_symbol')}
                      value={(
                        <span className={style.authorizedAmount}>
                          {formatterCurrency(amount)}
                        </span>
                      )}
                    />
                  )
                  : (
                    <FormInput
                      label={t('pages.refund.refund_amount_with_symbol')}
                      name="amount"
                      renderer={props => (
                        <CurrencyInput
                          {...props}
                        />
                      )}
                    />
                  )
                }

              </Col>
            </Row>
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button
            type="submit"
          >
            {t('pages.refund.continue')}
          </Button>
        </ModalActions>
      </Form>
    )
  }
}

CardForm.propTypes = {
  amount: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
  cardFirstDigits: PropTypes.string.isRequired,
  cardLastDigits: PropTypes.string.isRequired,
  email: PropTypes.string,
  holderName: PropTypes.string.isRequired,
  installments: PropTypes.number.isRequired,
  isAuthorized: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  recipient: PropTypes.string,
  recipients: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  showRecipientsSelector: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

CardForm.defaultProps = {
  email: null,
  isAuthorized: false,
  recipient: null,
  recipients: [],
  showRecipientsSelector: false,
}

export default CardForm
