import React from 'react'
import PropTypes from 'prop-types'
import InfoCard from '../../../../components/InfoCard'
import style from './style.css'

const TransactionCreateInfo = ({ t }) => (
  <div className={style.container}>
    <InfoCard
      title={t('pages.transactions.transaction_create_info.title')}
      description={(
        <p className={style.description}>{t('pages.transactions.transaction_create_info.text')}</p>
      )}
      buttonLabel={t('pages.transactions.transaction_create_info.button')}
      link="/transactions/add"
      isInternalLink
    />
  </div>
)

TransactionCreateInfo.propTypes = {
  t: PropTypes.func.isRequired,
}

export default TransactionCreateInfo
