/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  allPass,
  contains,
  equals,
  always,
  complement,
  compose,
  either,
  head,
  ifElse,
  includes,
  is,
  isEmpty,
  isNil,
  length,
  pathEq,
  pathOr,
  pipe,
  prop,
  tail,
  values,
  uncurryN,
  sortBy,
  reverse,
} from 'ramda'
import moment from 'moment-timezone'
import qs from 'qs'
import IconInfo from 'emblematic-icons/svg/InfoCircle32.svg'
import { Alert } from 'former-kit'
import Loader from '../../../components/Loader'
import { getErrorUrl } from '../../../formatters/error'

import {
  requestDetails,
  receiveDetails,
} from './actions'
import Reprocess from '../../Reprocess'
import currencyFormatter from '../../../formatters/decimalCurrency'
import checkIsPaymentLink from '../../../validation/isPaymentLink'
import isLinkmeSeller from '../../../validation/isLinkmeSeller'
import TransactionDetailsContainer from '../../../containers/TransactionDetails'
import Refund from '../../Refund'
import Capture from '../../Capture'
import { withError } from '../../ErrorBoundary'
import ResendBoletoModal from './ResendBoletoModal'
import SimulatePayment from './SimulatePayment'
import env, { impersonate } from '../../../environment'
import ApiClientSingleton from '../../../utils/helpers/ApiClientSingleton'
import handleExportDataSuccess from '../../../utils/helpers/fileDownload'

const hasPrintInQueryString = pipe(
  tail,
  qs.parse,
  prop('print'),
  equals('true')
)

const mapStateToProps = ({
  account: {
    client,
    company,
    jwt,
    sessionId,
    user: {
      permission,
    },
  },
  transactionDetails: { loading },
  transactions: { query },
}) => ({
  client,
  company,
  jwt,
  loading,
  permission,
  query,
  sessionId,
})

const mapDispatchToProps = ({
  onReceiveDetails: receiveDetails,
  onRequestDetails: requestDetails,
})

const enhanced = compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withError
)

const copyToClipBoard = (text) => {
  /* eslint-disable no-undef */
  const textarea = document.createElement('textarea')
  textarea.textContent = text
  textarea.style.opacity = 0
  textarea.style.position = 'absolute'
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
  /* eslint-enable no-undef */
}

const getActionLabels = t => ({
  boleto: t('pages.transaction.action_boleto'),
  capture: t('pages.transaction.action_capture'),
  export: t('export'),
  exportRefundPdf: t('pages.transaction.export_refund_pdf'),
  refund: t('pages.transaction.action_refund'),
  reprocess: t('pages.transaction.action_reprocess'),
  reprocessing: t('pages.transaction.action_reprocessing'),
  resendBoleto: t('pages.transaction.action_resend_boleto'),
  simulatePayment: t('pages.transaction.action_simulate_payment'),
})

const getTransactionDetailsLabels = t => ({
  acquirer_name: t('pages.transaction.acquirer_name'),
  acquirer_response_code: t('pages.transaction.acquirer_response_code'),
  antifraud_score: t('pages.transaction.antifraud_score'),
  authorization_code: t('pages.transaction.authorization_code'),
  capture_method: t('pages.transaction.capture_method'),
  nsu: t('pages.transaction.nsu'),
  soft_descriptor: t('pages.transaction.soft_descriptor'),
  subscription_id: t('pages.transaction.subscription_id'),
  tid: t('pages.transaction.tid'),
  title: t('pages.transaction.title'),
})

const countCustomerPhones = pipe(
  prop('phones'),
  ifElse(
    either(isNil, complement(is(Array))),
    always(0),
    length
  )
)

const getCustomerLabels = (customer, t) => ({
  birthday: t('models.customer.birthday'),
  city: t('models.customer.city'),
  complementary: t('models.customer.complement'),
  document_number: t('models.customer.document_number'),
  email: t('models.customer.email'),
  name: t('models.customer.name'),
  neighborhood: t('models.customer.neighborhood'),
  phone: t('models.customer.phone', { count: countCustomerPhones(customer) }),
  state: t('models.customer.state'),
  street: t('models.customer.street'),
  street_number: t('models.customer.number'),
  title: t('pages.transaction.customer_card_title'),
  zipcode: t('models.customer.zip_code'),
})

const getShowBoletoLabel = uncurryN(2, t => ifElse(
  allPass([
    complement(isNil),
    pathEq(['capabilities', 'capturable'], true),
    pathEq(['payment', 'method'], 'boleto'),
  ]),
  always(t('pages.transaction.boleto.issue')),
  always(t('pages.transaction.boleto.show'))
))

const getPaymentBoletoLabels = (t, transaction) => ({
  copy: t('pages.transaction.copy'),
  due_date: t('pages.transaction.boleto.due_date'),
  feedback: t('pages.transaction.boleto.feedback'),
  show: getShowBoletoLabel(t, transaction),
  title: t('pages.transaction.boleto.title'),
})

const getPaymentPixLabels = t => ({
  copy: t('pages.transaction.copy'),
  dueDateLabel: t('pages.transaction.pix.due_date'),
  feedback: t('pages.transaction.pix.feedback'),
  showQrcode: t('pages.transaction.pix.show_qr_code'),
  title: t('pages.transaction.pix.title'),
})

const getPaymentMethod = pathOr('', ['payment', 'method'])

const getEventPaymentLabels = t => (transaction) => {
  const paymentMethod = getPaymentMethod(transaction)
  const label = (
    paymentMethod
      ? t(`pages.transaction.event_labels.${paymentMethod}`)
      : ''
  )

  return ({ title: label })
}

const getPaymentCardLabels = (cardType, t) => ({
  title: t(cardType === 'prepaid' ? 'pages.transaction.card_prepaid' : 'pages.transaction.credit_card'),
})

const getRiskLevelsLabels = t => ({
  high: t('pages.transaction.risk_level.high'),
  low: t('pages.transaction.risk_level.low'),
  moderated: t('pages.transaction.risk_level.moderated'),
  very_high: t('pages.transaction.risk_level.very_high'),
  very_low: t('pages.transaction.risk_level.very_low'),
})

const getShippingLabels = t => ({
  city: t('pages.transactions.export.city'),
  complementary: t('pages.transactions.export.complementary'),
  country: t('pages.transactions.export.country'),
  delivery_date: t('components.payment_form.shipping.delivery_date'),
  expedited: t('components.payment_form.shipping.expedited'),
  fee: t('components.payment_form.shipping.fee'),
  neighborhood: t('pages.transactions.export.neighborhood'),
  number: t('pages.transactions.export.streetNumber'),
  recipient: t('recipient'),
  state: t('pages.transactions.export.state'),
  street: t('pages.transactions.export.street'),
  title: t('pages.transactions.shipping'),
  zipcode: t('pages.transactions.export.zipcode'),
})

// TODO: Remove this function and it usage when the this issue is solved
// https://github.com/pagarme/pilot/issues/681
const getDefaultPhone = ifElse(
  either(isNil, isEmpty),
  always(null),
  head
)

// TODO: Remove this function and it usage when the this issue is solved
// https://github.com/pagarme/pilot/issues/681
const removeCustomerUnusedPhones = (transaction) => {
  if (!transaction.customer) {
    return transaction
  }
  const { phones, ...customer } = transaction.customer

  return {
    ...transaction,
    customer: {
      ...customer,
      phone: getDefaultPhone(phones),
    },
  }
}

const anyPropEqual = value => pipe(
  values,
  contains(value)
)

const promiseDelay = timeout => new Promise(
  resolve => setTimeout(resolve, timeout)
)

const hasChangedNextId = ({
  client,
  transaction,
}) => promiseDelay(1000)
  .then(() => client.transactions.details(transaction.id))
  .then((result) => {
    const {
      transaction: updatedTransaction,
    } = result

    const hasChangedReprocess = (
      updatedTransaction.nextId !== transaction.nextId
    )

    if (hasChangedReprocess) {
      return result
    }

    return hasChangedNextId({
      client,
      transaction,
    })
  })

const isCreditError = pipe(
  getErrorUrl,
  includes('/credit/')
)

const getReprocessedTransaction = ifElse(
  () => impersonate,
  always(null),
  pipe(
    prop('reprocessed_transactions'),
    sortBy(prop('created_at')),
    reverse,
    head
  )
)

const getReprocessIds = (transaction, reprocessed) => {
  if (!impersonate) {
    // eslint-disable-next-line max-len
    const isOriginalTransaction = transaction.id === reprocessed?.original_transaction_id
    const hasPreviousId = !isOriginalTransaction && reprocessed

    return {
      nextId: isOriginalTransaction ? reprocessed.transaction_id : null,
      previousId: hasPreviousId ? reprocessed?.original_transaction_id : null,
    }
  }
  return {
    nextId: transaction.nextId,
    previousId: transaction.previousId,
  }
}

class TransactionDetails extends Component {
  constructor (props) {
    super(props)
    const { t } = this.props
    this.state = {
      actionLabels: getActionLabels(t),
      canExportCancellationLetter: false,
      canReproccessWhitoutAntifraud: false,
      chargebackRate: 0,
      eventPaymentLabel: { title: '' },
      expandRecipients: false,
      loading: {
        reprocess: false,
      },
      loadingRequest: false,
      lockReason: null,
      maxChargebackRate: 2,
      paymentBoletoLabels: getPaymentBoletoLabels(t),
      paymentPixLabels: getPaymentPixLabels(t),
      recipientsData: [],
      result: {
        transaction: {},
      },
      riskLevelsLabels: getRiskLevelsLabels(t),
      shippingLabels: getShippingLabels(t),
      showCapture: false,
      showRefund: false,
      showReprocess: false,
      showResendBoleto: false,
      showSimulatePayment: false,
      transactionDetailsLabels: getTransactionDetailsLabels(t),
    }

    this.handleRefundReceiptDownload = this.handleRefundReceiptDownload.bind(
      this
    )
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this)
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this)
    this.handleCapture = this.handleCapture.bind(this)
    this.handleCloseCapture = this.handleCloseCapture.bind(this)
    this.handleCloseRefund = this.handleCloseRefund.bind(this)
    this.handleCopyBoletoUrlClick = this.handleCopyBoletoUrlClick.bind(this)
    this.handleCopyQrCodeUrlClick = this.handleCopyQrCodeUrlClick.bind(this)
    this.handleManualReview = this.handleManualReview.bind(this)
    this.handleNextTransactionRedirect = this
      .handleNextTransactionRedirect
      .bind(this)
    this.handlePreviousTransactionRedirect = this
      .handlePreviousTransactionRedirect
      .bind(this)
    this.handleRefund = this.handleRefund.bind(this)
    this.handleReprocessClose = this.handleReprocessClose.bind(this)
    this.handleReprocessOpen = this.handleReprocessOpen.bind(this)
    this.handleReprocessSuccess = this.handleReprocessSuccess.bind(this)
    this.handleShowBoletoClick = this.handleShowBoletoClick.bind(this)
    this.handleResendBoletoOpen = this.handleResendBoletoOpen.bind(this)
    this.handleResendBoletoClose = this.handleResendBoletoClose.bind(this)
    this.handleSimulatePaymentOpen = this.handleSimulatePaymentOpen.bind(this)
    this.handleSimulatePaymentClose = this.handleSimulatePaymentClose.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.requestData = this.requestData.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleAfterPrint = this.handleAfterPrint.bind(this)
    this.handleChangeCollapse = this.handleChangeCollapse.bind(this)
    this.requestChargbackOperations = this.requestChargbackOperations.bind(this)
  }

  componentDidMount () {
    const {
      match: {
        params: {
          id,
        },
      },
    } = this.props
    this.setState({
      loadingRequest: true,
    })
    this.handleUpdate(id, true)

    this.afterPrintListener = window.addEventListener('afterprint', this.handleAfterPrint) // eslint-disable-line no-undef
    this.beforePrintListener = window.addEventListener('beforeprint', this.handleChangeCollapse) // eslint-disable-line no-undef
  }

  componentWillReceiveProps ({ match: { params: { id } } }) {
    this.handleUpdate(id)
  }

  componentDidUpdate (prevProps) {
    const {
      loading,
      location: {
        search,
      },
    } = this.props

    if ((prevProps.loading && !loading) && hasPrintInQueryString(search)) {
      this.handleExport()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('afterprint', this.afterPrintListener) // eslint-disable-line no-undef
    window.removeEventListener('beforeprint', this.handleChangeCollapse) // eslint-disable-line no-undef
  }

  handleAfterPrint () {
    this.handleChangeCollapse(false, null)
    this.setState({ expandRecipients: false })
  }

  handleRefundReceiptDownload () {
    const { result } = this.state

    if (impersonate) {
      const { client, company, t } = this.props
      import('./refundReceiptExport')
        .then(refundReceiptExport => refundReceiptExport.default({
          client,
          companyName: company.name,
          t,
          transaction: result.transaction,
        }))
    } else {
      const apiClient = ApiClientSingleton.get()

      apiClient.transactions.cancellationLetter({
        transactionId: result.transaction.id,
      }).then((data) => {
        handleExportDataSuccess(data.data.url)
      }).catch(() => {
      })
    }
  }

  handleUpdate (id, forceUpdate) {
    const { history, match: { params } } = this.props
    if (isNil(id)) {
      history.replace('/transactions')
    } else if (id !== params.id || forceUpdate) {
      this.requestData(id)
    }
  }

  requestChargbackOperations (query) {
    const apiClient = ApiClientSingleton.get()

    return apiClient.transactions.chargebackOperations({
      transactionId: query,
    }).then(({ data }) => {
      const isPreConditionFailed = data.is_precondition_failed
      if (isPreConditionFailed === false) {
        this.setState({
          canReproccessWhitoutAntifraud: true,
        })
      }
    }).catch((error) => {
      const getErrorMessage = pathOr([''], ['response', 'data', 'errors'])

      this.setState({
        canReproccessWhitoutAntifraud: false,
        lockReason: head(getErrorMessage(error)),
      })
    })
  }

  requestData (query) {
    const {
      client,
      onReceiveDetails,
      onRequestDetails,
      t,
    } = this.props
    onRequestDetails({ query })

    const apiClient = ApiClientSingleton.get()

    const transactionRequests = [
      client.transactions.details(query),
    ]

    transactionRequests.push(
      apiClient.transactions.findReprocessedTransactions({
        transactionId: query,
      }),
      apiClient.transactions.findOne({
        transactionId: query,
      })
    )
    this.requestChargbackOperations(query)

    return Promise.all(transactionRequests)
      .then((data) => {
        const [
          result,
          reprocessed,
          transactionDetails,
          chargebackRate,
          maxChargebackIndex,
        ] = data

        const reprocessedTransaction = getReprocessedTransaction(
          reprocessed?.data || []
        )

        const maxChargebackRate = chargebackRate?.transactionsCount > 100
          ? maxChargebackIndex
          : 10

        const { expandRecipients } = this.state
        const { recipients } = result.transaction
        const recipientsData = recipients.map(recipient => ({
          ...recipient,
          collapsed: !expandRecipients,
        }))

        const newState = {
          canExportCancellationLetter: impersonate
            ? result.transaction.payment.refund_amount > 0
            : transactionDetails.data.can_export_cancellation_letter,
          chargebackRate,
          eventPaymentLabel: getEventPaymentLabels(t)(result.transaction),
          loadingRequest: false,
          maxChargebackRate,
          paymentBoletoLabels: getPaymentBoletoLabels(t, result.transaction),
          recipientsData,
          result: {
            transaction: {
              ...result.transaction,
              ...getReprocessIds(result.transaction, reprocessedTransaction),
            },
          },
        }

        this.setState(newState)
        onReceiveDetails(result)
      })
  }

  handleAlertDismiss () {
    const { history } = this.props
    history.push('/')
  }

  handleCapture () {
    this.setState({ showCapture: true })
  }

  handleCloseCapture () {
    this.setState({ showCapture: false })
  }

  handleCopyBoletoUrlClick () {
    const {
      result: {
        transaction: {
          boleto,
        },
      },
    } = this.state
    copyToClipBoard(boleto.barcode)
  }

  handleCopyQrCodeUrlClick () {
    const {
      result: {
        transaction,
      },
    } = this.state
    copyToClipBoard(transaction.pix?.qr_code)
  }

  handleManualReview (status) {
    const {
      client,
      company,
      jwt,
      sessionId,
    } = this.props
    const { result } = this.state

    if (jwt) {
      this.setState({
        loading: {
          manualReview: true,
        },
      })

      const body = {
        status,
      }

      fetch(`https://api.pagar.me/1/transactions/${result.transaction.id}/antifraud_analyses?company_id=${company.id}`, {
        body: JSON.stringify(body),
        headers: {
          authorization: `Bearer ${jwt}`,
          'content-type': 'application/json',
          'x-live': env === 'live' ? '1' : '0',
        },
        method: 'POST',
      }).then(() => {
        this.handleUpdate(result.transaction.id)
      })
        .catch(() => {})
        .finally(() => {
          this.setState({
            loading: {
              manualReview: false,
            },
          })
        })
    } else if (sessionId) {
      client.antifraudAnalyses.create({
        status,
        transactionId: result.transaction.id,
      })
        .then(this.handleUpdate(result.transaction.id))
        .catch(() => {})
        .finally(() => {
          this.setState({
            loading: {
              manualReview: false,
            },
          })
        })
    }
  }

  handleRefund () {
    this.setState({ showRefund: true })
  }

  handleCloseRefund () {
    this.setState({ showRefund: false })
  }

  handleNextTransactionRedirect () {
    const {
      result: {
        transaction,
      },
    } = this.state
    const nextTransactionId = transaction.nextId
    const { history } = this.props
    history.push(`/transactions/${nextTransactionId}`)
  }

  handlePreviousTransactionRedirect () {
    const {
      result: {
        transaction: {
          previousId,
        },
      },
    } = this.state
    const { history } = this.props

    history.push(`/transactions/${previousId}`)
  }

  handleReprocessClose () {
    this.setState({
      showReprocess: false,
    })
  }

  handleReprocessOpen () {
    this.setState({
      showReprocess: true,
    })
  }

  handleReprocessSuccess () {
    const { client } = this.props
    const {
      result: {
        transaction,
      },
    } = this.state

    this.setState({
      loading: {
        reprocess: true,
      },
    })

    hasChangedNextId({
      client,
      transaction,
    })
      .then(result => this.setState({
        loading: {
          reprocess: false,
        },
        result,
      }))
  }

  handleExport () {
    this.handleChangeCollapse(true, null)
    this.setState({
      expandRecipients: true,
    }, () => window.print()) // eslint-disable-line no-undef
  }

  handleChangeCollapse (collapsed = true, id = null) {
    const { recipientsData } = this.state
    const item = recipientsData.map(
      (recipient) => {
        if (id === null) {
          return { ...recipient, collapsed: !collapsed }
        }

        if (recipient.id === id) {
          return { ...recipient, collapsed: !collapsed }
        }
        return recipient
      }
    )
    this.setState({ recipientsData: item })
  }

  handleShowBoletoClick () {
    const {
      result: {
        transaction: {
          boleto,
          capabilities,
          payment: {
            method,
          },
        },
      },
    } = this.state

    if (method === 'boleto' && capabilities.capturable) {
      this.setState({
        showCapture: true,
      })
    } else {
      // eslint-disable-next-line no-undef
      window.open(boleto.url)
    }
  }

  handleResendBoletoOpen () {
    this.setState({
      showResendBoleto: true,
    })
  }

  handleResendBoletoClose () {
    this.setState({
      showResendBoleto: false,
    })
  }

  handleSimulatePaymentOpen () {
    this.setState({
      showSimulatePayment: true,
    })
  }

  handleSimulatePaymentClose () {
    this.setState({
      showSimulatePayment: false,
    })
  }

  render () {
    const {
      company,
      error,
      permission,
      t,
    } = this.props
    const {
      actionLabels,
      canExportCancellationLetter,
      canReproccessWhitoutAntifraud,
      chargebackRate,
      eventPaymentLabel,
      loading,
      loadingRequest,
      lockReason,
      maxChargebackRate,
      paymentBoletoLabels,
      paymentPixLabels,
      recipientsData,
      result,
      riskLevelsLabels,
      shippingLabels,
      showCapture,
      showRefund,
      showReprocess,
      showResendBoleto,
      showSimulatePayment,
      transactionDetailsLabels,
    } = this.state

    const paymentMethod = getPaymentMethod(result.transaction)

    const modals = {
      showCapture,
      showRefund,
      showReprocess,
      showResendBoleto,
      showSimulatePayment,
    }

    const isShowingModal = anyPropEqual(true)

    if (error && !isShowingModal(modals) && !isCreditError) {
      const {
        localized: {
          message: localizedMessage,
        } = {},
        message,
      } = error

      return (
        <Alert
          icon={<IconInfo height={16} width={16} />}
          type="error"
        >
          <span>{localizedMessage || message }</span>
        </Alert>
      )
    }

    const transaction = removeCustomerUnusedPhones(result.transaction)

    const {
      captured_at,
      chargeback_reason: chargebackReason,
      customer,
      payment = { installments: [] },
      reason_code,
    } = transaction

    const alertLabels = {
      chargeback_reason: chargebackReason || t('pages.transaction.chargeback.code.unknown'),
      chargeback_reason_label: t('pages.transaction.alert.chargeback_reason'),
      reason_code: t('pages.transaction.alert.reason_code', { code: reason_code || '-' }),
      resubmit: t('pages.transaction.alert.resubmit'),
    }

    const customerLabels = getCustomerLabels(customer, t)

    const headerLabels = {
      approveLabel: t('pages.transaction.header.approve'),
      boletoAmountLabel: t('pages.transaction.header.boleto_amount'),
      cardAmountLabel: t('pages.transaction.header.card_amount'),
      installments: t('pages.transaction.header.installment', {
        count: payment.installments,
      }),
      installmentsLabel: t('pages.transaction.header.installment_title'),
      refuseLabel: t('pages.transaction.header.refuse'),
      statusLabel: t('pages.transaction.header.status'),
      title: t('pages.transaction.header.title'),
    }

    const reprocessLabels = {
      nextAlert: t('pages.transaction.reprocess.next'),
      previousAlert: t('pages.transaction.reprocess.previous'),
      showNext: t('pages.transaction.reprocess.showNext'),
      showPrevious: t('pages.transaction.reprocess.showPrevious'),
    }

    const totalDisplayLabels = {
      anticipation_fee: t('pages.transaction.anticipation_fee', {
        value: currencyFormatter(
          payment.anticipation_fee
        ),
      }),
      authorized_amount: t('pages.transaction.authorized_amount'),
      captured_at: captured_at
        ? t('captured_at', { date: moment(captured_at).format('L') })
        : t('pages.transaction.not_captured'),
      cost: t('models.payment.cost', {
        value: currencyFormatter(payment.cost_amount || 0),
      }),
      fee: {
        boleto: t('pages.transaction.boleto.fee'),
        credit_card: payment.fraud_coverage_amount > 0
          ? t('models.payment.mdr_with_fraud_coverage', {
            value: currencyFormatter(
              payment.mdr_amount + payment.fraud_coverage_amount
            ),
          })
          : t('models.payment.mdr', {
            value: currencyFormatter(
              payment.mdr_amount || 0
            ),
          }),
        pix: t('pages.transaction.pix.fee'),
      },
      net_amount: t('pages.transaction.net_amount'),
      out_amount: t('pages.transaction.out_amount'),
      paid_amount: t('pages.transaction.paid_amount'),
      refund: t('models.payment.refund', {
        value: currencyFormatter(payment.refund_amount || 0),
      }),
    }

    const tooltipLabels = {
      description: t('pages.transaction.tooltip.description'),
      title: t('pages.transaction.tooltip.title'),
    }

    const nextTransactionId = transaction.nextId
    const isLinkmeCompany = checkIsPaymentLink(company)

    const userPermissionIsNotReadOnly = permission !== 'read_only'
    const shouldShowSimulationModal = includes(paymentMethod, ['boleto', 'pix'])
    && userPermissionIsNotReadOnly

    if (loadingRequest) {
      return (
        <Loader
          opaqueBackground={false}
          position="absolute"
          visible
        />
      )
    }

    return (
      <Fragment>
        <TransactionDetailsContainer
          actionLabels={actionLabels}
          alertLabels={alertLabels}
          boletoWarningMessage={t('pages.transaction.boleto.waiting_payment_warning')}
          canExportCancellationLetter={canExportCancellationLetter}
          customerLabels={customerLabels}
          eventPaymentLabel={eventPaymentLabel}
          headerLabels={headerLabels}
          handleRefundReceiptDownload={this.handleRefundReceiptDownload}
          loading={loading}
          metadataTitle={t('pages.transaction.metadata')}
          nextTransactionId={nextTransactionId}
          onChangeCollapse={this.handleChangeCollapse}
          onCapture={this.handleCapture}
          onCopyBoletoUrl={this.handleCopyBoletoUrlClick}
          onCopyQrCodeUrl={this.handleCopyQrCodeUrlClick}
          onDismissAlert={this.handleAlertDismiss}
          onManualReviewApprove={this.handleManualReview}
          onManualReviewRefuse={this.handleManualReview}
          onRefund={this.handleRefund}
          onExport={this.handleExport}
          onNextTransactionRedirect={this.handleNextTransactionRedirect}
          onPreviousTransactionRedirect={this.handlePreviousTransactionRedirect}
          onReprocess={this.handleReprocessOpen}
          onResendBoleto={(this.handleResendBoletoOpen)}
          onSimulatePayment={(this.handleSimulatePaymentOpen)}
          onShowBoleto={this.handleShowBoletoClick}
          paymentBoletoLabels={paymentBoletoLabels}
          paymentCardLabels={getPaymentCardLabels(
            transaction.card?.funding_source,
            t
          )}
          paymentPixLabels={paymentPixLabels}
          permissions={{
            capture: permission !== 'read_only',
            manualReview: permission !== 'read_only',
            readOnly: permission === 'read_only',
            refund: (
              permission !== 'read_only'
              && !isLinkmeSeller({ company, user: { permission } })
            ),
            reprocess: permission !== 'read_only' && !isLinkmeCompany,
          }}
          riskLevelsLabels={riskLevelsLabels}
          recipientsData={recipientsData}
          reprocessLabels={reprocessLabels}
          shippingLabels={shippingLabels}
          t={t}
          tooltipLabels={tooltipLabels}
          totalDisplayLabels={totalDisplayLabels}
          transaction={transaction}
          transactionDetailsLabels={transactionDetailsLabels}
        />
        {showCapture
          && (
            <Capture
              isFromCheckout={transaction.referer === 'encryption_key'}
              isOpen={showCapture}
              onClose={this.handleCloseCapture}
              onSuccess={this.handleUpdate}
              t={t}
              transaction={transaction}
            />
          )
        }
        <Refund
          isOpen={showRefund}
          onClose={this.handleCloseRefund}
          onSuccess={this.handleUpdate}
          transaction={transaction}
        />
        <Reprocess
          canReproccessWhitoutAntifraud={canReproccessWhitoutAntifraud}
          chargebackRate={chargebackRate}
          isImpersonate={impersonate}
          isOpen={showReprocess}
          lockReason={lockReason}
          maxChargebackRate={maxChargebackRate}
          onClose={this.handleReprocessClose}
          onSuccess={this.handleReprocessSuccess}
          transaction={transaction}
        />
        {paymentMethod === 'boleto'
        && (
        <ResendBoletoModal
          isOpen={showResendBoleto}
          onClose={this.handleResendBoletoClose}
          onSuccess={this.handleUpdate}
          transaction={transaction}
        />
        )
        }

        {shouldShowSimulationModal && (
          <SimulatePayment
            isOpen={showSimulatePayment}
            onClose={this.handleSimulatePaymentClose}
            onSuccess={this.handleUpdate}
            transaction={transaction}
          />
        )
        }
      </Fragment>
    )
  }
}

TransactionDetails.propTypes = {
  client: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  error: PropTypes.shape({
    localized: PropTypes.shape({
      message: PropTypes.string,
      title: PropTypes.string,
    }),
    message: PropTypes.string,
    method: PropTypes.string,
    status: PropTypes.number,
    type: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  jwt: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  }).isRequired,
  onReceiveDetails: PropTypes.func.isRequired,
  onRequestDetails: PropTypes.func.isRequired,
  permission: PropTypes.string.isRequired,
  sessionId: PropTypes.string,
  t: PropTypes.func.isRequired,
}

TransactionDetails.defaultProps = {
  company: {},
  error: undefined,
  jwt: '',
  sessionId: '',
}

export default enhanced(TransactionDetails)
