import { useCallback, useEffect, useState } from 'react'
import ApiClientSingleton from '../../utils/helpers/ApiClientSingleton'

const useGetPaymentLinks = ({ parameters = {}, runOnMount = false } = {}) => {
  const [isLoading, setIsLoading] = useState(!!runOnMount)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  const getItems = useCallback(
    async ({ parameters: newParams } = parameters) => {
      setIsLoading(true)
      const apiClient = ApiClientSingleton.get()

      try {
        const { data } = await apiClient.paymentLinks.search(
          newParams || parameters
        )

        setResult(data)
      } catch (err) {
        if (err.response?.data) {
          setError(err.response.data)
        } else {
          setError(err.message)
        }
      } finally {
        setIsLoading(false)
      }
    }, [parameters]
  )

  useEffect(() => {
    if (runOnMount) {
      getItems(parameters)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runOnMount])

  return {
    error,
    isLoading,
    request: getItems,
    result,
  }
}

export default useGetPaymentLinks
