import React from 'react'
import PropTypes from 'prop-types'

import style from './style.css'

const SummaryCard = ({ rows, title }) => (
  <div className={style.subcard}>
    <h3 className={style.subtitle}>
      { title }
    </h3>
    {rows.map(row => row && (
      <div className={style[row.type || 'info']} key={row.label}>
        <p>{ row.label }</p>
        <p>{ row.value }</p>
      </div>
    ))}
  </div>
)

SummaryCard.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

export default SummaryCard
