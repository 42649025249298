export default {
  items: [
    {
      label: 'Visa',
      value: 'visa',
    },
    {
      label: 'Elo',
      value: 'elo',
    },
    {
      label: 'Aura',
      value: 'aura',
    },
    {
      label: 'Amex',
      value: 'amex',
    },
    {
      label: 'Discover',
      value: 'discover',
    },
    {
      label: 'Mastercard',
      value: 'mastercard',
    },
    {
      label: 'Hipercard',
      value: 'hipercard',
    },
    {
      label: 'JCB',
      value: 'jcb',
    },
    {
      label: 'Diners',
      value: 'diners',
    },
  ],
  key: 'card_brands',
  name: 'pages.transactions.filters.card_brands',
  type: 'checkbox',
}
