import React from 'react'
import PropTypes from 'prop-types'
import { withI18n } from 'react-i18next'
import {
  Legend,
} from 'former-kit'
import style from './styles.css'
import installmentStatusTypes from '../../../models/installmentStatus'

const InstallmentStatus = ({ status, t }) => (
  <div className={style.centralizedItem}>
    <Legend
      acronym={t(installmentStatusTypes[status].key)}
      color={installmentStatusTypes[status].color}
      hideLabel
      textColor={installmentStatusTypes[status].textColor}
      textFormat="capitalize"
    >
      {t(installmentStatusTypes[status].key)}
    </Legend>
  </div>
)

InstallmentStatus.propTypes = {
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18n()(InstallmentStatus)
