import React from 'react';
import { sidebarOptionsStyle } from './SidebarOptions.style';
import { SidebarItem } from './SidebarItem/SidebarItem';
export const SidebarOptions = props => {
  var _a;
  return React.createElement("ul", {
    className: "\n    ".concat(sidebarOptionsStyle(), "\n    ").concat((_a = props.classes) === null || _a === void 0 ? void 0 : _a.list, "\n  ")
  }, props.options.map((option, index) => {
    var _a;
    return React.createElement(SidebarItem, {
      index: index,
      selected: props.selected,
      key: option.label,
      label: props.translate(option.label),
      event: option.event,
      className: (_a = props.classes) === null || _a === void 0 ? void 0 : _a.item,
      value: option.value
    });
  }));
};