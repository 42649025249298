import React from 'react'
import PropTypes from 'prop-types'
import {
  Flexbox,
} from 'former-kit'
import EmptyStateIcon from './EmptyStateIcon.svg'
import { Message } from '../../components/Message'
import styles from './styles.css'

const TableEmptyList = ({ t }) => (
  <Flexbox
    alignItems="center"
    className={styles.emptyStateBlock}
    direction="column"
  >
    <Message
      image={<EmptyStateIcon width={365} height={148} />}
      message={(
        <>
          <div>
            {t('pages.transactions.no_results_title')}
          </div>
          <div>
            {t('pages.transactions.no_results_message')}
          </div>
        </>
    )}
    />
  </Flexbox>
)

TableEmptyList.propTypes = {
  t: PropTypes.func.isRequired,
}

export default TableEmptyList
