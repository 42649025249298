import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'

import {
  CardActions,
  Button,
} from 'former-kit'

import {
  unless,
  is,
  of,
} from 'ramda'

import style from './style.css'

const ensureArray = unless(
  is(Array),
  of
)

const renderChildrenInput = ({
  disabled,
  index,
  input,
  styled,
}) => cloneElement(input, {
  className: styled.search,
  disabled,
  key: `${input.props.name}-${index}`,
})

const Toolbar = ({
  children,
  confirmDisabled,
  disabled,
  t,
}) => (
  <CardActions className={style.cardAction}>
    {ensureArray(children)
      .map((input, index) => renderChildrenInput({
        disabled,
        index,
        input,
        styled: style,
      }))
    }

    <Button
      relevance="normal"
      disabled={confirmDisabled}
      type="submit"
    >
      {t('components.filter.apply')}
    </Button>
  </CardActions>
)

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
  confirmDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

Toolbar.defaultProps = {
  confirmDisabled: true,
  disabled: false,
}

export default Toolbar
