import { juxt } from 'ramda'
import { exportTransactions as gtmExportTransactions } from './googleTagManager'
import { exportTransactions as fullStoryExportTransactions } from './fullStory'

/**
 * send exportTransactions event to vendor sdks
 *
 * @param {string} exportType exportType (csv or xls)
 * @param {array} columns columns chosen
  */
export default juxt([
  gtmExportTransactions,
  fullStoryExportTransactions,
])
