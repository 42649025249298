export const searchInputOptions = t => ([
  {
    key: 'ids',
    label: t('pages.payment_links.filter.input_id'),
    pattern: /^\s*[A-Za-z0-9_]+\s*$/,
    placeholder: 'Ex: 123456',
    tag: t('pages.payment_links.filter.input_id_tag'),
  },
  {
    key: 'short_ids',
    label: t('pages.payment_links.filter.input_short_id'),
    pattern: /^\s*[A-Za-z0-9_]+\s*$/,
    placeholder: 'Ex: tHyHVG6zj6',
    tag: t('pages.payment_links.filter.input_short_id_tag'),
  },
  {
    key: 'names',
    label: t('pages.payment_links.filter.input_name'),
    placeholder: 'Ex: João',
    tag: t('pages.payment_links.filter.input_name_tag'),
  },
])

export const statusOptions = t => [
  {
    label: t('pages.payment_links.filter.active'),
    value: 'active',
  },
  {
    label: t('pages.payment_links.filter.inactive'),
    value: 'inactive',
  },
]

export const dateSelectorButtonLabels = {
  empty: 'search_filter.date_selector.button.empty',
  full: 'search_filter.date_selector.button.full',
}

export const dateSelectorOptionsLabels = {
  custom: 'search_filter.date_selector.options.custom',
  lastMonth: 'search_filter.date_selector.options.lastMonth',
  lastWeek: 'search_filter.date_selector.options.lastWeek',
  lastYear: 'search_filter.date_selector.options.lastYear',
  month: 'search_filter.date_selector.options.month',
  today: 'search_filter.date_selector.options.today',
  week: 'search_filter.date_selector.options.week',
  year: 'search_filter.date_selector.options.year',
  yesterday: 'search_filter.date_selector.options.yesterday',
}
