import React from 'react'
import {
  pipe,
  prop,
} from 'ramda'

import { Legend } from 'former-kit'
import PropTypes from 'prop-types'
import formatDate from '../../../../formatters/longDate'
import statusLegends from '../../../../models/statusLegendsBff'

const renderLegend = ({ status }, t) => (
  <Legend
    acronym={t(statusLegends[status]?.text)}
    color={statusLegends[status]?.color}
    hideLabel
    textColor={statusLegends[status]?.textColor}
  >
    { t(statusLegends[status]?.text) }
  </Legend>
)

renderLegend.propTypes = {
  status: PropTypes.string.isRequired,
}

const getDefaultColumns = t => ([
  {
    accessor: ['status'],
    renderer: item => renderLegend(item, t),
    title: t('models.transaction.status'),
  },
  {
    accessor: ['id'],
    renderer: prop('id'),
    title: t('models.transaction.id'),
  },
  {
    accessor: ['created_at'],
    renderer: pipe(prop('created_at'), formatDate),
    title: 'Data de criação',
  },
  {
    accessor: ['customer_name'],
    renderer: prop('customer_name'),
    title: t('models.transaction.customer_name'),
  },
  {
    accessor: ['customer_document'],
    renderer: prop('customer_document'),
    title: t('models.transaction.document'),
  },
  {
    accessor: ['payment_methods'],
    renderer: pipe(
      row => row.payment_methods.join(', ')
    ),
    title: t('models.transaction.payment_method'),
  },
  {
    accessor: ['amount'],
    renderer: prop('amount'),
    title: t('models.transaction.transaction_amount'),
  },
])

export default getDefaultColumns
