/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardContent,
  CardTitle,
  Grid,
  Row,
  Col,
} from 'former-kit'
import {
  keys,
  mapObjIndexed,
  splitEvery,
} from 'ramda'
import Property from '../Property'
import style from './style.css'

const getFields = ({
  columns, data, labels, order,
}) => {
  const properties = mapObjIndexed((value, key) => (
    <Property
      title={labels[key]}
      value={value}
    />
  ), data)

  return order.length > 0
    ? splitEvery(columns, order.map(key => properties[key]))
    : splitEvery(columns, keys(properties).map(key => properties[key]))
}

const composeIndex = (rowIndex, fieldIndex) => `${rowIndex}_col_${fieldIndex}`

const DetailsCard = ({
  data, labels, numberOfColumns, order, title,
}) => (
  <Card>
    <CardTitle title={title} />
    <CardContent>
      <Grid>
        <Row className={style.propertyCustom}>
          {
            getFields({
              columns: numberOfColumns, data, labels, order,
            })
              .map((row, rowIndex) => (
                row.map((field, fieldIndex) => (
                  <Col
                    palm={12}
                    tablet={6}
                    desk={4}
                    tv={4}
                    key={composeIndex(rowIndex, fieldIndex)}
                    className={style.propertyCustom}
                  >
                    { field }
                  </Col>
                ))))
          }
        </Row>
      </Grid>
    </CardContent>
  </Card>
)

DetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  numberOfColumns: PropTypes.number,
  order: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

DetailsCard.defaultProps = {
  numberOfColumns: 3,
  order: [],
}

export default DetailsCard
