import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import qs from 'qs'
import {
  always,
  applySpec,
  compose,
  defaultTo,
  either,
  equals,
  identity,
  ifElse,
  isEmpty,
  isNil,
  juxt,
  mergeAll,
  mergeRight,
  objOf,
  path,
  pipe,
  prop,
  replace,
  tail,
  unless,
  when,
  pluck,
  pathOr,
  allPass,
  values,
} from 'ramda'
import { isMomentPropValidation, Grid } from 'former-kit'
import env from '../../../environment'

import exportCSVorXLS from '../../../utils/helpers/ExportCSVorXLS'
import logExportTransactions from '../../../vendor/exportTransactions'
import logChangeTransactionsViewMode from '../../../vendor/changeTransactionsViewMode'
import canCreateTransaction from '../../../validation/canCreateTransaction'
import { hasAutomaticAnticipation, hasCompulsory } from '../../../validation/hasAnticipation'

import style from './style.css'
import {
  requestSearch,
  receiveSearch,
  clearSearch,
} from './actions'

import dateSelectorPresets from '../../../models/dateSelectorPresets'
import filterOptions from '../../../models/transactionFilterOptions'
import TransactionsList from '../../../containers/TransactionsList'
import ExportSettingsModal from '../components/ExportSettingsModal'
import TransactionCreateInfo from '../components/TransactionCreateInfo'
import FeeCalculatorInfo from '../components/FeeCalculatorInfo'
import ApiClientSingleton from '../../../utils/helpers/ApiClientSingleton'

const mapStateToProps = ({
  account: {
    client, company, defaultRecipient, user,
  },
  transactions: { loading, query },
}) => ({
  client,
  company,
  defaultRecipient,
  loading,
  query,
  user,
})

const mapDispatchToProps = dispatch => ({
  onReceiveSearch: ({ query }) => {
    dispatch(receiveSearch({ query }))
  },
  onRequestClearSearch: () => {
    dispatch(clearSearch())
  },
  onRequestSearch: (query) => {
    dispatch(requestSearch(query))
  },
})

const enhanced = compose(
  translate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)

const momentToString = momentObj => momentObj.format('MM/DD/YYYY')

const normalizeDateToString = property => pipe(
  prop(property),
  unless(
    either(isNil, isEmpty),
    pipe(momentToString, objOf(property))
  )
)

const normalizeQueryDatesToString = pipe(
  prop('dates'),
  juxt([normalizeDateToString('start'), normalizeDateToString('end')]),
  mergeAll
)

const normalizeQueryLastUpdateToString = pipe(
  prop('lastUpdate'),
  juxt([normalizeDateToString('start'), normalizeDateToString('end')]),
  mergeAll
)

const stringToMoment = str => moment(str)

const normalizeDateTime = property => (date) => {
  if (property === 'start') {
    return date.startOf('day')
  }

  return date.endOf('day')
}

const normalizeStringToDate = property => pipe(
  prop(property),
  ifElse(
    either(isNil, isEmpty),
    always({ end: null, start: null }),
    pipe(
      stringToMoment,
      normalizeDateTime(property),
      objOf(property)
    )
  )
)

const normalizeQueryStringToDate = pipe(
  prop('dates'),
  juxt([normalizeStringToDate('start'), normalizeStringToDate('end')]),
  mergeAll,
  objOf('dates')
)

const normalizeQueryStringToLastUpdate = pipe(
  prop('lastUpdate'),
  juxt([normalizeStringToDate('start'), normalizeStringToDate('end')]),
  mergeAll,
  objOf('lastUpdate')
)

const normalizeTo = (defaultValue, propPath) => pipe(
  path(propPath),
  when(
    either(isNil, isEmpty),
    defaultTo(defaultValue)
  )
)

const normalizeQueryStructure = applySpec({
  count: pipe(
    normalizeTo(15, ['count']),
    Number
  ),
  filters: normalizeTo({}, ['filters']),
  offset: pipe(
    normalizeTo(1, ['offset']),
    Number
  ),
  search: normalizeTo('', ['search']),
  sort: {
    field: normalizeTo(['created_at'], ['sort', 'field']),
    order: normalizeTo('descending', ['sort', 'order']),
  },
})

const parseQueryUrl = pipe(
  tail,
  qs.parse,
  juxt([
    identity,
    normalizeQueryStringToDate,
    normalizeQueryStringToLastUpdate,
    normalizeQueryStructure,
  ]),
  mergeAll
)

const hasOldMetadataExport = pathOr(false, ['features', 'old_metadata_export'])

const defaultPreset = 'days-7'

class TransactionsSearch extends React.Component {
  constructor (props) {
    super(props)

    const { company } = props
    const urlSearchQuery = props.history.location.search

    this.localizedPresets = dateSelectorPresets(props.t)

    this.apiClient = ApiClientSingleton.get()

    this.state = {
      clearFilterDisabled: true,
      collapsed: true,
      confirmationDisabled: true,
      currentPage: 1,
      expandedRows: [],
      exporting: false,
      exportModalIsOpen: false,
      exportType: null,
      oldMetadataExport: hasOldMetadataExport(company),
      // eslint-disable-next-line react/no-unused-state
      pagination: null,
      pendingReviewsCount: 0,
      query: isEmpty(urlSearchQuery)
        ? props.query
        : parseQueryUrl(urlSearchQuery),
      result: {
        chart: {
          dataset: [],
        },
        list: {
          rows: [],
        },
        total: {},
      },
      selectedPreset: defaultPreset,
      selectedRows: [],
      showDateInputCalendar: false,
      transactions: [],
      viewMode: 'table',
    }

    this.handleChartsCollapse = this.handleChartsCollapse.bind(this)
    this.handleDatePresetChange = this.handleDatePresetChange.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleFilterConfirm = this.handleFilterConfirm.bind(this)
    this.handleFilterClear = this.handleFilterClear.bind(this)
    this.handleOrderChange = this.handleOrderChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePageCountChange = this.handlePageCountChange.bind(this)
    this.handlePendingReviewsFilter = this.handlePendingReviewsFilter.bind(this)
    this.handleSelectRow = this.handleSelectRow.bind(this)
    this.handleViewModeChange = this.handleViewModeChange.bind(this)
    this.requestData = this.requestData.bind(this)
    this.requestPendingReviewsCount = this.requestPendingReviewsCount.bind(this)
    this.toggleExportModal = this.toggleExportModal.bind(this)
  }

  componentDidMount () {
    const { query } = this.state
    this.requestData(query)
  }

  componentDidUpdate (prevProps) {
    const { query } = this.props
    if (!equals(prevProps.query, query)) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        query,
      })

      this.updateQuery(query)
    }
  }

  toggleExportModal (exportType = null) {
    const { exportModalIsOpen } = this.state
    this.setState({
      exportModalIsOpen: !exportModalIsOpen,
      exportType,
    })
  }

  requestPendingReviewsCount () {
    const { client } = this.props

    return client
      .transactions
      .countPendingReviews()
      .then(({ count }) => {
        this.setState({
          pendingReviewsCount: count,
        })
      })
  }

  handlePendingReviewsFilter () {
    this.handleFilterConfirm()
  }

  updateQuery (query) {
    const {
      history: {
        location,
        push,
      },
    } = this.props

    const buildSearchQuery = (queryToFormat) => {
      const dates = normalizeQueryDatesToString(queryToFormat)
      const lastUpdate = normalizeQueryLastUpdateToString(queryToFormat)

      const newQuery = {
        ...queryToFormat,
        dates,
        lastUpdate,
      }

      return qs.stringify(newQuery)
    }

    const newQuery = buildSearchQuery(query)
    const currentQuery = replace('?', '', location.search)

    this.setState({
      expandedRows: [],
      selectedRows: [],
    })

    if (currentQuery !== newQuery) {
      push({
        pathname: 'transactions',
        search: newQuery,
      })

      this.requestData(query)
    }
  }

  requestData (query) {
    const {
      onReceiveSearch,
      onRequestSearch,
    } = this.props

    onRequestSearch({ query })

    this.apiClient.transactions.search({
      ...query.createdDates,
      ...query.filters,
      ...query.filter,
      cursor: query.cursor,
      size: query.count,
    }).then((data) => {
      this.setState({
        pagination: data.data.pagination,
        transactions: data.data.items,
      })
      onReceiveSearch({ query })
    })
  }

  handleDatePresetChange (dates, preset) {
    const { query } = this.state
    this.setState({
      clearFilterDisabled: false,
      query: {
        ...query,
        dates,
      },
      selectedPreset: preset.key,
      showDateInputCalendar: true,
    })
  }

  handlePageCountChange (count) {
    const { query: stateQuery } = this.state
    const query = {
      ...stateQuery,
      count,
      cursor: null,
      filters: {
        ...stateQuery.filters,
      },
      offset: 1,
    }

    this.setState({
      currentPage: 1,
    })

    this.updateQuery(query)
  }

  handleOrderChange (field, order) {
    const { query: stateQuery } = this.state
    const query = {
      ...stateQuery,
      offset: 1,
      sort: {
        field,
        order,
      },
    }

    this.updateQuery(query)
  }

  handleFilterChange (query) {
    const { query: stateQuery } = this.state
    const newQuery = mergeRight(stateQuery, {
      ...query,
    })

    this.setState({
      clearFilterDisabled: false,
      confirmationDisabled: false,
      query: newQuery,
    })
  }

  handleFilterClear () {
    const { onRequestClearSearch } = this.props
    this.setState({
      clearFilterDisabled: true,
      confirmationDisabled: true,
      selectedPreset: defaultPreset,
    })

    onRequestClearSearch()
  }

  handleFilterConfirm () {
    const { query: stateQuery } = this.state
    if (stateQuery.cursor) {
      delete stateQuery.cursor
    }
    this.setState({
      currentPage: 1,
    })
    this.requestData(stateQuery)
  }

  handlePageChange (direction) {
    const { currentPage, pagination, query: stateQuery } = this.state
    if (direction === 'after') {
      if (currentPage < pagination.total_page) {
        this.setState({
          currentPage: currentPage + 1,
        })
      }
    } else if (currentPage > 0) {
      this.setState({
        currentPage: currentPage - 1,
      })
    }
    const query = {
      ...stateQuery,
      cursor: pagination[direction] || null,
      filters: {
        ...stateQuery.filters,
      },
    }
    this.updateQuery(query)
  }

  handleChartsCollapse () {
    const { collapsed } = this.state

    this.setState({
      collapsed: !collapsed,
    })
  }

  handleViewModeChange (viewMode) {
    logChangeTransactionsViewMode(viewMode)
    this.setState({
      viewMode,
    })
  }

  handleExport (exportFields) {
    const { exportType } = this.state
    this.setState({ exporting: true, exportModalIsOpen: false })

    const {
      query,
    } = this.state

    this.apiClient.transactions.report(
      {
        ...query.createdDates,
        ...query.filters,
        ...query.filter,
      },
      {
        fields: exportFields.map(field => field.key),
        report_type: 'csv',
      }
    ).then((res) => {
      const filename = `Pagarme - ${moment().format('LLL')}.`
      const columnKeys = ['Valor (R$)', 'Valor Capturado (R$)', 'Valor Estornado (R$)', 'Custo da Transação']
      const columnsPositions = []
      const columns = res.data.data[res.data.data.length - 1]
      const csvFormatter = exportData => values(exportData).map(value => value.map(item => `"${item}"`)).join('\r\n')
      let items = res.data.data.reverse()

      if (exportType === 'csv') {
        items = csvFormatter(items)
      }

      if (columns && exportType !== 'csv') {
        columns.forEach((column, index) => {
          if (columnKeys.includes(column)) {
            columnsPositions.push(index)
          }
        })
      }

      exportCSVorXLS(exportType,
        items,
        filename,
        columnsPositions)

      logExportTransactions({
        columns: pluck('value', exportFields),
        exportType,
      })

      this.setState({ exporting: false, exportType: null })
    })
  }

  handleSelectRow (selectedRows) {
    this.setState({
      selectedRows,
    })
  }

  render () {
    const {
      clearFilterDisabled,
      collapsed,
      columns,
      confirmationDisabled,
      currentPage,
      expandedRows,
      exportModalIsOpen,
      exporting,
      oldMetadataExport,
      pagination: currentPagination,
      pendingReviewsCount,
      query,
      query: {
        count,
        offset,
        sort,
      },
      result: {
        chart,
        // eslint-disable-next-line no-unused-vars
        list,
        total,
      },
      selectedPreset,
      selectedRows,
      showDateInputCalendar,
      transactions,
      viewMode,
    } = this.state

    const {
      company,
      defaultRecipient,
      loading,
      t,
      user,
    } = this.props

    const pagination = {
      offset,
      total: Math.ceil(currentPagination?.total_items / count || 1),
    }

    const hasCompulsoryOrAutomatic = either(
      () => hasCompulsory(company, defaultRecipient),
      () => hasAutomaticAnticipation(defaultRecipient)
    )
    const envAvailable = () => env !== 'test'

    const canAccessFeeCalculator = allPass([
      hasCompulsoryOrAutomatic,
      envAvailable,
    ])(company)

    return (
      <>
        <Grid className={style.grid}>
          {canCreateTransaction(company, user) && (
            <TransactionCreateInfo t={t} />
          )}
          {canAccessFeeCalculator && <FeeCalculatorInfo t={t} />}
        </Grid>
        <TransactionsList
          amount={total.payment
            ? total.payment.paid_amount
            : 0
          }
          clearFilterDisabled={clearFilterDisabled}
          collapsed={collapsed}
          columns={columns}
          count={currentPagination?.total_items}
          confirmationDisabled={confirmationDisabled}
          currentPage={currentPage}
          data={chart.dataset}
          dateSelectorPresets={this.localizedPresets}
          expandedRows={expandedRows}
          exporting={exporting}
          filterOptions={filterOptions}
          loading={loading}
          onChartsCollapse={this.handleChartsCollapse}
          onDatePresetChange={this.handleDatePresetChange}
          onExport={this.toggleExportModal}
          onFilterChange={this.handleFilterChange}
          onFilterConfirm={this.handleFilterConfirm}
          onFilterClear={this.handleFilterClear}
          onOrderChange={this.handleOrderChange}
          onPageChange={this.handlePageChange}
          onPageCountChange={this.handlePageCountChange}
          onPendingReviewsFilter={this.handlePendingReviewsFilter}
          onSelectRow={this.handleSelectRow}
          order={sort.order}
          orderField={sort.field}
          pagination={pagination}
          pendingReviewsCount={pendingReviewsCount}
          query={query}
          rows={transactions}
          selectedPage={count}
          selectedPreset={selectedPreset}
          selectedRows={selectedRows}
          showDateInputCalendar={showDateInputCalendar}
          t={t}
          viewMode={viewMode}
        />
        <ExportSettingsModal
          isOpen={exportModalIsOpen}
          showMetadataInColumns={oldMetadataExport}
          onCancel={this.toggleExportModal}
          onSubmit={this.handleExport}
          t={t}
        />
      </>
    )
  }
}

TransactionsSearch.propTypes = {
  client: PropTypes.shape({
    transactions: PropTypes.shape({
      countPendingReviews: PropTypes.func.isRequired,
      exportData: PropTypes.func.isRequired,
      search: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  company: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  defaultRecipient: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onReceiveSearch: PropTypes.func.isRequired,
  onRequestClearSearch: PropTypes.func.isRequired,
  onRequestSearch: PropTypes.func.isRequired,
  query: PropTypes.shape({
    count: PropTypes.number.isRequired,
    dates: PropTypes.shape({
      end: isMomentPropValidation,
      start: isMomentPropValidation,
    }),
    filters: PropTypes.shape({}),
    offset: PropTypes.number.isRequired,
    search: PropTypes.shape({}),
    sort: PropTypes.shape({
      field: PropTypes.arrayOf(PropTypes.string),
      order: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    permission: PropTypes.string.isRequired,
  }).isRequired,
}

export default enhanced(TransactionsSearch)
