import React from 'react'
import { ThemeProvider } from 'former-kit'
import PropTypes from 'prop-types'
import theme from 'former-kit-skin-pagarme'
import { Provider as StateProvider } from 'react-redux'

import store from 'pilot/configureStore'

import Root from './pages/Root'

const App = ({
  accountId, merchantId, onError, onSubmit, token,
}) => (
  <ThemeProvider theme={theme}>
    <StateProvider store={store}>
      <Root
        onError={onError}
        onSubmit={onSubmit}
        accountId={accountId}
        merchantId={merchantId}
        jwt={token}
      />
    </StateProvider>
  </ThemeProvider>
)

App.propTypes = {
  accountId: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
}

export default App
