import Cookies from 'js-cookie'
import pagarme from 'pagarme'
import {
  complement,
  identity,
  isEmpty,
  merge,
  path,
  pathOr,
  propEq,
  propOr,
  uncurryN,
} from 'ramda'
import {
  map,
  mergeMap,
  tap,
} from 'rxjs/operators'
import { of as rxOf } from 'rxjs'
import { combineEpics, ofType } from 'redux-observable'
import cockpit from 'cockpit'
// eslint-disable-next-line import/no-unresolved
import apiClient from 'api-client'
import env, { idSignInUrl, impersonate } from '../../../environment'
import setCompany from '../../../vendor/setCompany'
import {
  ACCOUNT_RECEIVE,
  COMPANY_RECEIVE,
  failLogin,
  LOGIN_RECEIVE,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RECIPIENT_RECEIVE,
  receiveAccount,
  receiveCompany,
  receiveLogin,
  receiveLogout,
  receiveRecipientBalance,
  receiveRecipient,
  receiveFeePreset,
  GET_ACQUIRERS_REQUEST,
  getAcquirersResponse,
} from './actions'

import store from '../../../configureStore'

import { WITHDRAW_RECEIVE } from '../../Withdraw/actions'
import { receiveError } from '../../ErrorBoundary'
import {
  activeCompanyLogin,
  paymentLinkCompanyLogin,
  inactiveCompanyLogin,
} from '../../../vendor/googleTagManager'

import isPaymentLink from '../../../validation/isPaymentLink'
import ApiClientSingleton from '../../../utils/helpers/ApiClientSingleton'
import getUserDetails from './getUserDetails'

const isActiveCompany = propEq('status', 'active')
const isSelfRegister = propEq('type', 'self_register')
const isPendingRiskAnalysis = propEq('status', 'pending_risk_analysis')

const hasDashboardAccess = path(['capabilities', 'allow_dashboard_login'])

const hasPaymentsAccess = path(['capabilities', 'allow_payments_request'])

const getRecipientId = state => pathOr(null, ['account', 'company', 'default_recipient_id', state.account.impersonationEnv || env])(state)

const getFeePresetId = pathOr(null, ['account', 'defaultRecipient', 'fee_preset_id'])

const isNotEmpty = complement(isEmpty)

const errorHandler = (error) => {
  console.error(error) // eslint-disable-line no-console

  store.dispatch(receiveError(error))
  return Promise.reject(error)
}

const verifyCapabilityPermission = client => (
  client.company.current()
    .then((company) => {
      if (!hasDashboardAccess(company)) {
        if (client.authentication.session_id) {
          client.session.destroy(client.authentication.session_id)
        }

        throw new Error('Unauthorized Login')
      }

      return client
    })
)

const alreadyTransacted = async (client) => {
  try {
    const [payables] = await Promise.all([
      client.payables.all({ count: 1 }),
    ])
    return isNotEmpty(payables)
  } catch (e) {
    return true
  }
}

const getFeatures = async (client, companyId) => {
  try {
    const features = await client.ganon.getFeatures(companyId)
    return features
  } catch (e) {
    return {
      dashboard_migrated: true,
      experimental_anticipation_simulation: false,
      old_metadata_export: false,
    }
  }
}

const generateFingerPrint = async () => {
  if (!window.Bloodhound) {
    return null
  }
  try {
    const result = await window.Bloodhound.get()
    const decoded = JSON.parse(window.atob(result))
    return decoded.fingerprint.visitorId
  } catch {
    return null
  }
}

const login = uncurryN(2, (action, fingerPrint) => {
  const { payload } = action
  if (fingerPrint) {
    payload.visitorID = fingerPrint
  }
  const qsOptions = { qsOptions: { indices: false } }
  payload.options = payload.options
    ? merge(payload.options, qsOptions)
    : qsOptions

  return pagarme.client.connect(payload)
})

const loginEpic = (action$, state$) => action$
  .pipe(
    ofType(LOGIN_REQUEST),
    mergeMap(action => generateFingerPrint()
      .then(login(action))
      .then(client => cockpit(client, errorHandler))
      .then((client) => {
        const { value: state } = state$

        ApiClientSingleton.set(
          apiClient({
            authentication: client.authentication,
            baseURL: state.account.impersonationKey
              ? 'https://hodor.network.pagarme.net/api-admin/pilot/v1'
              : 'https://api.mundipagg.com/pilot/v1',
          })
        )

        return client
      })
      .then(verifyCapabilityPermission)
      .then(receiveLogin)
      .catch((error) => {
        try {
          localStorage.removeItem('redux_localstorage_simple_account.sessionId')
          localStorage.removeItem('redux_localstorage_simple_account.accountId')
          localStorage.removeItem('redux_localstorage_simple_account.companyId')
          localStorage.removeItem('redux_localstorage_simple_account.jwt')
        } catch (err) {
          console.warn(err.message) //eslint-disable-line
        }
        return failLogin(error)
      }))
  )

const accountEpic = action$ => action$
  .pipe(
    ofType(LOGIN_RECEIVE),
    mergeMap((action) => {
      const { error, payload: client } = action

      if (error) {
        return Promise.resolve(action.payload)
      }

      return client.user.current().catch(identity)
    }),
    map(receiveAccount),
    tap(({ error, payload }) => {
      if (error) {
        return
      }

      const {
        email,
        permission,
      } = payload

      window.accountData = {
        email,
        permission,
      }
    })
  )

const companyEpic = (action$, state$) => action$.pipe(
  ofType(ACCOUNT_RECEIVE),
  mergeMap(({ error, payload }) => {
    const { value: state } = state$
    const { account: { client } } = state

    if (error) {
      return Promise.resolve(payload)
    }

    return client.company.current()
      .then(async (account) => {
        const currentEnv = state.account.impersonationEnv || env

        ApiClientSingleton.set(
          apiClient({
            authentication: client.authentication,
            baseURL: state.account.impersonationKey
              ? 'https://hodor.network.pagarme.net/api-admin/pilot/v1'
              : 'https://api.mundipagg.com/pilot/v1',
            companyId: account.id,
            impersonateKey: state.account.impersonationKey,
            isSandbox: currentEnv === 'test',
          })
        )

        const companyRequests = [
          getFeatures(client, account.id),
          hasPaymentsAccess(account),
        ]

        const [
          features,
          canDoPayments = false,
        ] = await Promise.all(companyRequests)

        getUserDetails({
          accountType: account.type,
          client,
          env,
          user: payload,
        })

        window.accountData = { ...window.accountData, features }

        return {
          ...account,
          canDoPayments,
          features,
        }
      })
      .catch(errorPayload => ({
        error: true,
        payload: errorPayload,
      }))
  }),
  mergeMap(async (action) => {
    if (action.error) {
      return rxOf(action)
    }

    const { value: state } = state$
    const { account: { client } } = state

    return Promise.resolve({
      ...action,
      alreadyTransacted: await alreadyTransacted(client),
    })
  }),
  mergeMap((action) => {
    if (action.error) {
      return rxOf(receiveError(action.payload))
    }

    return rxOf(receiveCompany(action))
  }),
  tap(({ error, payload }) => {
    if (error) {
      return
    }
    const { value: state } = state$
    const {
      api_version: apiVersion,
      cnpj,
      date_created: dateCreated,
      id,
      name,
      status,
      transfers,
      type,
    } = payload

    const {
      account: {
        user: {
          id: userId,
        },
      },
    } = state

    setCompany(
      id,
      name,
      dateCreated,
      status,
      type,
      userId
    )

    window.accountData = {
      ...window.accountData,
      apiVersion,
      blockedBalanceAmount: propOr(
        null, 'blocked_balance_amount', transfers
      ),
      cnpj,
      id,
      status,
      type,
    }

    if (status === 'active') {
      if (isPaymentLink(type)) {
        paymentLinkCompanyLogin()
      } else {
        activeCompanyLogin()
      }
    } else {
      inactiveCompanyLogin()
    }
  })
)

const recipientEpic = (action$, state$) => action$.pipe(
  ofType(COMPANY_RECEIVE),
  mergeMap(() => {
    const state = state$.value
    const recipientId = getRecipientId(state)
    const { account: { client } } = state

    return client.recipients.find({ id: recipientId })
  }),
  map(receiveRecipient)
)

const feePresetEpic = (action$, state$) => action$.pipe(
  ofType(RECIPIENT_RECEIVE),
  mergeMap(() => {
    const state = state$.value
    const feePresetId = getFeePresetId(state)
    const { account: { client } } = state

    if (!feePresetId) {
      return Promise.resolve(null)
    }

    return client.feePresets.find({ id: feePresetId })
  }),
  map(receiveFeePreset)
)

const recipientBalanceEpic = (action$, state$) => action$.pipe(
  ofType(COMPANY_RECEIVE, WITHDRAW_RECEIVE),
  mergeMap(({ error, payload }) => {
    const state = state$.value
    const recipientId = getRecipientId(state)
    const { account: { client } } = state

    if (error) {
      return Promise.resolve(payload)
    }

    return Promise.all([
      client.recipient.balance(recipientId),
      client.transfers.limits({ recipient_id: recipientId }),
    ])
      .then(([balance, withdrawal]) => ({
        balance,
        withdrawal,
      }))
      .catch(identity)
  }),
  map(receiveRecipientBalance)
)

const verifyEnvironmentPermission = (company) => {
  if (
    env === 'live'
    && isSelfRegister(company)
    && isPendingRiskAnalysis(company)
  ) {
    throw new Error('Pending risk analysis')
  }

  if (env === 'live' && !isActiveCompany(company)) {
    throw new Error('Unauthorized environment')
  }

  return company
}

const onCompanyReceive = action$ => action$.pipe(
  ofType(COMPANY_RECEIVE),
  mergeMap(({ payload }) => {
    try {
      return rxOf(verifyEnvironmentPermission(payload))
    } catch (error) {
      return rxOf({
        error: true,
        payload: error,
      })
    }
  }),
  mergeMap((action) => {
    if (action.error) {
      return rxOf(receiveError(action.payload))
    }

    return rxOf(action)
  })
)

const logoutEpic = (action$, state$) => action$.pipe(
  ofType(LOGOUT_REQUEST),
  mergeMap(async () => {
    const state = state$.value
    const {
      account: {
        client,
        jwt,
        sessionId,
      },
    } = state

    window.accountData = {}

    const pagarmeDomains = ['pagar.me', 'pagarme.net']
    const domain = pagarmeDomains
      .find(element => window.location.hostname.includes(element))

    Cookies.remove('mp_tk', { domain, path: '/' })
    Cookies.remove('mp_rt', { domain, path: '/' })
    Cookies.remove('mp_rd', { domain, path: '/' })
    localStorage.removeItem('accessToken')

    if (jwt) {
      return window.open(idSignInUrl, '_self')
    }

    if (impersonate || !sessionId) {
      return Promise.resolve()
    }

    return client.session
      .destroy(sessionId)
      .then(async () => {
        await window.open(idSignInUrl, '_self')
      })
      .catch(() => Promise.resolve()) // allow logout process to continue even if can't destroy session
  }),
  map(receiveLogout)
)

const getAcquirersEpic = (action$, state$) => action$
  .pipe(
    ofType(GET_ACQUIRERS_REQUEST),
    mergeMap(() => {
      const state = state$.value
      const { account: { client } } = state

      return client.acquirers.all()
        .then(getAcquirersResponse)
    })
  )

export default combineEpics(
  loginEpic,
  accountEpic,
  getAcquirersEpic,
  companyEpic,
  feePresetEpic,
  recipientEpic,
  recipientBalanceEpic,
  onCompanyReceive,
  logoutEpic
)
