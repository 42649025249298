import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {
  ModalContent,
  ModalTitle,
  Col,
  Grid,
  Row,
} from 'former-kit'

import { mapObjIndexed } from 'ramda'

import formatCurrency from '../../formatters/currency'
import Property from '../Property'
import style from './style.css'

const fields = (labels, contents) => mapObjIndexed((label, key) => (
  <Property
    title={label}
    value={contents[key]}
  />
), labels)

const PixRefundDetails = ({ contents, labels, title }) => {
  const {
    amount,
    recipient,
    refundAmount,
  } = contents

  const formattedContent = {
    amount: formatCurrency(amount),
  }
  const data = fields(labels, formattedContent)

  const distributedWidth = recipient
    ? 4
    : 6

  return (
    <Fragment>
      <ModalTitle
        title={title}
        titleAlign="start"
      />
      <ModalContent>
        <Grid>
          <Row>
            <Col
              palm={12}
              tablet={distributedWidth}
              desk={distributedWidth}
              tv={distributedWidth}
            >
              {data.amount}
            </Col>
            <Col
              palm={12}
              tablet={distributedWidth}
              desk={distributedWidth}
              tv={distributedWidth}
            >
              <Property
                title={labels.refundAmount}
                value={(
                  <span
                    className={classnames(
                      style.emphasis, style.refundAmount
                    )}
                  >
                    {formatCurrency(refundAmount)}
                  </span>
                )}
              />
            </Col>

            {recipient && (
              <Col
                palm={12}
                tablet={distributedWidth}
                desk={distributedWidth}
                tv={distributedWidth}
              >
                <Property
                  title={labels.recipient}
                  value={(
                    <span
                      className={classnames(
                        style.emphasis, style.recipient
                      )}
                    >
                      {recipient.name}
                    </span>
                  )}
                />
              </Col>
            )}
          </Row>
        </Grid>
      </ModalContent>
    </Fragment>
  )
}

PixRefundDetails.propTypes = {
  contents: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    recipient: PropTypes.shape({
      name: PropTypes.string,
    }),
    refundAmount: PropTypes.string.isRequired,
  }).isRequired,
  labels: PropTypes.shape({
    recipient: PropTypes.string.isRequired,
    refundAmount: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

export default PixRefundDetails
