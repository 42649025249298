import { useEffect, useState } from 'react'
import ApiClientSingleton from '../../utils/helpers/ApiClientSingleton'

export const defaultPaymentLinksExportOptions = {
  fields: ['date_created', 'id', 'name', 'status', 'amount', 'url'],
  report_type: 'CSV',
}

const usePostPaymentLinksReports = ({
  parameters = {},
  options = defaultPaymentLinksExportOptions,
  runOnMount = false,
} = {}) => {
  const [isLoading, setIsLoading] = useState(!!runOnMount)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  const getItems = async ({
    options: newOptions,
    parameters: newParams,
  } = {}) => {
    setIsLoading(true)
    const apiClient = ApiClientSingleton.get()

    try {
      const { data } = await apiClient.paymentLinks.export(
        newParams || parameters,
        newOptions || options
      )

      setResult(data)
      setIsLoading(false)
      return { response: data }
    } catch (err) {
      if (err.response?.data) {
        setError(err.response.data)
        setIsLoading(false)
        return { error: err.response.data }
      }

      setError(err.message)
      setIsLoading(false)
      return { error: err.message }
    }
  }

  useEffect(() => {
    if (runOnMount) {
      getItems(parameters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runOnMount])

  return {
    error,
    isLoading,
    request: getItems,
    result,
  }
}

export default usePostPaymentLinksReports
