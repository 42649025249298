import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-i18next'
import {
  anyPass, compose, isEmpty, isNil, path, sortBy, toLower, prop,
} from 'ramda'
import {
  Col,
  Grid,
  Row,
} from 'former-kit'
import { withError } from '../../../ErrorBoundary'
import {
  getLinkRequest as getLinkRequestAction,
  getTransactionsRequest as getTransactionsRequestAction,
  cancelLinkRequest as cancelLinkRequestAction,
} from './actions'
import {
  DetailsHeader,
  DetailsInfo,
  DisableLinkModal,
  PaymentMethods,
  TransactionsList,
} from '../../../../containers/PaymentLinks/Details'
import Spinner from '../../../../components/Spinner'
import TransactionItemsCard from '../../../../components/TransactionItemsCard'
import styles from './styles.css'

const mapStateToProps = ({
  paymentLinksDetails: {
    loadingGetLink,
    loadingGetTransactions,
    paymentLink,
    transactions,
  },
}) => ({
  loadingGetLink,
  loadingGetTransactions,
  paymentLink,
  transactions,
})

const mapDispatchToProps = {
  cancelLinkRequest: cancelLinkRequestAction,
  getLinkRequest: getLinkRequestAction,
  getTransactionsRequest: getTransactionsRequestAction,
}

const enhanced = compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withError
)

const sortItems = sortBy(
  compose(
    toLower,
    prop('title')
  )
)

const isEmptyOrNull = anyPass([isEmpty, isNil])

const defaultColumnSize = {
  desk: 12,
  palm: 12,
  tablet: 12,
  tv: 12,
}

const Details = ({
  cancelLinkRequest,
  getLinkRequest,
  getTransactionsRequest,
  history,
  loadingGetLink,
  loadingGetTransactions,
  match,
  paymentLink,
  t,
  transactions,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const itemFieldsToShow = {
    id: true,
    quantity: true,
    tangible: true,
    unit_price: true,
  }
  const transactionResult = transactions?.items || []

  useEffect(() => {
    getLinkRequest(match.params.id)
    getTransactionsRequest(match.params.id)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelLink = () => {
    cancelLinkRequest(match.params.id)
    setIsOpen(false)
  }

  const onRowClick = (rowIndex) => {
    const transactionId = transactions?.items[rowIndex]?.id
    history.push(`/transactions/${transactionId}`)
  }

  return !loadingGetLink
    ? (
      <>
        <DisableLinkModal
          isOpen={isOpen}
          onCancelLink={onCancelLink}
          onClose={() => setIsOpen(false)}
          t={t}
        />
        <Grid>
          <Row>
            <Col {...defaultColumnSize}>
              <DetailsHeader
                name={paymentLink.name}
                onPaymentLinkCancel={() => setIsOpen(true)}
                status={paymentLink.status}
                t={t}
                url={paymentLink.url}
              />
            </Col>
          </Row>
          <Row stretch>
            <Col desk={6} palm={6} tablet={6} tv={6}>
              <DetailsInfo
                amount={paymentLink.amount}
                createdAt={paymentLink.date_created}
                expiresAt={paymentLink.expires_at}
                t={t}
              />
            </Col>
            <Col desk={6} palm={6} tablet={6} tv={6}>
              <PaymentMethods
                boletoConfig={path(['payment_config', 'boleto'], paymentLink)}
                creditCardConfig={path(['payment_config', 'credit_card'], paymentLink)}
                pixConfig={path(['payment_config', 'pix'], paymentLink)}
                t={t}
              />
            </Col>
          </Row>
          {!isEmptyOrNull(paymentLink.items) && (
          <Row>
            <Col
              desk={12}
              palm={12}
              tablet={12}
              tv={12}
            >
              <TransactionItemsCard
                contents={sortItems(paymentLink.items)}
                fields={itemFieldsToShow}
                fieldsPerRow={4}
                title="pages.payment_link_detail.items.title"
                t={t}
              />
            </Col>
          </Row>
          )}
          <Row>
            <Col {...defaultColumnSize}>
              <TransactionsList
                loading={loadingGetTransactions}
                onRowClick={onRowClick}
                rows={transactionResult}
                t={t}
              />
            </Col>
          </Row>
        </Grid>
      </>
    )
    : (
      <div className={styles.loading}>
        <Spinner />
      </div>
    )
}

Details.propTypes = {
  cancelLinkRequest: PropTypes.func.isRequired,
  getLinkRequest: PropTypes.func.isRequired,
  getTransactionsRequest: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loadingGetLink: PropTypes.bool.isRequired,
  loadingGetTransactions: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  }).isRequired,
  paymentLink: PropTypes.shape(),
  t: PropTypes.func.isRequired,
  transactions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
}

Details.defaultProps = {
  paymentLink: null,
}

export default enhanced(Details)
